import { TABLET_SCREEN_WIDTH } from 'utils/constants';
import styled from 'styled-components';

export const MaterialNameStyle = styled.div`
  text-transform: capitalize;
  white-space: nowrap;
`;

export const NoValueTextStyle = styled.p`
  text-align: center;
  padding: 4rem;
  margin: 0;
  font-size: 1.2rem;
`;

export const BannersContainerStyle = styled.div`
  padding: 3rem;

  @media (max-width: ${TABLET_SCREEN_WIDTH}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ImageContainerStyle = styled.div`
  width: 30%;
  height: 30%;
  margin: 1rem;
  text-align: center;
  display: inline-block;
  font-weight: bold;

  @media (max-width: ${TABLET_SCREEN_WIDTH}px) {
    width: 30rem;
    height: ${({ noImage }) => (noImage ? '15rem' : 'fit-content')};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
