import { CampaignsApi } from 'utils/apiCalls';
import { handleApiError, showMessage } from 'utils/helpers';
import { getLogsQuery } from './queryBuilder';

export default {
  name: 'History',
  state: {
    getLogsLoading: false,
    getWorkflowRunsLoading: false,
    historyLogs: [],
    workflowRuns: [],
  },
  actions: {
    *getCampaignLogs(campaignId, filter) {
      yield { getLogsLoading: true };
      try {
        const query = getLogsQuery(campaignId, filter);
        const logs = yield CampaignsApi.getCampaignHistory(query).catch(e => e);
        handleApiError(logs);

        const filteredLogs = [];
        logs.hits.hits.forEach(log => {
          filteredLogs.push(log._source);
        });

        yield {
          getLogsLoading: false,
          historyLogs: filteredLogs,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getLogsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign logs${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignWorkflowRuns(campaignId) {
      yield { getWorkflowRunsLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignWorkflowRuns(
          campaignId,
        ).catch(e => e);
        handleApiError(data);

        yield {
          getWorkflowRunsLoading: false,
          workflowRuns: data,
        };
      } catch (err) {
        yield {
          getWorkflowRunsLoading: false,
        };
      }
    },
  },
};
