const adminTheme = {
  colors: {
    main: '#31365d',
    mainLight: '#656da3',
    mainDark: '#1f223c',
    white: '#ffffff',
    red: '#ef4938',
    orange: '#f6982e',
    green: '#4d9c5d',
    blue: '#288cc2',
    gray: '#5a5959',
    grayLight: '#959393',
    grayLighter: '#b8b5b5',
    grayBorder: '#e8e8e8',
    silver: '#d2d2d2',
  },
};

module.exports = adminTheme;
