import React, { useEffect, useState } from 'react';
import { Select, Table, Input, Spin, Button, Space } from 'antd';
import { CSVLink } from 'react-csv';

import { DEBOUNCE_TIMEOUT } from '../../../utils/constants';
import countries from 'utils/countries';
import { DivFlexSpaceBetween } from 'styles/styledComponents';

const { Search } = Input;
const { Option, OptGroup } = Select;

const columns = [
  {
    title: 'Keyword',
    dataIndex: 'keyword',
  },
  {
    title: 'Search Volume',
    dataIndex: 'volume',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.volume - b.volume,
  },
  {
    title: 'Max CPC ($)',
    dataIndex: 'max_cpc',
    sorter: (a, b) => a.max_cpc - b.max_cpc,
  },
  {
    title: 'Min CPC ($)',
    dataIndex: 'min_cpc',
    sorter: (a, b) => a.min_cpc - b.min_cpc,
  },
];

const KeywordTab = ({
  data,
  url,
  campaign_id,
  getKeywords,
  selectKeywords,
  saveLoading,
  source,
  loading,
  campaign_countries,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('url');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [refreshKeywords, setRefreshKeywords] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setSearchTerm(url);
    setSelectedCountries(campaign_countries);
  }, [campaign_id]); //eslint-disable-line

  useEffect(() => {
    setTableData(data);

    const rows = data?.filter(item => item.selected);
    setSelectedRows(rows || []);
    setSelectedRowKeys(rows?.map(item => item.keyword));
  }, [data]);

  useEffect(() => {
    if (campaign_id && searchTerm && refreshKeywords) {
      const timeout = setTimeout(() => {
        if (searchType === 'url') {
          getKeywords({
            campaign_id,
            domain: searchTerm,
            source,
            countries: selectedCountries,
          });
        } else {
          getKeywords({
            campaign_id,
            keywords_seeds: searchTerm,
            source,
            countries: selectedCountries,
          });
        }
      }, DEBOUNCE_TIMEOUT);

      return () => {
        setRefreshKeywords(false);
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedCountries]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      keyword: record.keyword,
    }),
  };

  const searchTypeOptions = (
    <Select
      defaultValue="url"
      onChange={e => {
        setSearchType(e);
        setSearchTerm('');
      }}
    >
      <Option value="url">URL</Option>
      <Option value="keyword">Keyword</Option>
    </Select>
  );

  const restCountries = Object.keys(countries)
    .filter(country => !campaign_countries?.includes(country))
    ?.map(country => (
      <Option
        disabled={
          selectedCountries?.length === 1 && selectedCountries.includes(country)
        }
        value={country}
        key={country}
      >
        {countries[country]}
      </Option>
    ));

  const downloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([selectedRowKeys?.join(';')], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = `campaign-${campaign_id}-keywords.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const addToCampaign = () => {
    selectKeywords({
      campaign_id,
      source,
      keywords: selectedRowKeys,
    });
  };

  const toggleAllKeywordsSelection = () => {
    const operationIsSelect = selectedRowKeys?.length !== data?.length;
    const keywordsKeys = [];
    const newData = data.map(keywordObj => {
      keywordsKeys.push(keywordObj.keyword);
      return { ...keywordObj, selected: operationIsSelect };
    });

    operationIsSelect
      ? setSelectedRowKeys(keywordsKeys)
      : setSelectedRowKeys([]);
    operationIsSelect ? setSelectedRows(newData) : setSelectedRows([]);
    setTableData(newData);
  };

  return (
    <Spin spinning={loading}>
      <div
        style={{
          display: 'flex',
          marginBottom: 20,
        }}
      >
        <Search
          placeholder={
            searchType === 'url'
              ? 'e.g: www.instascaler.com'
              : 'e.g: tech;marketing'
          }
          addonBefore={searchTypeOptions}
          value={searchTerm}
          onChange={e => {
            setRefreshKeywords(true);
            setSearchTerm(e.target.value);
          }}
          enterButton
          style={{
            marginRight: 20,
          }}
        />
        <Select
          mode="multiple"
          maxTagCount={2}
          maxTagPlaceholder={tags => <span>+{tags.length}</span>}
          value={selectedCountries}
          style={{ width: 400 }}
          showSearch
          optionFilterProp="children"
          onChange={e => {
            setRefreshKeywords(true);
            setSelectedCountries(e);
          }}
        >
          <OptGroup label="Campaign Countries">
            {campaign_countries?.map(country => (
              <Option
                disabled={
                  selectedCountries?.length === 1 &&
                  selectedCountries.includes(country)
                }
                key={country}
                value={country}
              >
                {countries[country]}
              </Option>
            ))}
          </OptGroup>
          <OptGroup label="All Countries">{restCountries}</OptGroup>
        </Select>
      </div>
      <DivFlexSpaceBetween style={{ flexWrap: 'wrap' }}>
        <span>
          All Keywords: {data?.length || 0} (Selected:{' '}
          {selectedRowKeys?.length || 0}{' '}
          {data?.length ? (
            <Button
              type="link"
              onClick={toggleAllKeywordsSelection}
              style={{ padding: 0 }}
            >
              {selectedRowKeys?.length !== data?.length
                ? 'Select All'
                : 'Deselect All'}
            </Button>
          ) : (
            ''
          )}
          )
        </span>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: `0 0 20px auto`,
          }}
        >
          <Button
            type="primary"
            onClick={addToCampaign}
            disabled={!selectedRowKeys?.length}
            key={`save-${selectedRowKeys?.length}`}
            loading={saveLoading}
          >
            Add To Campaign
          </Button>
          <Button
            type="primary"
            disabled={!selectedRowKeys?.length}
            key={`csv-${selectedRowKeys?.length}`}
          >
            <CSVLink
              data={selectedRows}
              target="_blank"
              filename={`campaign-${campaign_id}-keywords.csv`}
              style={{
                color: selectedRowKeys?.length ? '#fff' : '#00000025',
              }}
            >
              Export as CSV
            </CSVLink>
          </Button>
          <Button
            type="primary"
            onClick={downloadTxtFile}
            disabled={!selectedRowKeys?.length}
            key={`txt-${selectedRowKeys?.length}`}
          >
            Export as TXT
          </Button>
        </Space>
      </DivFlexSpaceBetween>
      <Table
        rowSelection={rowSelection}
        rowKey="keyword"
        columns={columns}
        dataSource={tableData}
        pagination={{
          showSizeChanger: true,
        }}
      />
    </Spin>
  );
};

export default KeywordTab;
