import React from 'react';
import { Card } from 'antd';
import RangePicker from '../SharedComponents/rangePicker';

function Propeller({ getFieldDecorator, campaign }) {
  return (
    <>
      <Card title="Propeller Details" size="small">
        <RangePicker
          getFieldDecorator={getFieldDecorator}
          campaign={campaign}
        />
      </Card>
    </>
  );
}

export default Propeller;
