import React from 'react';

import {
  KeyContainerStyle,
  RowContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';

import { Button, Modal } from 'antd';
import { ActionContainerStyle } from './styles';

const IntegrationsActionsPanel = ({
  campaign,
  revokeGa,
  revokeFb,
  revokeGaLoading,
  revokeFbLoading,
}) => {
  const {
    is_ga_integrated: isGaIntegrated,
    is_fb_integrated: isFbIntegrated,
  } = campaign;

  const onRevoke = platform => {
    let revoke = revokeGa;
    let serviceToRevoke = 'Google Analytics';
    if (platform === 'fb') {
      revoke = revokeFb;
      serviceToRevoke = 'Facebook Ads';
    }
    Modal.confirm({
      title: `Are you sure you want to revoke ${serviceToRevoke}?`,
      width: 700,
      onOk: revoke,
      okText: 'Yes',
    });
  };

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Google Analytics</KeyContainerStyle>
        <ValueContainerStyle>
          {isGaIntegrated ? (
            /* In case GA is integrated */
            <ActionContainerStyle>
              <span>Integrated</span>
              <Button
                type="primary"
                onClick={() => onRevoke('ga')}
                loading={revokeGaLoading}
              >
                Revoke GA
              </Button>
            </ActionContainerStyle>
          ) : (
            /* In case GA is not integrated */
            <span>Not Integrated</span>
          )}
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow>
        <KeyContainerStyle>Facebook Ads</KeyContainerStyle>
        <ValueContainerStyle>
          {isFbIntegrated ? (
            /* In case FB is integrated */
            <ActionContainerStyle>
              <span>Integrated</span>
              <Button
                type="primary"
                onClick={() => onRevoke('fb')}
                loading={revokeFbLoading}
              >
                Revoke FB
              </Button>
            </ActionContainerStyle>
          ) : (
            /* In case FB is not integrated */
            <span>Not Integrated</span>
          )}
        </ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default IntegrationsActionsPanel;
