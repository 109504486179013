import { Layout } from 'antd';
import SideNav from 'components/SideNav';
import TopNav from 'components/TopNav';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { pathLogin } from 'routes/routes';
import Cookies from 'universal-cookie';
import { COOKIES_AUTHORIZATION } from 'utils/constants';

const cookies = new Cookies();
const { Content } = Layout;

const AppLayout = ({ children }) => {
  const isLoggedIn = cookies.get(COOKIES_AUTHORIZATION);

  return (
    <>
      {isLoggedIn ? (
        <>
          <Layout style={{ minHeight: '100vh' }}>
            <SideNav />
            <Layout>
              <TopNav />
              <Content>
                <Switch>{children}</Switch>
              </Content>
            </Layout>
          </Layout>
        </>
      ) : (
        <Redirect to={pathLogin} />
      )}
    </>
  );
};

export default AppLayout;
