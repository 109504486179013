import styled from 'styled-components';
import theme from 'styles/themes';

export const CostReportCardsContainerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;

  .ant-card {
    flex-grow: 1;
    min-width: 25rem;
    margin: 0 1rem 2rem;
  }

  .ant-card-small > .ant-card-body {
    padding: 2rem;
  }

  & .ant-timeline.ant-timeline-label {
    & .ant-timeline-item-label {
      width: calc(41% - 1.2rem);
    }

    & .ant-timeline-item-head-custom,
    .ant-timeline-item-tail {
      left: 42%;
    }

    & .ant-timeline-item-left .ant-timeline-item-content {
      left: calc(43% - 0.4rem);
    }
  }
`;

export const CostBoxesStyle = styled.div`
  margin: 0 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
`;

export const BoxStyle = styled.div`
  color: #ffffff;
  flex-grow: 1;
  margin: 1rem;
  padding: 2rem;
  display: flex;
  height: 9rem;
  width: calc(25% - 2rem);
  min-width: 20rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  text-transform: uppercase;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
  ${({ isActive }) => (!isActive ? 'opacity: 0.7;' : '')}

  &:nth-of-type(1) {
    background-color: ${theme.colors.mainLight};
  }

  &:nth-of-type(2) {
    background-color: ${theme.colors.orange};
  }

  &:nth-of-type(3) {
    background-color: ${theme.colors.green};
  }

  &:nth-of-type(4) {
    background-color: ${theme.colors.blue};
  }
`;
