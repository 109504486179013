import React, { useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { DatePicker } from 'antd';
import moment from 'moment';
import { getPackageStartDate, getPackageEndDate } from 'utils/helpers';

let startDate = moment.utc().startOf('day');
let endDate = moment.utc().add(1, 'months');
const disabledDate = current =>
  current && current < moment.utc().startOf('day');

const { RangePicker: AntdRangePicker } = DatePicker;
function RangePicker({ getFieldDecorator, campaign, customizedProps = {} }) {
  useEffect(() => {
    if (campaign?.package) {
      startDate = moment.utc(getPackageStartDate(campaign?.package));
      endDate = moment.utc(getPackageEndDate(campaign?.package));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item label="Date Range">
      {getFieldDecorator('rangePicker', {
        initialValue: [startDate, endDate],
        rules: [
          {
            required: true,
            message: 'Please input a Date Range!',
          },
        ],
      })(
        <AntdRangePicker
          disabledDate={disabledDate}
          data-test="dateRange"
          {...customizedProps}
        />,
      )}
    </Form.Item>
  );
}

export default RangePicker;
