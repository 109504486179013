/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

import {
  ColumnWidthOutlined,
  DeleteOutlined,
  FilePptOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PoundOutlined,
  SendOutlined,
  CarryOutOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import useMedia from 'use-media';
import { Form as OldForm } from '@ant-design/compatible';
import { cancelPendingRequests } from './module';

import {
  Button,
  Result,
  Spin,
  Tabs,
  Tooltip,
  Popconfirm,
  DatePicker,
  Modal,
  InputNumber,
  Input,
  Drawer,
  Form,
  Select,
  Popover,
  Card,
} from 'antd';

import {
  campaignsStatusMap,
  LIMIT,
  getSearchParams,
  DATE_FORMAT,
  MOB_WIDTH_FOR_DRAWER,
  MOB_SCREEN_WIDTH,
} from 'utils/constants';
import { connect } from 'speedux';
import Module from 'pages/CampaignDetails/module';
import PublishCampaign from 'components/PublishCampaign';

import {
  ContainerStyle,
  CardHeaderStyle,
  CampaignNameStyle,
  CampaignStateStyle,
  TabHeaderStyle,
  CampaignDetailsHeaderNameStateStyle,
  CampaignDetailsActionIcons,
  actionIconStyles,
  NotificationFormContainerStyle,
  ScheduledPublishContainerStyle,
  SinglePublishContainerStyle,
} from './styles';
import Actions from './Actions';
import Analytics from './Analytics';
import CampaignInformation from './CampaignInformation';
import CampaignMaterial from './CampaignMaterial';
import Optimization from './Optimization';
import Publishing from './Publishing';
import Keywords from './Keywords';
import { showMessage } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import theme from 'styles/themes';
import moment from 'moment';
import {
  Circle,
  FormContainerStyle,
  DivFlexSpaceBetween,
} from 'styles/styledComponents';

import notificationsList from 'utils/notifications.json';

const { TabPane } = Tabs;
const { Option } = Select;
const { blue, green, orange, red } = theme.colors;

const CampaignDetails = ({
  actions: {
    getCampaign,
    editCampaign,
    archiveCampaign,
    editPackage,
    editUserEmail,
    getCampaignCreatives,
    getCampaignRules,
    getCampaignGaGoals,
    getCampaignWorkflowRuns,
    getTsCampaigns,
    getTsCampaignsStatus,
    getCampaignGAViews,
    getCampaignScripts,
    getKampaniaData,
    getCampaignIntegrations,
    getAccountTransactions,
    cloneCampaign,
    patchCampaignGAView,
    editCampaignOpentagScripts,
    generateIntegrationToken,
    revokeGaIntegration,
    revokeFbIntegration,
    resetState,
    resetCampaignCreatives,
    extendCampaign,
    editTSCampaignWeight,
    getPaymanCharges,
    chargeCampaign,
    resetEditWeight,
    resetCampaign,
    sendNotification,
    getCustomerNotifications,
    resetCustomerNotifications,
    toggleTSCampaign,
    getAllScheduledPublish,
    deleteScheduledPublish,
    getKeywords,
    selectKeywords,
    resetKeywords,
    getSelectedKeywords,
    resetSelectedKeywords,
    resetSourceTobeRefreshed,
  },
  state: {
    campaign,
    campaignLinks,
    fetchCampaignLoading,
    fetchCampaignError,

    bingKeywords,
    googleKeywords,
    semrushKeywords,
    bingSelectedKeywords,
    googleSelectedKeywords,
    semrushSelectedKeywords,
    manualSelectedKeywords,
    bingGetKeywordsLoading,
    googleGetKeywordsLoading,
    semrushGetKeywordsLoading,
    bingSaveKeywordsLoading,
    manualSaveKeywordsLoading,
    googleSaveKeywordsLoading,
    semrushSaveKeywordsLoading,
    sourceTobeRefreshed,
    googleGetSelectedKeywordsLoading,
    semrushGetSelectedKeywordsLoading,
    bingGetSelectedKeywordsLoading,
    manualGetSelectedKeywordsLoading,

    campaignCreatives,
    campaignRules,
    campaignWorkflowRuns,
    tsCampaigns,
    tsCampaignsStatus,
    failedToFetchStatus,
    campaignLocations,
    opentagScripts,
    gaGoals,
    gaViews,
    accountTransactions,
    fieldsToEdit,

    editLoading,
    fetchKampaniaDataLoading,
    fetchCampaignIntegrationsLoading,
    editCampaignOpenTagLoading,
    fetchCampaignGAViewsLoading,
    fetchCampaignScriptsLoading,
    fetchCampaignGoalsLoading,
    patchCampaignGAViewLoading,
    fetchCampaignWorkflowRunsLoading,
    fetchTsCampaignsLoading,
    fetchCampaignRulesLoading,
    cloneCampaignLoading,

    gaIntegrationToken,
    generateGaIntegrationTokenLoading,

    fbIntegrationToken,
    generateFbIntegrationTokenLoading,
    isPackageUpdated,

    revokeGaLoading,
    revokeFbLoading,
    archiveCampaignLoading,
    isCampaignArchived,
    isCampaignUpdated,
    extendCampaignLoading,
    sucessExtendCampaign,
    extendCampaignError,
    editWeightLoading,
    doneEditingWeight,
    sendNotificationLoading,
    remainingBudget,
    chargeCampaignLoading,
    toggleTSCampaignLoading,
    paymanAccountId,
    publish_jobs,
    loadingDeleteScheduledPublish,
    getAllScheduledPublishLoading,
    publishToBeDeleted,
    customerNotifications,
    getCustomerNotificationsLoading,
  },
  globalState: { fetchAllCampaignsLoading },
  campaignId,
  selectedTabKey,
  selectSectionTabKey,
  subSectionTabSelected,
  selectSubSectionFromTab,
  getAllCampaigns,
}) => {
  const campaignDetailsRef = useRef(null);
  const [additionalBalanceValue, setAdditionalBalance] = useState('');
  const [extendDate, setExtendDate] = useState('');
  const location = useLocation();
  const [isExtendModal, setExtendModal] = useState(false);
  const [
    sendNotificationDrawerVisible,
    setSendNotificationDrawerVisible,
  ] = useState(false);
  const params = getSearchParams(location.search);
  const [
    isPublishCampaignDrawerVisible,
    setPublishCampaignDrawerVisible,
  ] = useState(false);
  const [shouldRefreshTable, setShouldRefreshTable] = useState(false);

  const [editMode, setEditMode] = useState(null);

  const [notificationForm] = Form.useForm();

  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });

  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
      getAllScheduledPublish(campaignId);
    }

    return () => {
      cancelPendingRequests();

      resetCampaign();
      resetState();
    };
  }, [campaignId]);

  useEffect(() => {
    if (campaign?.state && campaign?.state !== 'ARCHIVED' && campaignId) {
      getCampaignCreatives(campaignId);
    }

    return () => resetCampaignCreatives();
  }, [campaignId, campaign?.state]);

  useEffect(() => {
    if (campaign?.package?.account) {
      getAccountTransactions();
    }
  }, [campaign]); //eslint-disable-line

  useEffect(() => {
    if (isCampaignArchived) {
      showMessage('success', 'Campaign Archived Successfully!');
    }

    if (isCampaignArchived || isCampaignUpdated) {
      getAllCampaigns({
        campaignParams: {
          state: params.get('state'),
          search: params.get('search'),
          source: params.get('source'),
          accountId: params.get('accountId'),
        },
        limit: LIMIT,
        page: 1,
      });

      setEditMode(false);
    }

    return () => {
      setEditMode(null);
      resetState();
    };
  }, [isCampaignArchived, isCampaignUpdated]);

  useEffect(() => {
    if (isPackageUpdated) {
      getCampaignWorkflowRuns(campaign._id);
      onGetTsCampaigns();
      setEditMode(false);
    }

    return () => {
      setEditMode(null);
      resetState();
    };
  }, [isPackageUpdated]);

  useEffect(() => {
    if (!isExtendModal) {
      setAdditionalBalance('');
      setExtendDate('');
    }
  }, [isExtendModal]);

  useEffect(() => {
    if (doneEditingWeight) {
      showMessage('warning', `Weight will be edited soon!`);
      resetEditWeight();
    }
  });

  useEffect(() => {
    if (sucessExtendCampaign && !extendCampaignLoading) {
      setExtendModal(false);
      showMessage('success', 'Campaign Extended Successfully!');
      getAllCampaigns({
        campaignParams: {
          state: params.get('state'),
          search: params.get('search'),
          accountId: params.get('accountId'),
        },
        limit: LIMIT,
        page: 1,
      });
    }

    if (extendCampaignError) showMessage('error', extendCampaignError);

    return () => {
      resetState();
    };
  }, [sucessExtendCampaign, extendCampaignLoading, extendCampaignError]);

  const renderActionIcons = () => {
    const iconsToRender = [];
    const accountId = campaign?.package?.account;

    const allowedIdsForSendingMsgs = process?.env?.REACT_APP_SEND_MSG_IDS?.split(
      ',',
    );

    const isAllowedToSendMsgId = allowedIdsForSendingMsgs?.includes(
      `${accountId}`,
    );

    if (
      campaignState === 'EXPIRED' &&
      campaign?.delivery_utm_source !== 'instascaler'
    ) {
      iconsToRender.push(
        <ColumnWidthOutlined
          style={{
            ...actionIconStyles,
            color: green,
          }}
          onClick={() => {
            setExtendModal(true);
          }}
        />,
      );
    }
    const campaignStatesToShowPublishIcon = [
      'RUNNING',
      'PENDING_PAID',
      'PAUSED',
    ];

    if (publish_jobs?.length) {
      const onDeleteScheduledPublish = id => {
        deleteScheduledPublish(id);
      };

      const scheduledTSCampaignsPopOverContent = (
        <Spin spinning={getAllScheduledPublishLoading}>
          <ScheduledPublishContainerStyle>
            {publish_jobs?.map(job => {
              return (
                <SinglePublishContainerStyle key={job.id}>
                  <div>
                    {job?.is_processed ? (
                      job?.publishing_status === '200' ? (
                        <Tooltip title="Published Successfully!">
                          <CheckCircleOutlined
                            style={{ marginRight: '1rem', color: green }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Failed to publish!">
                          <CloseCircleOutlined
                            style={{ marginRight: '1rem', color: red }}
                          />
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip title="Not processed yet!">
                        <Circle color={green} />
                      </Tooltip>
                    )}
                    <span>{job.traffic_source}</span> at{' '}
                    <span>
                      {moment.utc(job.publishing_due).format(DATE_FORMAT)}
                    </span>
                  </div>
                  <Popconfirm
                    title="Are you sure to delete this scheduled publish?"
                    onConfirm={() => onDeleteScheduledPublish(job.id)}
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{
                      'data-test': `ScheduledPublish-delete-icon`,
                    }}
                    disabled={loadingDeleteScheduledPublish}
                    style={{ maxWidth: 300 }}
                  >
                    {loadingDeleteScheduledPublish &&
                    publishToBeDeleted === job.id ? (
                      <LoadingOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        spin
                      />
                    ) : (
                      <DeleteOutlined
                        style={{
                          fontSize: 18,
                          color: theme.colors.red,
                          marginLeft: 5,
                        }}
                      />
                    )}
                  </Popconfirm>
                </SinglePublishContainerStyle>
              );
            })}
          </ScheduledPublishContainerStyle>
        </Spin>
      );

      iconsToRender.push(
        <Tooltip
          title={`${publish_jobs?.length} Scheduled Publish`}
          key="scheduledTSCampaigns"
        >
          <Popover
            placement="leftTop"
            trigger="click"
            content={scheduledTSCampaignsPopOverContent}
            onVisibleChange={() => getAllScheduledPublish(campaignId)}
          >
            <CarryOutOutlined style={{ color: green, ...actionIconStyles }} />
          </Popover>
        </Tooltip>,
      );
    }

    if (campaignStatesToShowPublishIcon.includes(campaignState)) {
      iconsToRender.push(
        <Tooltip title="Publish Campaign" key={`${campaignState}-publish`}>
          <FilePptOutlined
            onClick={() => setPublishCampaignDrawerVisible(true)}
            style={{ color: blue, ...actionIconStyles }}
          />
        </Tooltip>,
      );
    }

    if (isAllowedToSendMsgId) {
      const onFinish = values => {
        const body = {
          issue_category: values.notificationCategory,
          issue_message: values.notificationBody,
        };

        sendNotification(campaignId, body, () => {
          setSendNotificationDrawerVisible(false);
        });
      };

      iconsToRender.push(
        <>
          <Tooltip title="Customer Notifications">
            <SendOutlined
              style={{ color: green, ...actionIconStyles }}
              onClick={() => {
                setSendNotificationDrawerVisible(true);
              }}
            />
          </Tooltip>
          <Drawer
            title="Customer Notifications"
            {...(isMob
              ? { width: MOB_WIDTH_FOR_DRAWER }
              : { width: MOB_SCREEN_WIDTH })}
            onClose={() => {
              setSendNotificationDrawerVisible(false);
            }}
            destroyOnClose
            visible={sendNotificationDrawerVisible}
            afterVisibleChange={visible => {
              if (!visible) {
                notificationForm.resetFields();
                resetCustomerNotifications();
              } else {
                getCustomerNotifications(campaignId);
              }
            }}
          >
            <Card
              title="New Notification"
              size="small"
              style={{ marginBottom: 20 }}
            >
              <NotificationFormContainerStyle>
                <Form
                  name="sendNotificationForm"
                  form={notificationForm}
                  onFinish={onFinish}
                  initialValues={{
                    notificationCategory: 0,
                    notificationBody:
                      notificationsList.notifications[0].description,
                  }}
                >
                  <Form.Item
                    label="Notification Category"
                    name="notificationCategory"
                    rules={[
                      {
                        required: true,
                        message: 'Please select notification category',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a Category"
                      optionFilterProp="children"
                      notFoundContent="Not Found."
                      loading={false}
                      onChange={value => {
                        const {
                          reasons,
                          actions,
                          description,
                        } = notificationsList.notifications[value];
                        notificationForm.setFieldsValue({
                          notificationBody: `${description}\n${reasons
                            .map(reason => `- ${reason}\n`)
                            .join('')}${actions
                            .map((action, index) =>
                              index === 0
                                ? `\nRecommendation/Action: \n- ${action}\n`
                                : `- ${action}\n`,
                            )
                            .join('')}`,
                        });
                      }}
                    >
                      {notificationsList?.notifications?.map(notification => (
                        <Option key={notification.id} value={notification.id}>
                          {notification.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Notification Body"
                    name="notificationBody"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter notification body',
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Notification body"
                      autoSize={{ minRows: 10 }}
                    />
                  </Form.Item>
                </Form>
                <div
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      notificationForm.submit();
                    }}
                    type="primary"
                    loading={sendNotificationLoading}
                  >
                    Send
                  </Button>
                </div>
              </NotificationFormContainerStyle>
            </Card>
            <Card
              title="Notifications History"
              size="small"
              bodyStyle={{
                minHeight: 100,
              }}
            >
              <Spin spinning={getCustomerNotificationsLoading}>
                {customerNotifications
                  ? customerNotifications?.map(notification => (
                      <DivFlexSpaceBetween style={{ marginBottom: 10 }}>
                        <div>
                          <Circle color={theme.colors.mainLight} />
                          {notification?.title}
                        </div>
                        <div>
                          <Popover
                            placement="topRight"
                            content={notification?.author}
                            overlayStyle={{ maxWidth: 400 }}
                          >
                            <UserOutlined
                              style={{ fontSize: 20, marginRight: 10 }}
                            />
                          </Popover>
                          <Popover
                            placement="topRight"
                            content={notification?.text}
                            overlayStyle={{ maxWidth: 400 }}
                          >
                            <MessageOutlined style={{ fontSize: 20 }} />
                          </Popover>
                        </div>
                      </DivFlexSpaceBetween>
                    ))
                  : "You haven't sent any notifications for this campaign!"}
              </Spin>
            </Card>
          </Drawer>
        </>,
      );
    }

    if (campaignState === 'PAUSED') {
      iconsToRender.push(
        <Popconfirm
          title="Are you sure Resume this campaign?"
          onConfirm={() => {
            editCampaign({ state: 'RUNNING' });
          }}
          key={`${campaignState}-play`}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Tooltip title="Resume">
            <PlayCircleOutlined style={{ color: green, ...actionIconStyles }} />
          </Tooltip>
        </Popconfirm>,
      );
    } else if (campaignState === 'RUNNING') {
      iconsToRender.push(
        <Popconfirm
          title="Are you sure Pause this campaign?"
          onConfirm={() => {
            editCampaign({ state: 'PAUSED' });
          }}
          key={`${campaignState}-pause`}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Tooltip title="Pause">
            <PauseCircleOutlined
              style={{ color: orange, ...actionIconStyles }}
            />
          </Tooltip>
        </Popconfirm>,
      );
    }

    if (campaignState !== 'ARCHIVED') {
      iconsToRender.push(
        <Popconfirm
          title="Are you sure Archive this campaign?"
          onConfirm={() => {
            archiveCampaign(campaignId);
          }}
          key={`${campaignState}-archive`}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Tooltip title="Archive">
            <DeleteOutlined style={{ color: red, ...actionIconStyles }} />
          </Tooltip>
        </Popconfirm>,
      );
    }

    return iconsToRender.map(i => i);
  };

  const onEditSave = updatedField => {
    const [updatedFieldKey] = Object.keys(updatedField);

    const packageFields = [
      'package_type',
      'amount',
      'bonus',
      'abm',
      'total_visits',
      'custom_start_date',
      'custom_end_date',
      'last_renew_date',
      'payment_method',
      'balance',
      'auto_renew',
    ];

    if (packageFields.includes(updatedFieldKey)) {
      editPackage(updatedField);
    } else if (updatedFieldKey === 'email') {
      editUserEmail(updatedField);
    } else {
      editCampaign(updatedField);
    }
  };

  const onEditOpentagScripts = newScripts => {
    editCampaignOpentagScripts(campaignId, newScripts);
  };

  const onGenerateIntegrationToken = platform => {
    generateIntegrationToken(campaignId, platform);
  };

  const onGetCampaignGAViews = () => {
    getCampaignGAViews(campaignId);
  };

  const onGetGaGoals = () => {
    getCampaignGaGoals(campaignId);
  };

  const onPatchCampaignGAView = ({ viewId, webPropertyId }) => {
    patchCampaignGAView({ campaignId, viewId, webPropertyId });
  };

  const onRevokeGaIntegration = () => {
    revokeGaIntegration(campaignId);
  };

  const onRevokeFbIntegration = () => {
    revokeFbIntegration(campaignId);
  };

  const onGetKampaniaData = () => {
    getKampaniaData(campaignId);
  };

  const onGetCampaignIntegrations = () => {
    getCampaignIntegrations(campaignId);
  };

  const onGetCampaignWorkflowRuns = () => {
    getCampaignWorkflowRuns(campaignId);
  };

  const onGetTsCampaigns = () => {
    getTsCampaigns(campaign?.kampania_id);
  };

  const onGetCampaignRules = () => {
    getCampaignRules(campaignId);
  };

  const loadingCampaign = fetchCampaignLoading || fetchAllCampaignsLoading;

  const campaignName = campaign?.name?.toLowerCase() || 'Campaign Name';
  const campaignState = campaign?.state || 'Campaign State';

  const { title: stateTitle, color: stateColor } = campaignsStatusMap[
    campaignState
  ];

  const toggleDisableAllChildButtons = (parentComponent, isDisable) => {
    const parentComponentButtons = parentComponent?.getElementsByTagName(
      'button',
    );
    if (parentComponentButtons) {
      for (const child of parentComponentButtons) {
        if (child.className.includes('ant-switch')) {
          child.className += ' ant-switch-disabled';
        }
        child.disabled = isDisable;
      }
    }
  };

  useEffect(() => {
    if (campaign?.kampania_id || campaign?.kampania_id === 0) {
      onGetTsCampaigns();
    }
  }, [campaign?._id]);

  useEffect(() => {
    const campaignDetailsRefInEffect = campaignDetailsRef.current;
    if (campaign?.state === 'ARCHIVED') {
      toggleDisableAllChildButtons(campaignDetailsRefInEffect, true);
    }

    return () => {
      toggleDisableAllChildButtons(campaignDetailsRefInEffect, false);
      selectSubSectionFromTab('1');
    };
  }, [
    campaign?.state,
    selectedTabKey,
    subSectionTabSelected,
    loadingCampaign,
    fetchCampaignRulesLoading,
    fetchKampaniaDataLoading,
    selectSubSectionFromTab,
  ]);

  if (fetchCampaignError && !loadingCampaign) {
    return (
      <ContainerStyle centeralize>
        <Result
          status={
            fetchCampaignError?.errorCode?.toString()?.includes('4')
              ? '404'
              : '500'
          }
          title={fetchCampaignError?.errorCode}
          subTitle="An error happened while fetching campaign"
          extra={
            <Button
              type="primary"
              onClick={() => campaignId && getCampaign(campaignId)}
            >
              Try again
            </Button>
          }
        />
      </ContainerStyle>
    );
  }

  if (!campaignId && campaign?._id && !loadingCampaign) {
    return (
      <ContainerStyle centeralize>
        <Result
          status="404"
          title="404"
          subTitle="Looks like no campaign is chosen"
        />
      </ContainerStyle>
    );
  }

  return (
    <ContainerStyle selectedTabKey={selectedTabKey} ref={campaignDetailsRef}>
      <Modal
        title={'Extend This Campaign'}
        visible={isExtendModal}
        onOk={() => {
          extendCampaign({
            campaignId,
            balance: additionalBalanceValue,
            endDate: moment.utc(extendDate).format(DATE_FORMAT),
          });
        }}
        confirmLoading={extendCampaignLoading}
        onCancel={() => {
          setExtendModal(false);
          setAdditionalBalance('');
          setExtendDate('');
        }}
      >
        <FormContainerStyle>
          <OldForm.Item label="Additional Balance">
            <InputNumber
              value={additionalBalanceValue}
              formatter={value =>
                value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value && value.replace(/\$\s?|(,*)/g, '')}
              prefix={<PoundOutlined />}
              onChange={setAdditionalBalance}
              placeholder="Balance"
            />
          </OldForm.Item>

          <OldForm.Item label="End Date">
            <DatePicker
              value={extendDate}
              format={DATE_FORMAT}
              onChange={setExtendDate}
              placeholder="End Date"
              disabledDate={current =>
                current && current < moment.utc().endOf('day')
              }
            />
          </OldForm.Item>
        </FormContainerStyle>
      </Modal>
      <Spin
        spinning={loadingCampaign || archiveCampaignLoading || editLoading}
        size="large"
      >
        <CardHeaderStyle>
          <CampaignDetailsHeaderNameStateStyle>
            <CampaignNameStyle data-test="campaign-name">
              {campaignName}
            </CampaignNameStyle>
            {stateTitle && (
              <CampaignStateStyle stateColor={stateColor}>
                {stateTitle}
              </CampaignStateStyle>
            )}
          </CampaignDetailsHeaderNameStateStyle>
          {!loadingCampaign && (
            <CampaignDetailsActionIcons>
              {renderActionIcons()}
            </CampaignDetailsActionIcons>
          )}
        </CardHeaderStyle>

        <Tabs onChange={selectSectionTabKey} activeKey={selectedTabKey}>
          <TabPane
            tab={
              <TabHeaderStyle data-test="campaign-information-tab">
                Campaign Information
              </TabHeaderStyle>
            }
            key="1"
          >
            <CampaignInformation
              subSectionTabSelected={subSectionTabSelected}
              selectSubSectionFromTab={selectSubSectionFromTab}
              campaign={campaign}
              onEditSave={onEditSave}
              isCampaignUpdated={isCampaignUpdated}
              fieldsToEdit={fieldsToEdit}
              editCampaignLoading={editLoading}
              campaignLocations={campaignLocations}
              accountTransactions={accountTransactions}
              tsCampaignsReport={tsCampaigns}
              fetchTsCampaignsLoading={fetchTsCampaignsLoading}
              editMode={editMode}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="campaign-material-tab">
                Campaign Material
              </TabHeaderStyle>
            }
            key="2"
          >
            <CampaignMaterial
              campaignCreatives={campaignCreatives}
              campaignName={campaign?.name}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="campaign-material-tab">
                keywords
              </TabHeaderStyle>
            }
            key="3"
          >
            <Keywords
              campaign={campaign}
              getKeywords={getKeywords}
              selectKeywords={selectKeywords}
              resetKeywords={resetKeywords}
              bingKeywords={bingKeywords}
              googleKeywords={googleKeywords}
              semrushKeywords={semrushKeywords}
              campaign_countries={campaign?.countries}
              bingGetKeywordsLoading={bingGetKeywordsLoading}
              googleGetKeywordsLoading={googleGetKeywordsLoading}
              semrushGetKeywordsLoading={semrushGetKeywordsLoading}
              bingSaveKeywordsLoading={bingSaveKeywordsLoading}
              manualSaveKeywordsLoading={manualSaveKeywordsLoading}
              googleSaveKeywordsLoading={googleSaveKeywordsLoading}
              semrushSaveKeywordsLoading={semrushSaveKeywordsLoading}
              getSelectedKeywords={getSelectedKeywords}
              resetSelectedKeywords={resetSelectedKeywords}
              resetSourceTobeRefreshed={resetSourceTobeRefreshed}
              sourceTobeRefreshed={sourceTobeRefreshed}
              bingSelectedKeywords={bingSelectedKeywords}
              googleSelectedKeywords={googleSelectedKeywords}
              semrushSelectedKeywords={semrushSelectedKeywords}
              manualSelectedKeywords={manualSelectedKeywords}
              googleGetSelectedKeywordsLoading={
                googleGetSelectedKeywordsLoading
              }
              semrushGetSelectedKeywordsLoading={
                semrushGetSelectedKeywordsLoading
              }
              bingGetSelectedKeywordsLoading={bingGetSelectedKeywordsLoading}
              manualGetSelectedKeywordsLoading={
                manualGetSelectedKeywordsLoading
              }
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="optimization-tab">
                Optimization
              </TabHeaderStyle>
            }
            key="4"
          >
            <Optimization
              campaign={campaign}
              onEditSave={onEditSave}
              editMode={editMode}
              isCampaignUpdated={isCampaignUpdated}
              getCampaignRules={onGetCampaignRules}
              campaignRules={campaignRules}
              fetchCampaignRulesLoading={fetchCampaignRulesLoading}
              fieldsToEdit={fieldsToEdit}
              editCampaignLoading={editLoading}
              onGetCampaignIntegrations={onGetCampaignIntegrations}
              getKampaniaData={onGetKampaniaData}
              fetchKampaniaDataLoading={fetchKampaniaDataLoading}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="publishing-tab">
                Publishing
              </TabHeaderStyle>
            }
            key="5"
          >
            <Publishing
              campaign={campaign}
              getCampaignWorkflowRuns={onGetCampaignWorkflowRuns}
              getTsCampaignsStatus={getTsCampaignsStatus}
              campaignWorkflowRuns={campaignWorkflowRuns}
              tsCampaignsReport={tsCampaigns}
              tsCampaignsStatus={tsCampaignsStatus}
              failedToFetchStatus={failedToFetchStatus}
              fetchCampaignWorkflowRunsLoading={
                fetchCampaignWorkflowRunsLoading
              }
              fetchTsCampaignsLoading={fetchTsCampaignsLoading}
              accountTransactions={accountTransactions}
              cloneCampaign={cloneCampaign}
              cloneCampaignLoading={cloneCampaignLoading}
              editTSCampaignWeight={editTSCampaignWeight}
              editWeightLoading={editWeightLoading}
              doneEditingWeight={doneEditingWeight}
              setPublishCampaignDrawerVisible={setPublishCampaignDrawerVisible}
              toggleTSCampaign={toggleTSCampaign}
              toggleTSCampaignLoading={toggleTSCampaignLoading}
              shouldRefreshTable={shouldRefreshTable}
              setShouldRefreshTable={setShouldRefreshTable}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="analytics-tab">
                Analytics
              </TabHeaderStyle>
            }
            key="6"
          >
            <Analytics
              subSectionTabSelected={subSectionTabSelected}
              selectSubSectionFromTab={selectSubSectionFromTab}
              campaign={campaign}
              opentagScripts={opentagScripts}
              getGaGoals={onGetGaGoals}
              getCampaignScripts={getCampaignScripts}
              fetchCampaignScriptsLoading={fetchCampaignScriptsLoading}
              gaGoals={gaGoals}
              gaViews={gaViews}
              editOpentagScripts={onEditOpentagScripts}
              editCampaignOpenTagLoading={editCampaignOpenTagLoading}
              getCampaignGAViews={onGetCampaignGAViews}
              fetchCampaignGAViewsLoading={fetchCampaignGAViewsLoading}
              patchCampaignGAView={onPatchCampaignGAView}
              fetchCampaignGoalsLoading={fetchCampaignGoalsLoading}
              patchCampaignGAViewLoading={patchCampaignGAViewLoading}
              generateIntegrationToken={onGenerateIntegrationToken}
              generateGaIntegrationTokenLoading={
                generateGaIntegrationTokenLoading
              }
              generateFbIntegrationTokenLoading={
                generateFbIntegrationTokenLoading
              }
              gaIntegrationToken={gaIntegrationToken}
              fbIntegrationToken={fbIntegrationToken}
              getCampaignIntegrations={onGetCampaignIntegrations}
              fetchCampaignIntegrationsLoading={
                fetchCampaignIntegrationsLoading
              }
              selectedTabKey={selectedTabKey}
            />
          </TabPane>
          <TabPane
            tab={
              <TabHeaderStyle data-test="actions-tab">Actions</TabHeaderStyle>
            }
            key="7"
          >
            <Actions
              campaign={campaign}
              revokeGaIntegration={onRevokeGaIntegration}
              revokeFbIntegration={onRevokeFbIntegration}
              getPaymanCharges={getPaymanCharges}
              chargeCampaign={chargeCampaign}
              remainingBudget={remainingBudget}
              paymanAccountId={paymanAccountId}
              chargeCampaignLoading={chargeCampaignLoading}
              revokeGaLoading={revokeGaLoading}
              revokeFbLoading={revokeFbLoading}
              onEditCampaign={onEditSave}
              isCampaignUpdated={isCampaignUpdated}
              fieldsToEdit={fieldsToEdit}
              editCampaignLoading={editLoading}
            />
          </TabPane>
        </Tabs>
      </Spin>
      <PublishCampaign
        getCampaignWorkflowRuns={onGetCampaignWorkflowRuns}
        campaign={campaign}
        campaignCreatives={campaignCreatives}
        campaignLinks={campaignLinks}
        setPublishCampaignDrawerVisible={setPublishCampaignDrawerVisible}
        isPublishCampaignDrawerVisible={isPublishCampaignDrawerVisible}
        fieldsToEdit={fieldsToEdit}
        onEditCampaign={onEditSave}
        isPackageUpdated={isPackageUpdated}
        editMode={editMode}
        campaignWorkflowRuns={campaignWorkflowRuns}
        editTSCampaignWeight={editTSCampaignWeight}
        editWeightLoading={editWeightLoading}
        doneEditingWeight={doneEditingWeight}
        editCampaignLoading={editLoading}
      />
    </ContainerStyle>
  );
};

export default connect(CampaignDetails, Module);
