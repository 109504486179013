import { CampaignsApi, AuthApi, ElasticSearch } from 'utils/apiCalls';
import * as helpers from 'utils/helpers';
import theme from 'styles/themes';
import { handleApiError } from 'utils/helpers';
import { showMessage } from 'utils/helpers';
import moment from 'moment';
import { COOKIES_NOTIFICATION_LAST_CHK } from '../../utils/constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export default {
  name: 'topNav',

  state: {
    loading: false,
    error: false,
    updateCampaignsCount: false,
    errorMsg: '',
    campaignsStateCount: [],
    notifications: [],
    getNotificationsLoading: false,
    newMsgCount: 0,
  },

  actions: {
    *getCampaignsStateCount() {
      try {
        const campaignsStateCountResponse = yield CampaignsApi.getCampaignsStateCount().catch(
          e => e,
        );

        helpers.handleApiError(campaignsStateCountResponse);

        const stateColorMapper = {
          RUNNING: theme.colors.green,
          PENDING_PAID: theme.colors.blue,
          PAUSED: theme.colors.orange,
        };

        const campaignsStateCount = [];

        Object.keys(stateColorMapper).forEach(state => {
          campaignsStateCount.push({
            name: state,
            value: campaignsStateCountResponse[state] || 0,
            color: stateColorMapper[state],
          });
        });

        yield {
          campaignsStateCount,
          updateCampaignsCount: false,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          loading: false,
          updateCampaignsCount: false,
          error: true,
          errorMsg,
        };
      }
    },
    *getCampaignNotifications() {
      try {
        yield { getNotificationsLoading: true };

        const notificationsRes = yield ElasticSearch.getNotifications(
          null,
          'ALL',
          20,
        ).catch(e => e);

        handleApiError(notificationsRes);

        let newMsgCount = 0;

        const notifications = notificationsRes?.hits?.hits;

        const lastChkNotificationOn = cookies.get(
          COOKIES_NOTIFICATION_LAST_CHK,
        );

        notifications.forEach(notification => {
          const notificationDate = moment.utc(notification._source.inserted_on);
          const lastChkDate = moment.utc(lastChkNotificationOn);

          notificationDate.isAfter(lastChkDate) && newMsgCount++;
        });

        yield {
          getNotificationsLoading: false,
          notifications,
          newMsgCount,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getNotificationsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Notifications${message ? `: ${message}` : ''}`,
        );
      }
    },
    resetNotifications() {
      return {
        notifications: [],
      };
    },
    resetCount() {
      return {
        newMsgCount: 0,
      };
    },
    *getUser() {
      try {
        const user = yield AuthApi.getUser().catch(e => e);

        handleApiError(user);

        yield {
          user,
        };

        cookies.set('userId', user._id);
      } catch (err) {
        const { message } = err;
        showMessage(
          'error',
          `Error while fetching user${message ? `: ${message}` : ''}`,
        );
      }
    },
    *refreshMonitoring() {
      try {
        yield {
          refreshMonitoringLoading: true,
        };

        const checkRes = yield ElasticSearch.checkMonitoring().catch(e => e);

        handleApiError(checkRes);

        if (checkRes.state === 'PENDING') {
          showMessage(
            'warning',
            'There is a running task now.. data should be updated soon!',
          );

          yield {
            refreshMonitoringLoading: false,
          };
        } else {
          const refreshMonitoringRes = yield ElasticSearch.refreshMonitoring().catch(
            e => e,
          );

          handleApiError(refreshMonitoringRes);

          yield {
            refreshJobId: refreshMonitoringRes?.task_id,
          };
        }
      } catch (err) {
        const { message } = err;
        yield {
          getNotificationsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Notifications${message ? `: ${message}` : ''}`,
        );
      }
    },
    *getMonitoringStatus() {
      try {
        const getMonitoringStatusRes = yield ElasticSearch.getMonitoringStatus(
          this.getState('refreshJobId'),
        ).catch(e => e);

        handleApiError(getMonitoringStatusRes);

        const jobStatus = getMonitoringStatusRes?.objects?.status;

        if (jobStatus === 'FAILURE') {
          yield {
            refreshJobId: '',
            refreshMonitoringLoading: false,
          };
          showMessage(
            'error',
            `Failed to refresh monitoring data, please try again later!`,
          );
        } else if (jobStatus === 'SUCCESS') {
          yield {
            refreshJobId: '',
            refreshMonitoringLoading: false,
          };
          showMessage(
            'success',
            `Refreshing monitoring data done successfully.. please refresh your page!`,
          );
        }
      } catch (err) {
        const { message } = err;
        yield {
          refreshMonitoringLoading: false,
        };
        showMessage(
          'error',
          `Error while getting monitoring status ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },
  },
  handlers: {
    '@@Campaigns/GET_ALL_CAMPAIGNS/COMPLETE': function() {
      return {
        updateCampaignsCount: true,
      };
    },
  },
};
