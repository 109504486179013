import moment from 'moment';

export const POP_TRAFFIC_SOURCE = ['propeller', 'zp'];

const getClicks = (balance, cpc, isBonus = false) => {
  if (balance <= 0) return 0;
  if (!isBonus && balance % 2 !== 0) balance++;

  return Math.ceil(balance / cpc);
};

export const getCampaignCapping = (campaign, weight) => {
  let net_clicks = 0;
  let bonus_clicks = 0;
  let cap = 0;
  let next_renewal_date = null;

  if (campaign.package.package_type === 'custom') {
    net_clicks = campaign.package.total_visits;
    bonus_clicks = Math.ceil(campaign.package.bonus / campaign.cpc);
    next_renewal_date = campaign.package.custom_end_date;
  } else {
    const objective = campaign.objective;
    const cpc =
      objective === 'conversions'
        ? process.env.REACT_APP_CONVERSIONS_CPC_DEFAULT_VALUE
        : process.env.REACT_APP_AWARENESS_CPC_DEFAULT_VALUE;
    net_clicks = getClicks(campaign.package.balance, cpc);
    bonus_clicks = getClicks(campaign.package.bonus, cpc, true);
    next_renewal_date = campaign.package.next_payment_date;
  }

  const ordered_clicks = net_clicks + bonus_clicks;
  const start_date = moment(campaign.package.last_renew_date);
  const end_date = moment(next_renewal_date);
  const package_duration = end_date.diff(start_date, 'days') + 2;

  if (!isNaN(package_duration) && package_duration > 0) {
    cap = Math.ceil(ordered_clicks / package_duration);
  }

  return cap * weight;
};
