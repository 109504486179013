import React from 'react';
import { StatisticsContainerStyle } from './style';

const Statistics = props => {
  return (
    <StatisticsContainerStyle>
      {props.data &&
        props.data.map(item => (
          <span key={`${item.name}${item.value}`}>
            <dt>{item.name}</dt>
            <dd style={{ color: item.color }}>{item.value}</dd>
          </span>
        ))}
    </StatisticsContainerStyle>
  );
};

export default Statistics;
