import { GAApi, CampaignsApi } from 'utils/apiCalls';
import {
  reformatGAData,
  handleApiError,
  getPackageStartDate,
  getPackageEndDate,
} from 'utils/helpers';

import tableColumns from './tableColumns';

export default {
  name: 'gaIntegration',
  state: {
    loading: false,
    error: false,
    errorMsg: '',
    UTMSources: [
      {
        sessions: '0',
        source: 'instascaler',
      },
    ],
    reports: {},
    rank: 1,
    formattedData: [],
    tableCols: [],
    selectedUTMSource: '',
    UTMKeywordsList: [],
    loadingUTMKeywordText: false,
  },
  actions: {
    *getUTMSources(params) {
      try {
        yield { loading: true };
        const { campaignId, startDate, endDate, keywords, utmFilter } = params;

        const campaign = yield CampaignsApi.getCampaignById(campaignId).catch(
          e => e,
        );

        handleApiError(campaign);

        const campaignObj = campaign.campaign;

        const campaignStartDate = getPackageStartDate(campaignObj?.package);
        const campaignEndDate = getPackageEndDate(campaignObj?.package);

        yield {
          campaignStartDate,
          campaignEndDate,
        };

        const UTMSourcesParams = {
          analyticsId: campaignObj.analytics_id,
          startDate: startDate || campaignStartDate,
          endDate: endDate || campaignEndDate,
        };

        const UTMSources = yield GAApi.getUTMSources(UTMSourcesParams).catch(
          e => e,
        );

        handleApiError(UTMSources);

        let rank = 0;
        UTMSources.sort((a, b) => b.sessions - a.sessions);

        if (UTMSources.length) {
          if (!UTMSources.find(e => e.source === utmFilter)) {
            yield {
              selectedUTMSource: UTMSources[0].source,
            };
          }
        }

        UTMSources.forEach((source, i) => {
          if (source.source === utmFilter) {
            rank = i + 1;
          }
        });

        yield { UTMSources, rank };

        const reportParams = {
          analyticsId: campaignObj.analytics_id,
          startDate: startDate || campaignStartDate,
          endDate: endDate || campaignEndDate,
          utmFilter,
          keywords,
        };

        const reports = yield GAApi.getGAReport(reportParams).catch(e => e);

        handleApiError(reports);

        yield {
          reports,
          loading: false,
        };

        if (Object.keys(reports).length) {
          const table = reformatGAData(reports, tableColumns);
          yield {
            formattedData: table.tableData,
            tableCols: table.tableCols,
          };
        }
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          loading: false,
          error: true,
          errorMsg: errorMsg,
        };
      }
    },

    *getUTMSourceKeywordText(utmIDs) {
      let UTMkeywordText = this.getState('UTMKeywordsList').find(
        savedUTM =>
          savedUTM.keywordId === utmIDs.keywordId &&
          savedUTM.accountId === utmIDs.accountId &&
          savedUTM.adgroupId === utmIDs.adgroupId,
      );

      if (!UTMkeywordText) {
        yield {
          loadingUTMKeywordText: true,
        };

        UTMkeywordText = yield GAApi.getUTMKeywordText(utmIDs).catch(e => e);
      }

      const newUTMKeywordsList = [...this.getState('UTMKeywordsList')];

      if (UTMkeywordText instanceof Error) {
        newUTMKeywordsList.push({
          ...utmIDs,
          text: 'Unable to get keyword for this UTM',
        });
      } else {
        newUTMKeywordsList.push({
          ...utmIDs,
          text: UTMkeywordText.keyword_text,
        });
      }

      yield {
        loadingUTMKeywordText: false,
        UTMKeywordsList: newUTMKeywordsList,
      };
    },

    selectUTMSource(selectedUTMSource) {
      return {
        selectedUTMSource,
      };
    },

    resetState() {
      return {
        loading: false,
        error: false,
        errorMsg: '',
        UTMSources: [
          {
            sessions: '0',
            source: 'instascaler',
          },
        ],
        reports: {},
        rank: 1,
        formattedData: [],
        tableCols: [],
        selectedUTMSource: '',
        UTMKeywordsList: [],
        loadingUTMKeywordText: false,
      };
    },
  },
};
