import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Button, Checkbox, Input } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  DivFlexCenterCenter,
  DivFlexSpaceBetween,
  FormItemFlexGrow,
} from 'styles/styledComponents';

const LoginForm = props => {
  const { getFieldDecorator } = props.form;

  return (
    <FormItemFlexGrow>
      <Form onSubmit={props.handleSubmitLoginForm}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              data-test="email"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              data-test="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        <DivFlexSpaceBetween>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
          </Form.Item>
          {props.canResetPassword && (
            <Link to={props.pathForgetPassword}>Forgot password</Link>
          )}
        </DivFlexSpaceBetween>
        <DivFlexCenterCenter>
          <Form.Item style={{ marginTop: '2rem', marginBottom: 0 }}>
            <Button
              data-test="login-btn"
              type="primary"
              htmlType="submit"
              loading={props.loading}
            >
              LOG IN
            </Button>
          </Form.Item>
        </DivFlexCenterCenter>
      </Form>
    </FormItemFlexGrow>
  );
};

export default Form.create()(LoginForm);
