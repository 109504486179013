import styled from 'styled-components';

export const UploadContainerStyle = styled.div`
  width: 100%;
`;

export const CustomerListStyle = styled.div`
  width: 100%;

  .ant-page-header.ant-page-header-ghost {
    padding: 0 1rem;
    width: 100%;
    display: flex;
  }

  .activeRow {
    background-color: #ccd0e1;
  }

  .ant-table-row.ant-table-row-level-0 {
    cursor: pointer;
  }
`;
