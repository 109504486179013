export const trafficSourcesOptions = {
  googleSearch: [
    {
      label: 'Create Retargting Campaign',
      value: 'attach_google_userlist',
    },
    {
      label: 'Create Converting Keywords Campaign',
      value: 'bmm_campaign',
    },
  ],

  googleDisplay: [
    {
      label: 'Create Retargting Campaign',
      value: 'attach_google_userlist',
    },
  ],
};

export const filterTrafficSources = campaign => ({
  zpGA: {
    name: 'ZP - GA Integrated',
    legacyMode: true,
    on_semaphore: true,
    disabled: campaign?.countries?.length > 1,
  },
  zpNotGA: {
    name: 'ZP - GA NOT Integrated',
    legacyMode: true,
    disabled: campaign?.countries?.length > 1,
  },
  googleSearch: {
    name: 'Google Search',
    legacyMode: false,
    disabled: false,
  },
  googleDisplay: {
    name: 'Google Display',
    legacyMode: false,
    disabled: false,
  },
  facebook: {
    name: 'Facebook',
    legacyMode: false,
    disabled: !campaign.is_fb_integrated,
  },
  propellerGA: {
    name: 'Propeller - GA Integrated',
    legacyMode: false,
    on_semaphore: true,
    publish_testing_campaign: true,
    disabled: false,
  },
  propellerNotGA: {
    name: 'Propeller - GA NOT Integrated',
    legacyMode: false,
    on_semaphore: true,
    disabled: false,
  },
  bing: {
    name: 'Bing',
    legacyMode: false,
    disabled: false,
  },
});
