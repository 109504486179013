import React, { useMemo, useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import {
  Drawer,
  Button,
  Card,
  Menu,
  Dropdown,
  Checkbox,
  Alert,
  InputNumber,
  DatePicker,
} from 'antd';
import useMedia from 'use-media';
import JSZip from 'jszip';
import {
  MOB_SCREEN_WIDTH,
  MOB_WIDTH_FOR_DRAWER,
  flexBetweenCenter,
  DATE_FORMAT,
} from 'utils/constants';
import PropTypes from 'prop-types';
import { PublishCampaignCardsStyle, DatePickerContainer } from './styles';
import { formatList, renderKeywords, showMessage } from 'utils/helpers';
import Bing from './Bing';
import Propeller from './Propeller';
import GoogleSearch from './GoogleSearch';
import GoogleDisplay from './GoogleDisplay';
import Facebook from './Facebook';
import { connect } from 'speedux';
import Module from './module';
import { FormContainerStyle } from 'styles/styledComponents';
import { filterTrafficSources, trafficSourcesOptions } from './constants';
import moment from 'moment';
import ZeroPark from './ZeroPark';
import WeightSlider from './SharedComponents/weightSlider';
import EditableField from 'components/EditableField';
import Validation from 'utils/Validation';
import { getTrafficSourceName } from 'pages/CampaignDetails/Publishing/WfRunsTable/tableHelpers';
import styled from 'styled-components';
import { POP_TRAFFIC_SOURCE, getCampaignCapping } from './publishing-helpers';

const StyledCurrentCampaigns = styled.ul`
  list-style: none;
  padding: 0;

  & li {
    list-style: none;
  }
`;

const StyledTsWorkFlow = styled.p`
  margin-top: 1em;
  margin-bottom: 0.2em;
  background-color: rgb(239, 239, 239);
  padding: 1px 5px;
`;

const schdulePublishingDefaultDate = moment()
  .add(1, 'days')
  .format(DATE_FORMAT);
function PublishCampaign({
  isPublishCampaignDrawerVisible,
  getCampaignWorkflowRuns,
  setPublishCampaignDrawerVisible,
  campaign,
  campaignLinks,
  campaignCreatives,
  fieldsToEdit,
  editCampaignLoading,
  onEditCampaign,
  editMode,
  campaignWorkflowRuns,
  editTSCampaignWeight,
  editWeightLoading,
  doneEditingWeight,
  isPackageUpdated,
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    setFieldsValue,
    resetFields,
  },
  actions: {
    addNewPublishAccount,
    publishCampaign,
    generatePresignedUrl,
    uploadFile,
    resetUpload,
    resetState,
    getTrafficSourceAccounts,
    getFinalUrl,
    getVerticals,
    getAudienceIds,
    getPageIds,
    getKeywords,
    resetKeywords,
    generateKeywords,
  },
  state: {
    addingAccountLoading,
    accountAdded,
    publishCampaignLoading,
    isPublishCampaignSuccess,
    generatedURL,
    uploadedSuccessfully,
    publishCampaignErrorMessage,
    publishedCampaign,
    trafficSourceAccountList,
    getTrafficSourceAccountsLoading,
    finalUrlData,
    verticalsList,
    getVerticalsLoading,
    allFbAudiences,
    allPagesIds,
    isAudianceListLoading,
    isPageLoading,
    campaignKeywords,
    keywordsLoading,
  },
}) {
  const [editedValues, setEditedValues] = useState({
    abm: '',
  });
  const filteredTrafficSources = campaign && filterTrafficSources(campaign);
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });
  const [selectedTrafficSource, selectTrafficSource] = useState('');
  const [weight, setWeight] = useState(100);
  const [uploadFilesData, setUploadFilesData] = useState({});
  const [wfRunweight, setWfRunWeight] = useState(null);
  const [schedulePublishing, setSchedulePublishing] = useState(false);
  const [schedulePublishingDate, setSchedulePublishingDate] = useState(
    schdulePublishingDefaultDate,
  );
  useEffect(() => {
    if (isPublishCampaignDrawerVisible) getCampaignWorkflowRuns(campaign._id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishCampaignDrawerVisible]);

  const base64ImageToBlob = ({ name, extension, base64 }) => {
    // extract content type and base64 payload from original string
    const type = extension;
    const b64 = base64;

    // decode base64
    const imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      // eslint-disable-line
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new File([buffer], name, { type });

    return blob;
  };

  const handleDownloadOldFile = banners => {
    const filesToUpload = banners.map(base64ImageToBlob);

    const zip = new JSZip();

    filesToUpload.forEach(img => zip.file(img.name, img, { base64: true }));

    zip.generateAsync({ type: 'blob' }).then(content => {
      content.lastModifiedDate = new Date(); // eslint-disable-line
      content.name = 'images.zip'; // eslint-disable-line

      setUploadFilesData({
        zip: content,
        filesToUpload: [{ uid: 0, name: 'images.zip', ...zip }],
      });
    });
  };

  const resetCurrentState = () => {
    setWeight(100);
    selectTrafficSource('');
  };

  const reformatTrafficSourceNaming = trafficSrc => {
    if (trafficSrc.includes('zp')) {
      return 'zp';
    } else if (trafficSrc.includes('propeller')) {
      return 'propeller';
    }
    return trafficSrc;
  };

  const defaultCPC = campaign?.auto_bidding
    ? process.env.REACT_APP_AUTO_CPC_DEFAULT_VALUE
    : campaign?.objective === 'awareness'
    ? process.env.REACT_APP_AWARENESS_CPC_DEFAULT_VALUE
    : process.env.REACT_APP_CONVERSIONS_CPC_DEFAULT_VALUE;

  const validateAndSubmit = (err, formValues) => {
    if (!err) {
      let trafficSource = reformatTrafficSourceNaming(selectedTrafficSource);

      if (selectedTrafficSource === 'googleSearch') {
        trafficSource =
          formValues.trafficSource === 'responsive'
            ? 'google_responsive'
            : 'google';
      }

      if (selectedTrafficSource === 'googleDisplay') {
        trafficSource = 'google_display';
      }

      let headlines;
      let descriptions;

      if (selectedTrafficSource === 'facebook') {
        headlines = Object.assign(
          {},
          ...formValues.headlines.map((val, index) => ({
            [`headline_part${index + 1}`]: val,
          })),
        );

        descriptions = Object.assign(
          {},
          ...formValues.description.map((val, index) => ({
            [`description${index === 0 ? '' : index + 1}`]: val,
          })),
        );
      }

      const {
        keywords,
        rangePicker,
        audience_list_id = '',
        description = '',
        description2 = '',
        description3 = '',
        description4 = '',
        description5 = '',
        headline_part1 = '',
        headline_part2 = '',
        headline_part3 = '',
        headline_part4 = '',
        headline_part5 = '',
        text1 = '',
        text2 = '',
        text3 = '',
        text4 = '',
        text5 = '',
        ad_text1 = '',
        ad_text2 = '',
        title_part1 = '',
        title_part2 = '',
        title_part3 = '',
        bidding_strategy = '',
        cpc_amount = defaultCPC,
        attach_google_userlist = false,
        bmm_campaign = false,
        page_id = '',
        keywords_as_seed = false,
        use_keywords_in_display = false,
        topics = '',
        account_id = '',
        images_url = '',
      } = formValues;

      let { weight } = formValues;

      weight = weight / 100;

      let cap = 0;

      if (POP_TRAFFIC_SOURCE.includes(trafficSource)) {
        cap = getCampaignCapping(campaign, weight);
      }

      const body = {
        keywords: keywords?.join(';') || '',
        weight,
        start_date:
          (rangePicker && moment.utc(rangePicker[0]).format(DATE_FORMAT)) || '',
        end_date:
          (rangePicker && moment.utc(rangePicker[1]).format(DATE_FORMAT)) || '',
        description,
        description2,
        description3,
        description4,
        description5,
        headline_part1,
        headline_part2,
        headline_part3,
        headline_part4,
        headline_part5,
        ...(selectedTrafficSource === 'facebook'
          ? { ...descriptions, ...headlines }
          : {}),
        text1,
        text2,
        text3,
        text4,
        text5,
        ad_text: ad_text1,
        ad_text2,
        title_part1,
        title_part2,
        title_part3,
        audience_list_id,
        bidding_strategy,
        cpc_amount,
        legacy_mode:
          filteredTrafficSources[selectedTrafficSource].legacyMode || false,
        on_semaphore:
          filteredTrafficSources[selectedTrafficSource].on_semaphore || false,
        publish_testing_campaign:
          filteredTrafficSources[selectedTrafficSource]
            .publish_testing_campaign || false,
        attach_google_userlist,
        bmm_campaign,
        page_id,
        keywords_as_seed,
        use_keywords_in_display,
        topics,
        account_id,
        total_budget:
          (campaign.package.balance + campaign.package.bonus) *
            campaign.package.abm *
            weight || null,
        cities: campaign.targeted_locations.join(),
        cap,
        images_url,
      };
      const publishing_due = schedulePublishing ? schedulePublishingDate : null;
      publishCampaign(
        campaign._id,
        campaign?.kampania_id,
        trafficSource,
        body,
        publishing_due,
      );
    }
  };

  const isSaveButtonDisabled = (name, value) =>
    !editedValues[name] || editedValues[name]?.toString() === value?.toString();

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  const handlePublishCampaignSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, formValues) => {
      validateAndSubmit(err, formValues);
    });
  };

  const renderSourcesMenu = useMemo(() => {
    return (
      <Menu
        onClick={({ key: trafficSourceKey }) => {
          selectTrafficSource(trafficSourceKey);
        }}
      >
        {Object.keys(filteredTrafficSources).map(key => {
          const { disabled, name } = filteredTrafficSources[key];
          return (
            <Menu.Item
              key={key}
              disabled={disabled}
              data-test={`${key}MenuItem`}
            >
              {name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [filteredTrafficSources]);

  const renderTrafficSourceOptions = () => {
    if (
      selectedTrafficSource === 'googleDisplay' ||
      selectedTrafficSource === 'googleSearch'
    ) {
      return (
        <>
          {trafficSourcesOptions[selectedTrafficSource].map(
            trafficSourceOption => (
              <Form.Item style={{ margin: 0 }} key={trafficSourceOption.label}>
                {getFieldDecorator(trafficSourceOption.value, {
                  initialValue: false,
                })(
                  <Checkbox data-test={trafficSourceOption.value}>
                    {trafficSourceOption.label}
                  </Checkbox>,
                )}
              </Form.Item>
            ),
          )}
        </>
      );
    }

    return null;
  };

  const [editModeWfRun, setEditModeWfRun] = useState(null);

  useEffect(() => {
    if (doneEditingWeight) {
      setEditModeWfRun(false);
    }

    return () => setEditModeWfRun(null);
  }, [doneEditingWeight]);

  const renderPublishForm = () => {
    const passedProps = {
      publishCampaign,
      campaign,
      renderKeywords,
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
      generatedURL,
      uploadFileAction: uploadFile,
      resetUpload,
      selectedTrafficSource,
      campaignKeywords,
      keywordsLoading,
      generateKeywords,
      resetKeywords,
      defaultCPC,
    };

    const { headlines, descriptions } = campaignCreatives;

    const trafficSourcesComponents = {
      googleDisplay: (
        <GoogleDisplay
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          getVerticals={getVerticals}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          verticalsList={verticalsList}
          getVerticalsLoading={getVerticalsLoading}
          uploadFilesData={uploadFilesData}
          addNewPublishAccount={addNewPublishAccount}
          addingAccountLoading={addingAccountLoading}
          accountAdded={accountAdded}
          {...passedProps}
        />
      ),
      bing: (
        <Bing
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          addNewPublishAccount={addNewPublishAccount}
          addingAccountLoading={addingAccountLoading}
          accountAdded={accountAdded}
          {...passedProps}
        />
      ),
      propellerGA: <Propeller {...passedProps} />,
      propellerNotGA: <Propeller {...passedProps} />,
      googleSearch: (
        <GoogleSearch
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          headlines={headlines}
          descriptions={descriptions}
          addNewPublishAccount={addNewPublishAccount}
          addingAccountLoading={addingAccountLoading}
          accountAdded={accountAdded}
          {...passedProps}
        />
      ),
      facebook: (
        <Facebook
          allFbAudiences={allFbAudiences}
          allPagesIds={allPagesIds}
          isAudianceListLoading={isAudianceListLoading}
          isPageLoading={isPageLoading}
          getAudienceIds={getAudienceIds}
          getPageIds={getPageIds}
          {...passedProps}
        />
      ),
      zpGA: <ZeroPark {...passedProps} />,
      zpNotGA: <ZeroPark {...passedProps} />,
    };

    if (selectedTrafficSource in trafficSourcesComponents) {
      return trafficSourcesComponents[selectedTrafficSource];
    }
  };

  const renderCurrentCampaigns = () => {
    return (
      <StyledCurrentCampaigns>
        {campaignWorkflowRuns.map(
          ({ workflow_name, weight: workflow_weight, wf_run_id }) => {
            workflow_weight *= 100;
            const newWeight = wfRunweight || workflow_weight;

            return (
              <li>
                <StyledTsWorkFlow>{`${
                  getTrafficSourceName(workflow_name).name
                }: ${workflow_weight}% - ${(
                  (workflow_weight *
                    campaign?.package?.balance *
                    campaign?.package?.abm) /
                  100
                ).toFixed(2)} USD`}</StyledTsWorkFlow>

                <EditableField
                  editMode={editModeWfRun}
                  value={`${workflow_weight}%`}
                  disableSaveButton={
                    !wfRunweight || wfRunweight === workflow_weight
                  }
                  onSave={() => {
                    editTSCampaignWeight(newWeight, wf_run_id);
                    setWfRunWeight(null);
                  }}
                  onCancel={() => {
                    setWfRunWeight(null);
                  }}
                  loadingEdit={editWeightLoading}
                >
                  <InputNumber
                    placeholder="Total Visits"
                    defaultValue={workflow_weight}
                    onChange={e => setWfRunWeight(e)}
                    data-test={`total_visits-input`}
                    max={100}
                  />
                </EditableField>
              </li>
            );
          },
        )}
      </StyledCurrentCampaigns>
    );
  };

  useEffect(() => {
    if (isPublishCampaignDrawerVisible) {
      getFinalUrl(campaign.url);
    }
  }, [isPublishCampaignDrawerVisible]); // eslint-disable-line

  useEffect(() => {
    resetFields();
    resetState();
    setWeight(100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrafficSource]);

  useEffect(() => {
    if (campaign) generatePresignedUrl(campaign._id);
  }, [campaign, generatePresignedUrl]);

  useEffect(() => {
    const kampaniaLink = campaignLinks?.kampania;
    if (kampaniaLink) {
      getKeywords(kampaniaLink);
    }

    return () => {
      resetKeywords();
    };
  }, [campaignLinks]); // eslint-disable-line

  useEffect(() => {
    if (isPublishCampaignSuccess && Object.keys(publishedCampaign).length) {
      setPublishCampaignDrawerVisible(false);
      const partialErrors = publishedCampaign?.partial_errors?.split(';');

      if (partialErrors) {
        showMessage(
          'warning',
          <span>
            Campaign Published! with the following errors:
            <ul>
              {partialErrors?.map(error => (
                <li>{error}</li>
              ))}
            </ul>
          </span>,
        );
      } else {
        showMessage('success', 'Campaign Published Successfully!');
      }
      resetState();
      resetCurrentState();
      return () => {
        resetState();
        resetCurrentState();
      };
    }
  }, [
    isPublishCampaignSuccess,
    publishedCampaign,
    resetState,
    setPublishCampaignDrawerVisible,
  ]);

  useEffect(() => {
    if (campaignCreatives?.banners?.length) {
      handleDownloadOldFile(campaignCreatives?.banners);
    }

    return () => setUploadFilesData({});
  }, [campaignCreatives?.banners]); // eslint-disable-line

  const { finalUrl, redirectionOccured } = finalUrlData;

  const disabledDate = current => current && current < moment().endOf('day');

  return (
    <Drawer
      title="Publish Campaign"
      placement="right"
      {...(isMob
        ? { width: MOB_WIDTH_FOR_DRAWER }
        : { width: MOB_SCREEN_WIDTH })}
      onClose={() => {
        setPublishCampaignDrawerVisible(false);
        resetState();
        resetCurrentState();
      }}
      visible={isPublishCampaignDrawerVisible}
      destroyOnClose
    >
      <PublishCampaignCardsStyle>
        <FormContainerStyle>
          {redirectionOccured && finalUrl && (
            <Alert
              style={{ marginBottom: 24 }}
              description={
                <>
                  <p>A redirection occurs to this campaign url.</p>
                  <p>
                    Final url: <b>{finalUrl}</b>
                  </p>
                </>
              }
              type="warning"
            />
          )}
          <Form onSubmit={handlePublishCampaignSubmit}>
            <Card title="Campaign Info" size="small">
              <dl>
                <dt>Name</dt>
                <dd>{campaign?.name}</dd>

                <dt>Url</dt>
                <dd>{campaign?.url}</dd>

                <dt>Countries</dt>
                <dd>{formatList(campaign?.countries)}</dd>

                <dt>Devices</dt>
                <dd>{formatList(campaign?.devices)}</dd>
              </dl>
            </Card>
            <Card title="Traffic Source" size="small">
              <Dropdown overlay={renderSourcesMenu} style={{ width: '100%' }}>
                <Button
                  style={{
                    width: '100%',
                    ...flexBetweenCenter,
                  }}
                  data-test="selectTrafficSourceButton"
                >
                  {filteredTrafficSources[selectedTrafficSource]?.name ||
                    'Select a traffic source'}{' '}
                  <DownOutlined />
                </Button>
              </Dropdown>

              {selectedTrafficSource ? (
                <WeightSlider
                  campaign={campaign}
                  weight={weight}
                  setWeight={setWeight}
                  selectedTrafficSource={selectedTrafficSource}
                  setFieldsValue={setFieldsValue}
                  getFieldDecorator={getFieldDecorator}
                />
              ) : null}

              <div
                style={{
                  marginTop: '1rem',
                }}
              >
                {renderTrafficSourceOptions()}
              </div>
            </Card>
            {renderPublishForm()}
            <Card
              title="Publishing Summary"
              size="small"
              bodyStyle={{
                maxHeight: 200,
                overflowY: 'scroll',
              }}
            >
              <dl>
                <dt>Budget</dt>
                <dd>{campaign?.package?.balance} USD</dd>

                <dt>Margin</dt>
                <dd>
                  <EditableField
                    editMode={editMode}
                    value={campaign?.package?.abm}
                    disableSaveButton={isSaveButtonDisabled(
                      'abm',
                      campaign?.package?.abm,
                    )}
                    onSave={() => onEditCampaign({ abm: editedValues['abm'] })}
                    onCancel={() => {
                      setEditedValues({
                        ...editedValues,
                        abm: campaign?.package?.abm,
                      });
                      setFieldsValue({ abm: campaign?.package?.abm });
                    }}
                    loadingEdit={isEditLoading('abm')}
                  >
                    <Form.Item rules={[Validation.required]}>
                      <InputNumber
                        placeholder="Margin"
                        type="number"
                        value={editedValues['abm'] || campaign?.package?.abm}
                        min={0}
                        onChange={number =>
                          setEditedValues({
                            ...editedValues,
                            abm: number,
                          })
                        }
                        data-test={`margin-input`}
                      />
                    </Form.Item>
                  </EditableField>
                </dd>

                <dt>Total Assigned Budget</dt>
                <dd>
                  {(
                    campaign?.package?.balance * campaign?.package?.abm
                  ).toFixed(2)}
                  {' USD'}
                </dd>

                <dt>Current Campaigns</dt>
                <dd>{renderCurrentCampaigns()}</dd>
              </dl>
            </Card>
            <p>
              <Checkbox
                checked={schedulePublishing}
                onChange={e => setSchedulePublishing(e.target.checked)}
                data-test="schedulePublishing"
              >
                Schedule Publishing
              </Checkbox>
            </p>
            {schedulePublishing ? (
              <DatePickerContainer>
                <DatePicker
                  style={{ width: '50%' }}
                  format={DATE_FORMAT}
                  value={moment(schedulePublishingDate, DATE_FORMAT)}
                  data-test="schedulePublishing-datepicker"
                  disabledDate={disabledDate}
                  onChange={(date, dateString) =>
                    setSchedulePublishingDate(dateString)
                  }
                />
              </DatePickerContainer>
            ) : null}

            {isPublishCampaignSuccess && publishedCampaign ? null : (
              <Alert
                style={{ marginBottom: 24 }}
                description={publishCampaignErrorMessage}
                type="error"
                closable
              />
            )}
            <Button
              type="primary"
              htmlType="submit"
              loading={publishCampaignLoading}
              data-test="publishButton"
              disabled={!selectedTrafficSource}
            >
              {schedulePublishing ? 'Schedule Publishing' : 'Publish Campaign'}
            </Button>
          </Form>
        </FormContainerStyle>
      </PublishCampaignCardsStyle>
    </Drawer>
  );
}

PublishCampaign.propTypes = {
  campaign: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string),
    _id: PropTypes.number,
  }),
};

PublishCampaign.defaultProps = {
  campaign: {
    countries: [{}],
    _id: '',
  },
};

export default connect(Form.create()(PublishCampaign), Module);
