import { CampaignsApi } from 'utils/apiCalls';
import * as helpers from 'utils/helpers';

export default {
  name: 'AddCustomCampaign',

  state: {
    loading: false,
    error: false,
    errorMsg: '',
  },

  actions: {
    *submitCustomCampaign(customCampaignObj) {
      try {
        yield {
          loading: true,
        };

        const customCampaign = yield CampaignsApi.addCustomCampaign(
          customCampaignObj,
        ).catch(e => e);

        helpers.handleApiError(customCampaign);

        yield {
          loading: false,
        };
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          loading: false,
          error: true,
          errorMsg,
        };
      }
    },
  },
};
