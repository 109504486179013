import React, { useEffect, useState } from 'react';
import { Card, Spin, Timeline, Empty } from 'antd';
import moment from 'moment';

import { connect } from 'speedux';

import Module from './module';
import { CircleStyle } from 'styles/styledComponents';
import {
  CostReportCardsContainerStyle,
  CostBoxesStyle,
  BoxStyle,
} from './styles';
import { flexBetweenCenter, LAPTOP_SCREEN_HEIGHT } from '../../utils/constants';
import useMedia from 'use-media';

const TimelineItem = ({ label, onClick, children }) => {
  return (
    <Timeline.Item
      className="ant-timeline-item-left"
      label={label}
      dot={<CircleStyle />}
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </Timeline.Item>
  );
};

const CostReport = ({
  campaignName,
  campaignId,
  campaignBudget,
  createdAt,
  state: {
    loading,
    dailyCost,
    campaign,
    totalCost,
    paymanCost,
    totalPaymanCost,
  },
  actions,
}) => {
  const [selectedDay, setSelectedDay] = useState('');
  const [costTimelineDisplayed, showCostTimeline] = useState('Consumed Cost');

  useEffect(() => {
    actions.getCostReport(campaignId);

    return () => {
      actions.resetState();
    };
  }, [campaignId]); // eslint-disable-line

  const renderDailyCosts = () => {
    const days = dailyCost && Object.keys(dailyCost);

    return days?.reverse()?.map(day => (
      <TimelineItem
        key={day}
        label={day}
        onClick={() => {
          setSelectedDay(day);
        }}
      >
        Consumption {dailyCost[day]?.total_cost?.toFixed(2)}$
      </TimelineItem>
    ));
  };

  const renderPaymanCosts = () => {
    const days = Object.keys(paymanCost);

    return days?.reverse()?.map(day => (
      <TimelineItem
        key={day}
        label={day}
        onClick={() => {
          setSelectedDay(day);
        }}
      >
        Consumption {paymanCost[day].toFixed(2)}$
      </TimelineItem>
    ));
  };

  const dailyCosts = selectedDay && dailyCost[selectedDay];

  const isLaptop = useMedia({ minHeight: LAPTOP_SCREEN_HEIGHT });

  const balance = campaign?.package?.balance;
  const bonus = campaign?.package?.bonus;
  const abm = campaign?.package?.abm;

  const maxAllowedBudget = ((balance + bonus) * abm)?.toFixed(2);

  const renderCostBoxes = () => {
    const costBoxes = [
      {
        title: 'Assigned Budget',
        value: campaignBudget.toFixed(2),
      },
      {
        title: 'Allowed Budget',
        value: maxAllowedBudget,
      },
      {
        title: 'Consumed Cost',
        value: Number(totalCost).toFixed(2) || 0,
      },
      {
        title: 'Charged Cost',
        value: Number(totalPaymanCost)?.toFixed(2) || 0,
      },
    ];

    return costBoxes.map(({ title, value }) => {
      const isCustom = title === 'Consumed Cost' || title === 'Charged Cost';
      return (
        <BoxStyle
          clickable={isCustom}
          isActive={isCustom && costTimelineDisplayed !== title ? false : true}
          {...(isCustom
            ? {
                onClick: () => {
                  showCostTimeline(title);
                },
              }
            : {})}
        >
          <span>{title}</span>
          <span>{value}</span>
        </BoxStyle>
      );
    });
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <div style={{ ...flexBetweenCenter, padding: '20px 20px 0' }}>
        <h3>{campaignName}'s Cost Report</h3>
      </div>
      <Spin spinning={loading} size="large">
        <CostBoxesStyle>{renderCostBoxes()}</CostBoxesStyle>
        <CostReportCardsContainerStyle>
          {costTimelineDisplayed === 'Consumed Cost' ? (
            <Card
              size="small"
              title="Cost Timeline"
              style={{ minWidth: '320px' }}
              bodyStyle={{
                maxHeight: isLaptop ? '52vh' : 380,
                overflowY: 'scroll',
              }}
            >
              <Timeline mode="left">
                {renderDailyCosts()}
                <TimelineItem
                  key={createdAt}
                  label={moment.utc(createdAt).format('YYYY-MM-DD')}
                >
                  Assigned Budget {campaignBudget?.toFixed(2)}$
                </TimelineItem>
              </Timeline>
            </Card>
          ) : null}
          {costTimelineDisplayed === 'Charged Cost' ? (
            <Card
              size="small"
              title="Charged Cost Timeline"
              style={{ minWidth: '320px' }}
              bodyStyle={{
                maxHeight: isLaptop ? '52vh' : 380,
                overflowY: 'scroll',
              }}
            >
              <Timeline mode="left">{renderPaymanCosts()}</Timeline>
            </Card>
          ) : null}
          {costTimelineDisplayed === 'Consumed Cost' ? (
            <Card
              size="small"
              title={selectedDay ? `${selectedDay} details` : 'Day details'}
            >
              {selectedDay ? (
                <dl>
                  <dt>Google cost</dt>
                  <dd>{(dailyCosts?.google_cost || 0).toFixed(2)}$</dd>

                  <dt>Facebook cost</dt>
                  <dd>{(dailyCosts?.fb_cost || 0).toFixed(2)}$</dd>

                  <dt>Bing cost</dt>
                  <dd>{(dailyCosts?.bing_cost || 0).toFixed(2)}$</dd>

                  <dt>Propeller cost</dt>
                  <dd>{(dailyCosts?.propeller_cost || 0).toFixed(2)}$</dd>

                  <dt>Zeropark cost</dt>
                  <dd>{(dailyCosts?.zeropark_cost || 0).toFixed(2)}$</dd>
                </dl>
              ) : (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                  }}
                  description="No date is selected!"
                />
              )}
            </Card>
          ) : null}
        </CostReportCardsContainerStyle>
      </Spin>
    </Card>
  );
};

export default connect(CostReport, Module);
