import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, InputNumber, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import {
  RowContainerStyle,
  KeyContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';
import EditableField from 'components/EditableField';
import Validation from 'utils/Validation';

const OptimizationData = ({
  campaign,
  onEditSave,
  fieldsToEdit,
  editCampaignLoading,
  form,
  getKampaniaData,
  fetchKampaniaDataLoading,
  editMode,
}) => {
  const [editedValues, setEditedValues] = useState({
    voluum_id: '',
    daily_budget_limit: '',
    semaphore_final_url: '',
  });

  useEffect(() => {
    getKampaniaData();
  }, [campaign._id]); //eslint-disable-line

  const onEditSemaphoreFinalUrl = newUrl => {
    const {
      kampania: { semaphore_id: semaphoreId },
    } = campaign;

    onEditSave({ semaphoreId, ...newUrl });
  };

  const {
    daily_budget_limit,
    allow_optimization: allowOptimization,
  } = campaign;

  const voluum_id = campaign?.kampania?.voluum_id;
  const semaphoreUrl = campaign?.kampania?.semaphore_url;
  const semaphore_final_url = campaign?.kampania?.semaphore_final_url;

  const onToggleOptimization = newValue => {
    onEditSave({
      allow_optimization: newValue,
      allow_batch_optimization: newValue,
    });
  };

  const loadingIcon = <LoadingOutlined style={{ marginLeft: 10 }} spin />;

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  const isSaveButtonDisabled = (name, value) =>
    !editedValues[name] || editedValues[name]?.toString() === value?.toString();

  const { getFieldDecorator, setFieldsValue } = form;

  if (fetchKampaniaDataLoading) {
    const loadingIcon = <LoadingOutlined spin />;
    return (
      <Spin indicator={loadingIcon} style={{ width: '100%', margin: 10 }} />
    );
  }

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Voluum Id</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={voluum_id}
            disableSaveButton={isSaveButtonDisabled('voluum_id', voluum_id)}
            onSave={() => onEditSave({ voluum_id: editedValues['voluum_id'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, voluum_id });
              setFieldsValue({ voluum_id });
            }}
            loadingEdit={isEditLoading('voluum_id')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('voluum_id', {
                initialValue: voluum_id,
                rules: [Validation.required, Validation.volummId],
              })(
                <Input
                  placeholder="Voluum Id"
                  onChange={e =>
                    setEditedValues({
                      ...editedValues,
                      voluum_id: e.target.value,
                    })
                  }
                  data-test={`voluum-id-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Daily Budget Limit</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={daily_budget_limit}
            disableSaveButton={isSaveButtonDisabled(
              'daily_budget_limit',
              daily_budget_limit,
            )}
            onSave={() =>
              onEditSave({
                daily_budget_limit: editedValues['daily_budget_limit'],
              })
            }
            renderShownValue={value => `$${value}`}
            onCancel={() => {
              setEditedValues({ ...editedValues, daily_budget_limit });
              setFieldsValue({ daily_budget_limit });
            }}
            loadingEdit={isEditLoading('daily_budget_limit')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('daily_budget_limit', {
                initialValue: daily_budget_limit,
                rules: [Validation.required],
              })(
                <InputNumber
                  placeholder="Daily Budget Limit"
                  type="number"
                  onChange={number =>
                    setEditedValues({
                      ...editedValues,
                      daily_budget_limit: number,
                    })
                  }
                  data-test={`daily_budget_limit-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow={!semaphoreUrl}>
        <KeyContainerStyle>Optimization</KeyContainerStyle>
        <ValueContainerStyle>
          {allowOptimization ? 'Enabled' : 'Disabled'}
          <Switch
            checked={allowOptimization}
            onChange={onToggleOptimization}
            style={{ marginLeft: 10 }}
            data-test="optimization-switch"
          />
          {isEditLoading('allow_optimization') && (
            <Spin indicator={loadingIcon} />
          )}
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hidden={!semaphoreUrl}>
        <KeyContainerStyle>Semaphore URL</KeyContainerStyle>
        <ValueContainerStyle>{semaphoreUrl}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hidden={!semaphoreUrl} lastRow>
        <KeyContainerStyle>Semaphore final URL</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={semaphore_final_url}
            disableSaveButton={isSaveButtonDisabled(
              'semaphore_final_url',
              semaphore_final_url,
            )}
            onSave={() =>
              onEditSemaphoreFinalUrl({
                semaphoreFinalUrl: editedValues['semaphore_final_url'],
              })
            }
            onCancel={() => {
              setEditedValues({ ...editedValues, semaphore_final_url });
              setFieldsValue({ semaphore_final_url });
            }}
            loadingEdit={isEditLoading('semaphoreFinalUrl')}
            editMode={editMode}
          >
            <Form.Item>
              {getFieldDecorator('semaphore_final_url', {
                initialValue: semaphore_final_url,
                rules: [Validation.required],
              })(
                <Input
                  placeholder="Semaphore Final Url"
                  onChange={e =>
                    setEditedValues({
                      ...editedValues,
                      semaphore_final_url: e.target.value,
                    })
                  }
                  data-test={`semaphore-final-url-input`}
                />,
              )}
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default Form.create()(OptimizationData);
