import styled from 'styled-components';
import {
  MOB_SCREEN_WIDTH,
  LAPTOP_SCREEN_HEIGHT,
  TABLET_SCREEN_WIDTH,
} from 'utils/constants';

export const PageBody = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:not(.ant-back-top) {
    height: 52.5rem;

    @media (min-height: ${LAPTOP_SCREEN_HEIGHT}px) {
      height: 83vh;
    }
  }

  & > div:first-of-type {
    flex: 2 0 0;
    margin: 2rem 1.5rem 1rem 0;
    min-width: 43rem;

    @media (max-width: ${MOB_SCREEN_WIDTH}px) {
      margin: 0;
      width: 33.6rem;
      min-width: 33.6rem;
      height: auto;
    }

    & .ant-select.ant-select-enabled.ant-select-allow-clear {
      @media (min-width: 730px) {
        margin: 0;
        max-width: 20rem;
      }
    }
  }

  & > div:nth-of-type(2) {
    flex: 10 0 10em;
    margin: 2rem 0 1rem 1.5rem;
    min-width: 43rem;
    overflow-y: auto;

    @media (max-width: ${MOB_SCREEN_WIDTH}px) {
      margin: 2rem 0;
      width: 33.6rem;
      min-width: 33.6rem;
    }

    @media (max-width: ${TABLET_SCREEN_WIDTH}px) {
      min-height: 50rem;
      height: fit-content;
    }

    @media (min-height: ${LAPTOP_SCREEN_HEIGHT}px) {
      overflow-y: none;
    }
  }

  & > div:nth-of-type(3) {
    min-height: initial;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-page-header {
    padding: 0;
  }
`;

export const PageContainer = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
`;
