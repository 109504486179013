import { Collapse, Button } from 'antd';
import React, { useReducer } from 'react';
import { saveAs } from 'file-saver';

import {
  BannersContainerStyle,
  ImageContainerStyle,
  MaterialNameStyle,
  NoValueTextStyle,
} from './styles';

import {
  CollapseContainerStyle,
  CollapsePanelHeaderStyle,
} from 'pages/CampaignDetails/styles';

import ImageZoom from 'react-medium-image-zoom';
import {
  RowContainerStyle,
  KeyContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';

import JSZip from 'jszip';
import { DownloadOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const CampaignMaterial = ({ campaignCreatives, campaignName }) => {
  const [bannersState, dispatchBannersState] = useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case 'isReadyToDownload':
          return { ...state, isReadyToDownload: payload };
        case 'content':
          return { ...state, content: payload };
        default:
          return state;
      }
    },
    { isReadyToDownload: false, content: null },
  );

  const materialTypes = [
    {
      title: 'Headlines',
      key: 'headlines',
    },
    {
      title: 'Descriptions',
      key: 'descriptions',
    },
    {
      title: 'Banners',
      key: 'banners',
    },
  ];

  const downloadAllBanners = () => {
    if (bannersState.isReadyToDownload) {
      saveAs(bannersState.content, `banners_${campaignName}.zip`);
    } else {
      const filesToDownload = campaignCreatives['banners'];

      const zip = new JSZip();

      if (filesToDownload)
        filesToDownload.forEach(({ base64, name }) =>
          zip.file(name, base64, { base64: true }),
        );

      zip.generateAsync({ type: 'blob' }).then(content => {
        content.lastModifiedDate = new Date(); // eslint-disable-line
        content.name = 'images.zip'; // eslint-disable-line

        dispatchBannersState({ type: 'isReadyToDownload', payload: true });
        dispatchBannersState({ type: 'content', payload: content });
        saveAs(content, `banners_${campaignName}.zip`);
      });
    }
  };

  const renderBanners = () => (
    <BannersContainerStyle>
      {campaignCreatives['banners'].map(({ base64, extension }) =>
        ['png', 'gif', 'jpeg', 'jpg'].some(itm => itm === extension) ? (
          <ImageContainerStyle key={base64}>
            <ImageZoom
              image={{
                src: `data:image/${extension};base64, ${base64}`,
                alt: 'Banner',
                style: { width: '100%', height: '100%' },
              }}
              zoomImage={{
                src: base64,
                style: { width: 'fit-content' },
                alt: 'Banner',
              }}
            />
          </ImageContainerStyle>
        ) : (
          <ImageContainerStyle key={base64} noImage>
            {extension === 'html'
              ? 'Unsupported file (HTML)'
              : 'Unsupported file'}
            <br />⛔
          </ImageContainerStyle>
        ),
      )}
    </BannersContainerStyle>
  );

  const renderHeadlinesAndDescriptions = key => {
    const itemsArr = campaignCreatives[key]
      ? Object.keys(campaignCreatives[key])
      : '';
    return itemsArr.sort().map((label, index) => {
      const [enhancedLabel, number] = label.split(/(\d+)/).filter(Boolean);

      return (
        <RowContainerStyle key={label} lastRow={index === itemsArr.length - 1}>
          <KeyContainerStyle>
            <MaterialNameStyle>{`${enhancedLabel} ${number}`}</MaterialNameStyle>
          </KeyContainerStyle>
          <ValueContainerStyle>
            {campaignCreatives[key][label]}
          </ValueContainerStyle>
        </RowContainerStyle>
      );
    });
  };

  return (
    <CollapseContainerStyle>
      <Collapse defaultActiveKey={['1']} expandIconPosition="right" accordion>
        {materialTypes.map(({ title, key }, index) => {
          const itemsArr = campaignCreatives[key]
            ? Object.keys(campaignCreatives[key])
            : '';

          return (
            <Panel
              header={
                <CollapsePanelHeaderStyle>{title}</CollapsePanelHeaderStyle>
              }
              key={index + 1}
            >
              {itemsArr.length && key === 'banners' ? (
                <div
                  style={{
                    textAlign: 'end',
                    padding: '25px 0 0',
                  }}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={downloadAllBanners}
                  >
                    Download Banners
                  </Button>
                </div>
              ) : null}
              {itemsArr.length === 0 ? (
                <NoValueTextStyle>No {title}</NoValueTextStyle>
              ) : key === 'banners' ? (
                renderBanners()
              ) : (
                renderHeadlinesAndDescriptions(key)
              )}
            </Panel>
          );
        })}
      </Collapse>
    </CollapseContainerStyle>
  );
};

export default CampaignMaterial;
