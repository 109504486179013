import { Collapse } from 'antd';
import React from 'react';

import {
  CollapseContainerStyle,
  CollapsePanelHeaderStyle,
} from 'pages/CampaignDetails/styles';
import BudgetPanel from './BudgetPanel';
import MainInformation from './MainInformationPanel';
import CycleInformation from './CycleInformationPanel';
import TargetingOptionsPanel from './TargetingOptionsPanel';

const { Panel } = Collapse;

const CampaignInformation = ({
  campaign,
  onEditSave,
  fieldsToEdit,
  editCampaignLoading,
  campaignLocations,
  accountTransactions,
  subSectionTabSelected,
  selectSubSectionFromTab,
  isCampaignUpdated,
  tsCampaignsReport,
  fetchTsCampaignsLoading,
  editMode,
}) => {
  return (
    <CollapseContainerStyle>
      <Collapse
        defaultActiveKey={[subSectionTabSelected]}
        expandIconPosition="right"
        onChange={selectSubSectionFromTab}
        accordion
      >
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Main Information
            </CollapsePanelHeaderStyle>
          }
          key="1"
        >
          <MainInformation
            campaign={campaign}
            onEditSave={onEditSave}
            fieldsToEdit={fieldsToEdit}
            editMode={editMode}
            editCampaignLoading={editCampaignLoading}
          />
        </Panel>
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Cycle Information
            </CollapsePanelHeaderStyle>
          }
          key="2"
        >
          <CycleInformation
            campaign={campaign}
            onEditSave={onEditSave}
            editMode={editMode}
            fieldsToEdit={fieldsToEdit}
            editCampaignLoading={editCampaignLoading}
          />
        </Panel>
        <Panel
          header={<CollapsePanelHeaderStyle>Budget</CollapsePanelHeaderStyle>}
          key="3"
        >
          <BudgetPanel
            campaign={campaign}
            accountTransactions={accountTransactions}
            onEditSave={onEditSave}
            fieldsToEdit={fieldsToEdit}
            editMode={editMode}
            editCampaignLoading={editCampaignLoading}
            tsCampaignsReport={tsCampaignsReport}
            fetchTsCampaignsLoading={fetchTsCampaignsLoading}
          />
        </Panel>
        <Panel
          header={
            <CollapsePanelHeaderStyle>
              Targeting Options
            </CollapsePanelHeaderStyle>
          }
          key="4"
        >
          <TargetingOptionsPanel
            campaign={campaign}
            onEditSave={onEditSave}
            isCampaignUpdated={isCampaignUpdated}
            editMode={editMode}
            fieldsToEdit={fieldsToEdit}
            editCampaignLoading={editCampaignLoading}
            campaignLocations={campaignLocations}
          />
        </Panel>
      </Collapse>
    </CollapseContainerStyle>
  );
};

export default CampaignInformation;
