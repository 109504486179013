import styled from 'styled-components';

export const EditButtonsContainerStyle = styled.div`
  margin-top: 1rem;
`;

export const LocationsContainerStyle = styled.div`
  white-space: pre-wrap;
  line-height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const SingleLocationStyle = styled.span`
  background-color: #e6e6e6;
  padding: 0.5rem;
  margin-right: 1rem;
`;

export const EditLocationsContainerStyle = styled.div`
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
