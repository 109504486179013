import styled from 'styled-components';
import { MOB_SCREEN_WIDTH } from 'utils/constants';
import { DivFlexSpaceBetween } from '../../styles/styledComponents';
import theme from 'styles/themes';

export const HeaderContainerStyle = styled(DivFlexSpaceBetween)`
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  justify-content: ${props => (props.hasState ? 'space-between' : 'flex-end')};

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    justify-content: flex-end;
  }
`;

export const StatisticsContainerStyle = styled.div`
  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    display: none;
  }
`;

export const NotificationsContainerStyle = styled.div`
  background-color: #fff;
  width: 40rem;
  box-shadow: 0 0.3rem 1.8rem rgba(0, 0, 0, 0.1);
`;

export const NotificationHeaderStyle = styled.div`
  font-weight: bold;
  font-size: 1.6rem;
  padding: 1rem 1.5rem;
  border-bottom: 2px solid ${theme.colors.grayBorder};
  text-transform: uppercase;
`;

export const NotificationItemStyle = styled.div`
  position: relative;
  padding: 1rem 1.5rem;
  background-color: ${props => (props.newMsg ? '#edf0ff' : 'none')};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.grayBorder};
  }
  &::before {
    content: '';
    display: inline-block;
    background: ${props => props.color};
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 1rem;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  div {
    margin-left: 2rem;
  }

  div:last-of-type {
    font-size: 1.2rem;
    font-style: italic;
    color: gray;
    margin-left: 2rem;
  }
`;

export const NotificationItemsContainerStyle = styled.div`
  height: 25rem;
  overflow-y: scroll;
`;

export const NotificationFooterStyle = styled.div`
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  border-top: 2px solid ${theme.colors.grayBorder};
`;
