import { MOB_SCREEN_WIDTH } from 'utils/constants';
import styled from 'styled-components';

import { DivFlexSpaceBetween } from '../../../styles/styledComponents';

export const ActionContainerStyle = styled(DivFlexSpaceBetween)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    flex-direction: column;
    min-height: 6rem;
  }
`;
