import React from 'react';
import { formatDateSemantic } from 'utils/helpers';

export const getTrafficSourceName = wfName => {
  switch (wfName) {
    case 'INSTA_ZP_DELIVERY_SEMAPHOR_WF':
      return {
        name: 'Zeropark Delivery',
        source: 'zeropark',
      };

    case 'Insta_Test_Campaign_V1':
      return {
        name: 'Zeropark Testing',
        source: 'zeropark',
      };

    case 'Insta_TEST_PP_Campaign_V1':
      return {
        name: 'Propeller Testing',
        source: 'propeller',
      };

    case 'Insta_DEL_PP_SEMAPHORE_Campaign_V1':
      return {
        name: 'Propeller Delivery',
        source: 'propeller',
      };

    case 'Insta_DEL_PP_SEMAPHORE_Campaign_V1_No_Testing':
      return {
        name: 'Propeller Delivery without Testing',
        source: 'propeller',
      };

    case 'Insta_Bing_Campaign_K_Planner_V1':
      return {
        name: 'Bing With Keyword Planner',
        source: 'bing',
      };

    case 'Insta_Google_Campaign_V1':
      return {
        name: 'Google Standard',
        source: 'google',
      };

    case 'Insta_Google_Responsive_Campaign_V1':
      return {
        name: 'Google Search',
        source: 'google',
      };

    case 'Insta_GOOGLE_Display_Campaign_V1':
      return {
        name: 'Google Display',
        source: 'google',
      };

    case 'INSTA_ZP_LEGACY_SEMAPHOR_WF':
      return {
        name: 'Zeropark Legacy',
        source: 'zeropark',
      };

    default:
      return wfName;
  }
};

export const getBaseTrafficSourceName = wfName => {
  switch (wfName) {
    case 'Insta_Google_Campaign_V1':
      return 'google';

    case 'Insta_Google_Responsive_Campaign_V1':
      return 'google';

    case 'Insta_GOOGLE_Display_Campaign_V1':
      return 'google';

    default:
      return wfName;
  }
};

export const initialColumns = [
  {
    title: 'CAMPAIGN ID',
    dataIndex: 'campaign_id',
    key: 'campaign_id',
    width: 130,
  },
  {
    title: 'TRAFFIC SOURCE',
    dataIndex: 'workflow_name',
    key: 'workflow_name',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'STATUS',
    dataIndex: 'traffic_source_status',
    key: 'traffic_source_status',
    width: 100,
  },
  {
    title: 'STARTED AT',
    dataIndex: 'started_at',
    key: 'started_at',
    render: date => formatDateSemantic(date),
    width: 150,
  },
  {
    title: 'ASSIGNED BUDGET ($)',
    dataIndex: 'assigned_budget',
    key: 'assigned_budget',
    width: 150,
  },
  {
    title: 'ASSIGNED BUDGET PERCENTAGE',
    dataIndex: 'weight',
    key: 'assigned_budget_percent',
  },
  {
    title: 'CONSUMED BUDGET ($)',
    dataIndex: 'consumed_budget',
    key: 'consumed_budget',
    render: consumedBudget => <div>{(consumedBudget / 100).toFixed(2)}</div>,
    width: 100,
  },
  {
    title: '',
    dataIndex: 'is_active',
    key: 'is_active',
    width: 30,
  },
];
