import { ElasticSearch, commentsApi, BayonetApi } from 'utils/apiCalls';
import {
  handleApiError,
  showMessage,
  capitalizeFirstLetter,
} from 'utils/helpers';
import Cookies from 'universal-cookie';

import { getQueryForMonitoringDataWithFilters } from './queryBuilder';

const cookies = new Cookies();

const getCampaignAlerts = alerts => {
  const criticalAlerts = [];
  const mediumAlerts = [];
  const naAlerts = [];

  // eslint-disable-next-line no-unused-expressions
  alerts?.forEach(alert => {
    if (alert?.value !== 'N/A') {
      alert?.type === 'critical'
        ? criticalAlerts.push(alert?.name)
        : mediumAlerts.push(alert?.name);
    } else {
      naAlerts.push(alert?.name);
    }
  });

  return [criticalAlerts, mediumAlerts, naAlerts];
};

const getPublishedOn = traffic_sources_info => {
  const published_on = [];

  // eslint-disable-next-line no-unused-expressions
  traffic_sources_info?.forEach(ts => {
    if (ts?.account_name) {
      published_on.push(
        `${capitalizeFirstLetter(ts?.traffic_source)} (${ts?.account_name})`,
      );
    } else {
      published_on.push(capitalizeFirstLetter(ts?.traffic_source));
    }
  });

  return published_on.join('  |  ');
};

const getMonitoringDataHelper = async monitoringRes => {
  const monitoringData = [];
  const campaignIds = [];

  for (const campaign of monitoringRes?.hits?.hits) {
    const [criticalAlerts, mediumAlerts, naAlerts] = getCampaignAlerts(
      campaign?._source?.alerts,
    );

    const published_on = getPublishedOn(
      campaign?._source?.traffic_sources_info,
    );

    campaignIds.push(campaign?._source?.campaign_id);

    monitoringData.push({
      ...campaign._source,
      criticalAlerts,
      mediumAlerts,
      naAlerts,
      published_on,
    });
  }

  return [monitoringData, campaignIds];
};

const getModifiedObj = rule => {
  switch (rule.name) {
    case 'monitoring_clicks_no_visits_critical':
      const clicks = rule.formula
        .match(/{monitoring_clicks} > (\d+)/g)[0]
        .split(' > ')[1];

      const visits = rule.formula
        .match(/{monitoring_visits} == (\d+)/g)[0]
        .split(' == ')[1];

      return {
        ...rule,
        valuesToBeModified: {
          clicks,
          visits,
        },
        getRuleAfterModify: (newClicks, newVisits) => {
          return `{monitoring_clicks} > ${newClicks} and {monitoring_visits} == ${newVisits} and monitoring_campaign_is_ga_integrated is True`;
        },
      };
    case 'monitoring_clicks_visits_discrepancy_medium':
      const discrepancyPercentage = rule.formula
        .match(/> (\d+)(\.\d+)/g)[0]
        .split(' ')[1];

      return {
        ...rule,
        valuesToBeModified: {
          discrepancyPercentage,
        },
        getRuleAfterModify: newDiscrepancyPercentage => {
          return `( ( abs({monitoring_clicks} - {monitoring_visits} ) ) / ({monitoring_clicks} + {monitoring_visits}) ) > ${newDiscrepancyPercentage /
            100}`;
        },
      };
    case 'monitoring_bad_quality_medium':
      const timeOnSite = rule.formula.match(/< (\d+)/g)[0].split(' ')[1];

      return {
        ...rule,
        valuesToBeModified: {
          timeOnSite,
        },
        getRuleAfterModify: newTimeOnSite => {
          return `(int("{monitoring_avg_session_duration}".split(":")[0])*3600 + int("{monitoring_avg_session_duration}".split(":")[1])*60 + int("{monitoring_avg_session_duration}".split(":")[2])) < ${newTimeOnSite} and {monitoring_campaign_is_ga_integrated} is True`;
        },
      };
    case 'monitoring_slow_delivery_medium':
      const slowPercentage = rule.formula
        .match(/\* (\d+)(\.\d+)/g)[0]
        .split(' ')[1];

      return {
        ...rule,
        valuesToBeModified: {
          slowPercentage,
        },
        getRuleAfterModify: newSlowPercentage => {
          return `( {monitoring_campaign_ideal_spent_cost} * ${(100 -
            newSlowPercentage) /
            100} ) > {monitoring_live_consumed_cost}`;
        },
      };
    case 'monitoring_over_delivery_medium':
      const overPercentage = rule.formula
        .match(/\* (\d+)(\.\d+)/g)[0]
        .split(' ')[1];

      return {
        ...rule,
        valuesToBeModified: {
          overPercentage,
        },
        getRuleAfterModify: newOverPercentage => {
          return `( {monitoring_live_consumed_cost} * ${(100 -
            newOverPercentage) /
            100} ) > {monitoring_campaign_ideal_spent_cost}`;
        },
      };
    default:
      return rule;
  }
};

export default {
  name: 'Monitoring',
  state: {
    alerts: [],
    campaignsCount: 0,
    monitoringData: [],
    getMonitoringDataLoading: false,
    commentAdded: {},
    commentUpdated: {},
    addCommentLoading: false,
    selectedCampaign: null,
    commentObj: {},
    editCommentLoading: false,
    campaignComments: {},
    commentUpdatedInput: '',
    commentEditMode: true,
    refreshedCampaign: {},
  },
  actions: {
    *setCommentObj(obj) {
      yield {
        commentObj: obj,
      };
    },

    *setCommentUpdateText(val) {
      yield {
        commentUpdatedInput: val,
      };
    },

    *editComment(commentId, campaignId, text) {
      const commentBody = {
        campaign_id: campaignId,
        text,
        user: cookies.get('userId'),
      };

      try {
        yield {
          selectedCampaign: campaignId,
          editCommentLoading: true,
          commentEditMode: true,
        };

        const data = yield commentsApi
          .editComment(commentId, commentBody)
          .catch(e => e);

        handleApiError(data);

        showMessage('success', `Campaign Comment Updated Successfully!`);

        yield {
          commentUpdated: data,
          editCommentLoading: false,
          commentUpdatedInput: '',
          commentEditMode: false,
          commentObj: {
            ...this.getState().commentObj,
            [campaignId]: null,
          },
          shouldGetComments: true,
        };
      } catch (err) {
        showMessage(
          'error',
          `Error while Updating Campaign Comment ${
            err?.message ? `: ${err?.message}` : ''
          }`,
        );
        yield {
          editCommentLoading: false,
          commentEditMode: true,
        };
      }
    },

    *deleteComment(commentId, campaignId) {
      try {
        yield {
          selectedCampaign: campaignId,
          selectedComment: commentId,
          deleteCommentLoading: true,
        };

        const deleteComment = yield commentsApi
          .deleteComment(commentId)
          .catch(e => e);

        handleApiError(deleteComment);

        showMessage('success', `Campaign Comment deleted Successfully!`);

        yield {
          shouldGetComments: true,
          deleteCommentLoading: false,
        };
      } catch (err) {
        showMessage(
          'error',
          `Error while deleting Campaign Comment ${
            err?.message ? `: ${err?.message}` : ''
          }`,
        );
        yield {
          deleteCommentLoading: false,
        };
      }
    },

    *addComment(campaignId, text) {
      const commentBody = {
        campaign_id: campaignId,
        text,
        user: cookies.get('userId'),
      };

      try {
        yield {
          selectedCampaign: campaignId,
          addCommentLoading: true,
        };

        const data = yield commentsApi.addComment(commentBody).catch(e => e);

        handleApiError(data);

        const comments = yield commentsApi.getCampaignComments(campaignId);

        showMessage('success', `Campaign Comment Added Successfully!`);
        yield {
          commentAdded: data.data,
          addCommentLoading: false,
          commentObj: {
            ...this.getState().commentObj,
            [campaignId]: null,
          },
          commentUpdated: comments?.objects?.reverse()[0],
          commentUpdatedInput: '',
          shouldGetComments: true,
        };
      } catch (err) {
        showMessage(
          'error',
          `Error while Add Campaign Comment ${
            err?.message ? `: ${err?.message}` : ''
          }`,
        );
        yield {
          addCommentLoading: false,
        };
      }
    },

    *getMonitoringData(
      urlParams,
      selectedCampaignState,
      selectedAlerts,
      selectedCampaignSource,
      id,
    ) {
      try {
        let monitoringRes;
        yield {
          getMonitoringDataLoading: true,
        };
        if (
          selectedAlerts.length ||
          selectedCampaignState ||
          selectedCampaignSource
        ) {
          const query = getQueryForMonitoringDataWithFilters(
            selectedAlerts,
            selectedCampaignState,
            selectedCampaignSource,
            id,
          );
          monitoringRes = yield ElasticSearch.getCampaignsMonitoringWithFilter(
            urlParams,
            query,
          ).catch(e => e);
        } else {
          monitoringRes = yield ElasticSearch.getCampaignsMonitoring(
            urlParams,
          ).catch(e => e);
        }
        handleApiError(monitoringRes);
        const [monitoringData, campaignIds] = yield getMonitoringDataHelper(
          monitoringRes,
        );
        const alerts =
          (monitoringData[0] && monitoringData[0].alerts) ||
          this.getState().alerts;

        yield {
          alerts,
          campaignIds,
          monitoringData,
          campaignsCount: monitoringRes?.hits?.total,
          getMonitoringDataLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getMonitoringDataLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Monitoring Data ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getMonitoringRules() {
      try {
        yield {
          getMonitoringRulesLoading: true,
        };

        const rulesRes = yield BayonetApi.getMonitoringRules().catch(e => e);

        handleApiError(rulesRes);

        const modifiedRules = rulesRes.objects.map(rule => {
          return getModifiedObj(rule);
        });

        yield {
          getMonitoringRulesLoading: false,
          monitoringRules: modifiedRules,
        };
      } catch (e) {
        const { message } = e;
        yield {
          getMonitoringRulesLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Monitoring rules ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *editMonitoringRule(ruleId, newRule) {
      try {
        yield {
          editMonitoringRuleLoading: true,
        };

        const editRuleRes = yield BayonetApi.EditMonitoringRule(
          ruleId,
          newRule,
        ).catch(e => e);

        handleApiError(editRuleRes);

        const rulesRes = yield BayonetApi.getMonitoringRules().catch(e => e);

        handleApiError(rulesRes);

        const modifiedRules = rulesRes.objects.map(rule => {
          return getModifiedObj(rule);
        });

        yield {
          monitoringRules: modifiedRules,
          editRuleDone: true,
          editMonitoringRuleLoading: false,
        };
      } catch (e) {
        const { message } = e;
        yield {
          editMonitoringRuleLoading: false,
        };
        showMessage(
          'error',
          `Error while editing Monitoring rule ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    resetEditRule() {
      return { editRuleDone: false };
    },

    *getCampaignsComments() {
      try {
        const ids = this.getState('campaignIds');
        if (ids?.length) {
          yield {
            getCommentsLoading: true,
          };

          const comments = yield commentsApi.getCampaignComments(ids);

          handleApiError(comments);

          yield {
            getCommentsLoading: false,
            campaignsComments: comments.objects,
          };
        }
      } catch (e) {
        showMessage(
          'error',
          `Error while getting Campaign Comments ${
            e?.message ? `: ${e?.message}` : ''
          }`,
        );
        yield {
          getCommentsLoading: false,
        };
      }
    },

    *refreshCampaign(campaignId) {
      try {
        yield {
          getMonitoringDataLoading: true,
        };

        const { data } = yield ElasticSearch.refreshBayonet(campaignId).catch(
          e => e,
        );

        handleApiError(data);
        const campaign = data[0];

        const campaignToReplaceIndex = this.getState().monitoringData?.findIndex(
          camp => camp.campaign_id === campaign.campaign_id,
        );

        const [criticalAlerts, mediumAlerts, naAlerts] = getCampaignAlerts(
          campaign?.alerts,
        );

        const published_on = getPublishedOn(
          campaign?._source?.traffic_sources_info,
        );

        Object.assign(campaign, {
          ...campaign,
          criticalAlerts,
          mediumAlerts,
          naAlerts,
          published_on,
        });

        const updatedMonitoringData = [...this.getState().monitoringData];
        if (campaignToReplaceIndex > -1)
          updatedMonitoringData[campaignToReplaceIndex] = campaign;

        showMessage('success', 'Campaign Refresh Successfully!');
        yield {
          refreshedCampaign: campaign,
          monitoringData: updatedMonitoringData,
          getMonitoringDataLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          refreshedCampaign: null,
          getMonitoringDataLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Campaign Data ${message ? `: ${message}` : ''}`,
        );
      }
    },

    resetGetComments() {
      return {
        shouldGetComments: false,
      };
    },
  },
};
