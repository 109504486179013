import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Upload } from 'antd';
import styled from 'styled-components';

const { Dragger } = Upload;
const UploadFromComputerStyle = styled.div`
  & .ant-legacy-form-item-children {
    & > span {
      width: 100%;
    }
  }
`;
function UploadFromComputer({ uploadImagesProps, getFieldDecorator }) {
  return (
    <UploadFromComputerStyle>
      <Form.Item
        label="Upload from my computer"
        style={{ flexDirection: 'column' }}
      >
        {getFieldDecorator('fileFromComputer', {
          rules: [
            {
              required: true,
              message: 'Please Upload File',
            },
          ],
          initialValue: uploadImagesProps?.defaultFileList,
        })(
          <Dragger {...uploadImagesProps} data-test="uploadFile">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>,
        )}
      </Form.Item>
    </UploadFromComputerStyle>
  );
}

export default UploadFromComputer;
