import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import KeywordTab from './KeywordTab';
import ManualKeywordTab from './ManualKeywordTab';
import SelectedKeywords from './SelectedKeywords';
import theme from 'styles/themes';

const { TabPane } = Tabs;

const Keywords = ({
  campaign,
  getKeywords,
  resetKeywords,
  bingKeywords,
  googleKeywords,
  semrushKeywords,
  bingSelectedKeywords,
  manualSelectedKeywords,
  googleSelectedKeywords,
  semrushSelectedKeywords,
  googleGetSelectedKeywordsLoading,
  semrushGetSelectedKeywordsLoading,
  bingGetSelectedKeywordsLoading,
  manualGetSelectedKeywordsLoading,
  bingGetKeywordsLoading,
  googleGetKeywordsLoading,
  semrushGetKeywordsLoading,
  campaign_countries,
  selectKeywords,
  bingSaveKeywordsLoading,
  manualSaveKeywordsLoading,
  googleSaveKeywordsLoading,
  semrushSaveKeywordsLoading,
  getSelectedKeywords,
  resetSelectedKeywords,
  sourceTobeRefreshed,
  resetSourceTobeRefreshed,
}) => {
  const campaign_id = campaign?._id;
  const url = campaign?.url;

  useEffect(() => {
    if (campaign_id) {
      getKeywordsFromAllSources();

      return () => resetKeywords();
    }
  }, [campaign_id]); // eslint-disable-line

  useEffect(() => {
    if (campaign_id) {
      getSelectedKeywordsFromAllSources();

      return () => resetSelectedKeywords();
    }
  }, [campaign_id]); // eslint-disable-line

  useEffect(() => {
    if (sourceTobeRefreshed) {
      getSelectedKeywords({
        campaign_id,
        source: sourceTobeRefreshed,
      });

      if (sourceTobeRefreshed !== 'manual') {
        getKeywords({
          campaign_id,
          domain: url,
          source: sourceTobeRefreshed,
          countries: campaign_countries,
        });
      }
    }
    return () => resetSourceTobeRefreshed();
  }, [sourceTobeRefreshed]); //eslint-disable-line

  const getKeywordsFromAllSources = () => {
    for (const source of ['google', 'semrush', 'bing']) {
      getKeywords({
        campaign_id,
        domain: url,
        source,
        countries: campaign_countries,
      });
    }
  };

  const getSelectedKeywordsFromAllSources = () => {
    for (const source of ['google', 'semrush', 'bing', 'manual']) {
      getSelectedKeywords({
        campaign_id,
        source,
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Tabs defaultActiveKey="1" style={{ width: 800, flexGrow: 1 }}>
        <TabPane tab="Google" key="1">
          <KeywordTab
            source={'google'}
            data={googleKeywords}
            url={url}
            campaign_id={campaign_id}
            getKeywords={getKeywords}
            selectKeywords={selectKeywords}
            loading={googleGetKeywordsLoading}
            campaign_countries={campaign_countries}
            saveLoading={googleSaveKeywordsLoading}
          />
        </TabPane>
        <TabPane tab="SEMrush" key="2">
          <KeywordTab
            source={'semrush'}
            data={semrushKeywords}
            url={url}
            campaign_id={campaign_id}
            getKeywords={getKeywords}
            selectKeywords={selectKeywords}
            loading={semrushGetKeywordsLoading}
            campaign_countries={campaign_countries}
            saveLoading={semrushSaveKeywordsLoading}
          />
        </TabPane>
        <TabPane tab="Bing" key="3">
          <KeywordTab
            source={'bing'}
            data={bingKeywords}
            url={url}
            campaign_id={campaign_id}
            getKeywords={getKeywords}
            selectKeywords={selectKeywords}
            loading={bingGetKeywordsLoading}
            campaign_countries={campaign_countries}
            saveLoading={bingSaveKeywordsLoading}
          />
        </TabPane>
        <TabPane tab="Manual" key="4">
          <ManualKeywordTab
            source={'manual'}
            campaign_id={campaign_id}
            selectKeywords={selectKeywords}
            saveLoading={manualSaveKeywordsLoading}
            selectedKeywords={manualSelectedKeywords}
            loading={
              manualGetSelectedKeywordsLoading || manualSaveKeywordsLoading
            }
          />
        </TabPane>
      </Tabs>
      <div style={{ width: 200, flexGrow: 1, margin: '20px 0 0 20px' }}>
        <h3>Selected Keywords</h3>
        <SelectedKeywords
          source={'Google'}
          campaign_id={campaign_id}
          selectedKeywords={googleSelectedKeywords}
          color={theme?.colors?.green}
          loading={
            googleGetSelectedKeywordsLoading || googleSaveKeywordsLoading
          }
          selectKeywords={selectKeywords}
        />
        <SelectedKeywords
          source={'SEMrush'}
          campaign_id={campaign_id}
          selectedKeywords={semrushSelectedKeywords}
          color={theme?.colors?.orange}
          loading={
            semrushGetSelectedKeywordsLoading || semrushSaveKeywordsLoading
          }
          selectKeywords={selectKeywords}
        />
        <SelectedKeywords
          source={'Bing'}
          campaign_id={campaign_id}
          selectedKeywords={bingSelectedKeywords}
          color={theme?.colors?.blue}
          loading={bingGetSelectedKeywordsLoading || bingSaveKeywordsLoading}
          selectKeywords={selectKeywords}
        />
        <SelectedKeywords
          source={'Manual'}
          campaign_id={campaign_id}
          selectedKeywords={manualSelectedKeywords}
          color={theme?.colors?.blue}
          loading={
            manualGetSelectedKeywordsLoading || manualSaveKeywordsLoading
          }
          selectKeywords={selectKeywords}
        />
      </div>
    </div>
  );
};

export default Keywords;
