import styled from 'styled-components';

export const ContainerStyle = styled.div`
  & .ant-card {
    margin-bottom: 2rem;
  }
  & .ant-card-body {
    padding: 0 1.6rem;
  }
  & .ant-card-head-wrapper {
    height: 4.5rem;
  }
  & .ant-card-head-title {
    font-size: 1.4rem;
  }
`;
