import {
  UsersApi,
  UploadApi,
  ElasticSearch,
  CampaignsApi,
} from 'utils/apiCalls';
import { handleApiError, showMessage } from 'utils/helpers';
import { message } from 'antd';

export default {
  name: 'Customers',
  state: {
    getAllCustomersLoading: false,
    customersList: [],
    customersCount: 0,
    createPartnerLoading: false,
    partnerCreated: false,
    error: '',
  },
  actions: {
    *getCustomers(urlParams) {
      try {
        yield { getAllCustomersLoading: true };

        const getAllCustomers = yield UsersApi.getUsers(urlParams).catch(
          e => e,
        );

        handleApiError(getAllCustomers);

        const emails = [];

        let customerList = getAllCustomers.data.map(customer => {
          emails.push(customer.email);

          return {
            email: customer.email,
            balance: 0,
            campaigns_count: customer.num0fCampaigns,
          };
        });

        const customerBalanceRes = yield ElasticSearch.getCustomerBalance(
          emails,
        ).catch(e => e);

        handleApiError(customerBalanceRes);

        const customerBalance = [
          ...customerBalanceRes?.aggregations?.group_by_customer?.buckets,
          ...customerBalanceRes?.aggregations?.group_by_partner?.buckets,
        ];

        customerList = customerList.map(customer => {
          const customerBalanceObj = customerBalance.find(
            obj => obj.key === customer.email,
          );

          return {
            ...customer,
            balance: customerBalanceObj?.total_balance?.value || 0,
          };
        });

        yield {
          customersCount: getAllCustomers.headers['x-count'],
          customersList: customerList,
          getAllCustomersLoading: false,
        };
      } catch (e) {
        const {
          errorMsg: { reason },
        } = JSON.parse(e.message);

        yield {
          getAllCustomersLoading: false,
        };
        showMessage(
          'error',
          `Error while getting customers ${reason ? `: ${reason}` : ''}`,
        );
      }
    },

    *getCustomerCampaigns(email) {
      try {
        yield {
          getCampaignLoading: true,
        };
        const campaignsResponse = yield CampaignsApi.getAllCampaigns({
          userEmail: email,
        }).catch(e => e);

        handleApiError(campaignsResponse);

        const campaignsBalanceRes = yield ElasticSearch.getCustomerCampaignsBalance(
          email,
        ).catch(e => e);

        handleApiError(campaignsBalanceRes);

        const campaigns = campaignsResponse?.data.map(campaign => {
          const balanceObj = campaignsBalanceRes.hits.hits.find(
            obj => +obj._source.campaign_id === campaign._id,
          );

          return {
            ...campaign,
            remaining_balance: balanceObj?._source?.balance || 'N/A',
          };
        });

        yield {
          getCampaignLoading: false,
          campaigns: campaigns || [],
        };
      } catch (e) {
        const {
          errorMsg: { reason },
        } = JSON.parse(e.message);

        yield {
          getCampaignLoading: false,
        };
        showMessage(
          'error',
          `Error while getting customer campaigns${
            reason ? `: ${reason}` : ''
          }`,
        );
      }
    },

    *createPartner(partnerData) {
      try {
        yield { createPartnerLoading: true };
        const partnerResponse = yield UsersApi.addPartner(partnerData).catch(
          e => e,
        );
        handleApiError(partnerResponse);

        yield {
          createPartnerLoading: false,
          partnerCreated: true,
        };
      } catch (err) {
        const { errorMsg } = JSON.parse(err.message);
        message.error(errorMsg);
        yield {
          createPartnerLoading: false,
          error: errorMsg,
        };
      }
    },

    resetPartnerCreated() {
      return {
        partnerCreated: false,
      };
    },

    *generatePresignedUrl(campaignId) {
      const result = yield UploadApi.generatePresignedUrl(campaignId, {
        type: 'logoUploads',
      }).catch(e => e);
      handleApiError(result);

      const generatedURL = result.url;
      yield {
        generatedURL,
      };
    },

    uploadFile(action, file, headers, onSuccess, onError) {
      fetch(action, {
        method: 'PUT',
        body: file,
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            onSuccess(res, file);
          }
        })
        .catch(error => {
          onError(error);
        });
    },
  },
};
