const partnerTheme = require('./partner');
const adminTheme = require('./admin');

const currentTheme =
  process.env.REACT_APP_THEME &&
  process.env.REACT_APP_THEME.toLocaleLowerCase() === 'admin'
    ? adminTheme
    : partnerTheme;

module.exports = currentTheme;
