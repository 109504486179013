import React, { useEffect, useState } from 'react';
import { Card, DatePicker, Spin, Statistic, Descriptions } from 'antd';
import { connect } from 'speedux';
import { LineChart, Map, PieChart } from 'cogni-reports';

import Module, { cancelPendingRequests } from './module';
import {
  DATE_FORMAT,
  flexBetweenCenter,
  MOB_SCREEN_WIDTH,
} from '../../utils/constants';
import LocationsTable from './LocationsTable';
import ConversionTable from './ConversionsTable';
import {
  predefinedDateRanges,
  getPackageStartDate,
  getPackageEndDate,
} from '../../utils/helpers';
import styled from 'styled-components';
import useMedia from 'use-media';

const { RangePicker } = DatePicker;
const DescriptionsCustomContainerStyle = styled.div`
  .ant-descriptions-view table {
    width: initial;
  }
`;

const SubHeaderReportPageStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ isMob }) => (isMob ? 'column' : 'row')};

  & > *,
  & > .ant-statistic {
    margin-bottom: 20px;
  }
`;

const Report = ({
  campaignId,
  campaignName,
  state: {
    campaign,
    packageObj,
    lineChartData,
    mapData,
    pieData,
    goalsConversionsTotal,
    conversionsForAllGoals,
    goalNumbersData,
    countries,
    cities,
    loading,
    startDate,
    endDate,
  },
  actions,
}) => {
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });
  const [selectedDateRange, setDateRange] = useState([]);

  useEffect(() => {
    const [startDate, endDate] = selectedDateRange;

    actions.getReport(campaignId, startDate, endDate);

    return () => {
      cancelPendingRequests();
      actions.resetState();
    };
  }, [campaignId, selectedDateRange]); //eslint-disable-line

  const mapLevelsColors = ['#656da3', '#f6982e', '#4d9c5d', '#288cc2'];

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <div style={{ ...flexBetweenCenter, padding: 20 }}>
        <h3>{campaignName}'s Report</h3>
      </div>
      <Spin spinning={loading} size="large">
        <div style={{ padding: '0 20px' }}>
          <SubHeaderReportPageStyle isMob={isMob}>
            <div>
              <span style={{ marginRight: 10 }}>Date:</span>
              <RangePicker
                ranges={predefinedDateRanges}
                format={DATE_FORMAT}
                placeholder={!selectedDateRange.length && [startDate, endDate]}
                onChange={(_, val) => setDateRange(val)}
              />
            </div>
            <DescriptionsCustomContainerStyle>
              <Descriptions size="small" column={1}>
                <Descriptions.Item label="Start Date">
                  {getPackageStartDate(packageObj, 'DD-MM-YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label="End Date">
                  {getPackageEndDate(packageObj, 'DD-MM-YYYY')}
                </Descriptions.Item>
              </Descriptions>
            </DescriptionsCustomContainerStyle>
            <Statistic title="Budget" prefix="$" value={packageObj?.balance} />
          </SubHeaderReportPageStyle>
          <LineChart title="statistics" size="large" data={lineChartData} />
          {goalNumbersData && Object.keys(goalNumbersData).length ? (
            <ConversionTable
              goalsConversionsTotal={goalsConversionsTotal}
              conversionsForAllGoals={conversionsForAllGoals}
              goalNumbersData={goalNumbersData}
            />
          ) : null}
          {mapData && mapData.length ? (
            <Map
              title="Locations"
              size="large"
              maxNumber={5000}
              colors={mapLevelsColors}
              data={mapData}
            />
          ) : null}
          {countries && countries.length ? (
            <LocationsTable countries={countries} cities={cities} />
          ) : null}
          {pieData && pieData.length ? (
            <PieChart title="Devices" size="large" data={pieData} />
          ) : null}
        </div>
      </Spin>
    </Card>
  );
};

export default connect(Report, Module);
