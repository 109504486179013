import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';

export const campaignsColumns = [
  {
    title: 'id',
    dataIndex: '_id',
    key: '_id',
    width: 80,
  },
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'budget',
    dataIndex: ['package', 'balance'],
    key: 'Budget',
    width: 100,
  },
  {
    title: 'remaining balance',
    dataIndex: 'remaining_balance',
    key: 'remaining_balance',
    width: 175,
  },
  {
    title: 'state',
    dataIndex: 'state',
    key: 'state',
    width: 150,
  },
  {
    title: 'creation date',
    dataIndex: 'created_at',
    key: 'created_at',
    render: createdDate => moment(createdDate).format(DATE_FORMAT),
    width: 150,
  },
  {
    title: 'start date',
    dataIndex: ['package', 'custom_start_date'],
    key: 'start_date',
    render: startDate => moment(startDate).format(DATE_FORMAT),
    width: 150,
  },
  {
    title: 'end date',
    dataIndex: ['package', 'custom_end_date'],
    key: 'end_date',
    render: endDate => moment(endDate).format(DATE_FORMAT),
    width: 150,
  },
];

export default [
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'balance',
    dataIndex: 'balance',
    key: 'balance',
    width: 100,
  },
  {
    title: 'no of Campaigns',
    dataIndex: 'campaigns_count',
    key: 'campaigns_count',
    width: 175,
  },
];
