import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Card, Radio, Input } from 'antd';
import Validation from 'utils/Validation';
import RangePicker from '../SharedComponents/rangePicker';
import BiddingStrategy from '../SharedComponents/biddingStrategy';
import AccountId from '../SharedComponents/accountId';
import Keywords from '../SharedComponents/keywords';
import GenerateKeywords from '../SharedComponents/generateKeywords';

function GoogleSearch({
  getFieldDecorator,
  campaign,
  getTrafficSourceAccounts,
  trafficSourceAccountList,
  getTrafficSourceAccountsLoading,
  getFieldValue,
  campaignKeywords,
  keywordsLoading,
  headlines = {},
  descriptions = {},
  resetKeywords,
  setFieldsValue,
  generateKeywords,
  addNewPublishAccount,
  addingAccountLoading,
  accountAdded,
  defaultCPC,
}) {
  const [publishStrategy, setPublishStrategy] = useState('responsive');

  return (
    <>
      <Card title="Google Search Details" size="small">
        <Form.Item label="Publish As">
          {getFieldDecorator('trafficSource', {
            initialValue: 'responsive',
          })(
            <Radio.Group
              value={publishStrategy}
              onChange={e => {
                setPublishStrategy(e.target.value);
              }}
              data-test="trafficSourceGroup"
            >
              <Radio value="responsive">Responsive</Radio>
              <Radio value="standard">Standard</Radio>
            </Radio.Group>,
          )}
        </Form.Item>

        <BiddingStrategy
          getFieldDecorator={getFieldDecorator}
          defaultCPC={
            campaign?.auto_bidding
              ? 1.0
              : (campaign?.cpc || defaultCPC) * campaign?.package?.abm
          }
        />
        <AccountId
          trafficSource="google"
          getFieldDecorator={getFieldDecorator}
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          addNewPublishAccount={addNewPublishAccount}
          addingAccountLoading={addingAccountLoading}
          accountAdded={accountAdded}
        />

        <RangePicker
          getFieldDecorator={getFieldDecorator}
          campaign={campaign}
        />

        {Array.from(
          { length: publishStrategy === 'standard' ? 2 : 5 },
          (_, indx) => {
            return (
              <Form.Item
                key={`description${indx !== 0 ? indx + 1 : ''}`}
                label={`Description ${indx + 1}`}
              >
                {getFieldDecorator(`description${indx !== 0 ? indx + 1 : ''}`, {
                  rules: [Validation.required, Validation.descriptionLimit],
                  initialValue: descriptions[`description${indx + 1}`],
                })(
                  <Input
                    data-test={`description${indx !== 0 ? indx + 1 : ''}Input`}
                    maxLength={Validation.descriptionLimit.max}
                  />,
                )}
              </Form.Item>
            );
          },
        )}

        {Array.from(
          { length: publishStrategy === 'standard' ? 3 : 5 },
          (_, index) => {
            return (
              <Form.Item
                key={`headlinePart${index + 1}`}
                label={`Headline Part ${index + 1}`}
              >
                {getFieldDecorator(`headline_part${index + 1}`, {
                  rules: [Validation.required, Validation.headlineLimit],
                  initialValue: headlines[`headline${index + 1}`],
                })(
                  <Input
                    data-test={`headlinePart${index + 1}`}
                    maxLength={Validation.headlineLimit.max}
                  />,
                )}
              </Form.Item>
            );
          },
        )}
      </Card>

      <Card title="Keywords" size="small">
        <Keywords
          getFieldDecorator={getFieldDecorator}
          campaignKeywords={campaignKeywords}
          keywordsLoading={keywordsLoading}
          resetKeywords={resetKeywords}
          setFieldsValue={setFieldsValue}
        />
        <GenerateKeywords
          getFieldDecorator={getFieldDecorator}
          generateKeywords={generateKeywords}
          campaign={campaign}
          keywordsLoading={keywordsLoading}
        />
      </Card>
    </>
  );
}

export default GoogleSearch;
