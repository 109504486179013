import styled from 'styled-components';

export const SearchContainerStyle = styled.div`
  min-width: 15rem;
  margin-left: 2rem;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const FiltersContainerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;

  .ant-btn-group {
    & button:first-of-type {
      min-width: 25rem;
    }

    @media (max-width: 800px) {
      & button:first-of-type {
        min-width: calc(100% - 32px);
      }
      width: 100%;
    }
  }
`;

export const NotificationsContainerStyle = styled.ul`
  list-style: none;
  height: 100%;
  padding: 0;
  margin-top: 3rem;
`;

export const NotificationItemStyle = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;

    & a {
      align-self: flex-end;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #d2d2d2;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > div span:first-of-type {
    &::before {
      content: '';
      display: inline-block;
      background: ${props => props.color};
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }

  & > div span:last-of-type {
    font-size: 1.2rem;
    font-style: italic;
    color: gray;
    margin-left: 2rem;
  }
`;
