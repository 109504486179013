import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { Card, Select, Radio, message } from 'antd';
import RangePicker from '../SharedComponents/rangePicker';
import BiddingStrategy from '../SharedComponents/biddingStrategy';
import AccountId from '../SharedComponents/accountId';
import UploadFromUrl from '../SharedComponents/uploadFromUrl';
import UploadFromComputer from '../SharedComponents/uploadFromComputer';

const { Option } = Select;
function GoogleDisplay({
  getFieldDecorator,
  generatedURL,
  campaign,
  resetUpload,
  uploadFileAction,
  trafficSourceAccountList,
  getTrafficSourceAccountsLoading,
  verticalsList,
  getVerticalsLoading,
  getTrafficSourceAccounts,
  getVerticals,
  uploadFilesData,
  addNewPublishAccount,
  addingAccountLoading,
  accountAdded,
  defaultCPC,
}) {
  const [topicsList, setTopicsList] = useState(verticalsList || []);
  const [selectedUploadMode, selectUploadMode] = useState('upload');
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [uploadKey, setUploadKey] = useState(0);

  useEffect(() => {
    if (uploadFilesData.zip) {
      const headers = {
        'Content-Type': 'application/zip',
      };

      uploadFile({
        action: generatedURL,
        file: uploadFilesData.zip,
        headers,
        onSuccess: () => {
          message.success(`uploaded campaign banners successfully.`);
          setDefaultFileList(uploadFilesData.filesToUpload);
          setUploadKey(1);
        },
        onError: () => {
          message.error(`campaign banners upload failed.`);
        },
      });
    }
  }, [uploadFilesData.filesToUpload]); //eslint-disable-line

  const uploadFile = ({ action, file, headers, onSuccess, onError }) => {
    uploadFileAction({ action, file, headers, onSuccess, onError });
  };

  const uploadImagesProps = {
    name: 'file',
    multiple: true,
    action: generatedURL,
    defaultFileList: defaultFileList,
    customRequest({ action, file, onError, onSuccess }) {
      if ('generatedURL') {
        const headers = {
          'Content-Type': 'application/zip',
        };
        uploadFile({ action, file, headers, onSuccess, onError });
      }
    },
    onChange(info) {
      const { file } = info;
      const { status } = file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      } else if (status === 'removed') {
        if (info.fileList.length < 1) resetUpload();
      }
    },
  };

  const handleTopicsSearch = value => {
    const allTopicsFromState = verticalsList?.filter(
      topic => topic.category.toLowerCase().indexOf(value.toLowerCase()) >= 0,
    );
    setTopicsList(allTopicsFromState);
  };

  const renderTopicsOptionsWithHiddenAlreadySelected = () => {
    if (topicsList.length > 0) {
      return topicsList
        .map(({ id, category }) => (
          <Option value={String(id)} key={String(id)}>
            {category}
          </Option>
        ))
        .splice(0, 90);
    } else if (verticalsList.length) {
      return verticalsList
        .map(({ id, category }) => (
          <Option value={String(id)} key={String(id)}>
            {category}
          </Option>
        ))
        .splice(0, 90);
    }

    return null;
  };

  useEffect(() => {
    if (!verticalsList.length) getVerticals();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title="Google Display Details" size="small">
        <RangePicker
          getFieldDecorator={getFieldDecorator}
          campaign={campaign}
        />

        <AccountId
          trafficSource="google"
          getFieldDecorator={getFieldDecorator}
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          addNewPublishAccount={addNewPublishAccount}
          addingAccountLoading={addingAccountLoading}
          accountAdded={accountAdded}
        />

        <Form.Item label="Topics">
          {getFieldDecorator('topics')(
            <Select
              mode="tags"
              placeholder="Tags Mode"
              filterOption={false}
              onSearch={handleTopicsSearch}
              loading={getVerticalsLoading}
              data-test="tags"
            >
              {renderTopicsOptionsWithHiddenAlreadySelected()}
            </Select>,
          )}
        </Form.Item>

        <BiddingStrategy
          getFieldDecorator={getFieldDecorator}
          defaultCPC={
            campaign?.auto_bidding
              ? 0.1
              : (campaign?.cpc || defaultCPC) * campaign?.package?.abm
          }
        />
      </Card>

      <Card title="Upload" size="small">
        <Radio.Group
          style={{ width: '100%', marginBottom: '1rem' }}
          defaultValue="upload"
          buttonStyle="solid"
          onChange={e => {
            selectUploadMode(e.target.value);
          }}
          data-test="uploadGoogleDisplay"
        >
          <Radio.Button
            style={{ width: '50%', textAlign: 'center' }}
            value="upload"
          >
            Upload File
          </Radio.Button>
          <Radio.Button
            style={{ width: '50%', textAlign: 'center' }}
            value="url"
          >
            URL
          </Radio.Button>
        </Radio.Group>

        {selectedUploadMode === 'upload' ? (
          <UploadFromComputer
            uploadImagesProps={uploadImagesProps}
            getFieldDecorator={getFieldDecorator}
            key={uploadKey}
          />
        ) : (
          <UploadFromUrl getFieldDecorator={getFieldDecorator} />
        )}
      </Card>
    </>
  );
}

export default GoogleDisplay;
