import { MOB_SCREEN_WIDTH } from 'utils/constants';
import styled from 'styled-components';
import theme from 'styles/themes';

export const LinkContainerStyle = styled.div`
  display: inline-block;
  width: 100%;
  height: 6rem;
  overflow-x: auto;
  background-color: ${theme.colors.silver};
`;

export const CopyButtonContainerStyle = styled.div`
  display: inline-block;
  width: 15%;
  text-align: center;
  position: absolute;
  top: 75%;
  right: 10%;

  & .anticon-copy {
    font-size: 2.4rem;
  }

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    right: 20%;
  }
`;

export const OpentagInfoTextStyle = styled.p`
  margin: 2rem 1rem;
`;

export const HTMLTagStyle = styled.span`
  color: ${theme.colors.white};
  background-color: ${theme.colors.gray};
  border-radius: 0.5rem;
  padding: 0 0.4rem 0 0.4rem;
`;

export const CodeStyle = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.gray};
  padding: 1rem;
`;

export const HintContainerStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HintStyle = styled.span`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.3rem;
  margin-left: 1rem;
`;
