import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Select, Spin, Button, Tooltip, Popover, Input } from 'antd';
import Validation from 'utils/Validation';

const { Option } = Select;
function AccountId({
  trafficSource,
  getFieldDecorator,
  getTrafficSourceAccounts,
  trafficSourceAccountList,
  getTrafficSourceAccountsLoading,
  addNewPublishAccount,
  addingAccountLoading,
  accountAdded,
}) {
  useEffect(() => {
    getTrafficSourceAccounts(trafficSource);
  }, [trafficSource, getTrafficSourceAccounts]);

  useEffect(() => {
    if (accountAdded) {
      setAddAccountVisibility(!accountAdded);
    }
  }, [accountAdded]);

  const [accountName, setAccountName] = useState('');
  const [addAccountVisible, setAddAccountVisibility] = useState(null);

  const addAccountPopoverContent = (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Form.Item label="Account Name">
        <Input
          data-test="accountName"
          onChange={e => setAccountName(e.target.value)}
        />
      </Form.Item>

      <Button
        loading={addingAccountLoading}
        disabled={!accountName}
        data-test="addAccountBtn"
        type="primary"
        onClick={() => addNewPublishAccount(trafficSource, accountName)}
      >
        Add account
      </Button>
    </div>
  );

  return (
    <Form.Item label="Account Id">
      <Popover
        trigger="click"
        content={addAccountPopoverContent}
        title="Add new account"
        visible={addAccountVisible}
        onVisibleChange={e => setAddAccountVisibility(e)}
      >
        <Tooltip title="Add a new account">
          <Button loading={false} data-test="addAccount">
            +
          </Button>
        </Tooltip>
      </Popover>
      {getFieldDecorator('account_id', {
        rules: [Validation.required],
      })(
        <Select
          showSearch
          loading={getTrafficSourceAccountsLoading}
          placeholder="Select an Account"
          optionFilterProp="children"
          notFoundContent={
            getTrafficSourceAccountsLoading ? <Spin size="small" /> : null
          }
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          data-test="accountIdSelector"
        >
          {trafficSourceAccountList?.length > 0
            ? trafficSourceAccountList.map((itm, indx) => (
                <Option
                  value={itm.account_id}
                  key={itm.id}
                  {...(indx === trafficSourceAccountList.length - 1
                    ? { 'data-test': 'accountSelected' }
                    : {})}
                >
                  {itm.account_name}
                </Option>
              ))
            : null}
        </Select>,
      )}
    </Form.Item>
  );
}

export default AccountId;
