import { LoadingOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import React, { useEffect } from 'react';

import { ContainerStyle } from './styles';
import OptimizationData from './OptimizationData';
import RulesTable from './RulesTable';
import { CollapsePanelHeaderStyle } from '../styles';

const Optimization = ({
  campaign,
  onEditSave,
  campaignRules,
  fieldsToEdit,
  editCampaignLoading,
  getKampaniaData,
  getCampaignRules,
  fetchKampaniaDataLoading,
  fetchCampaignRulesLoading,
  editMode,
}) => {
  useEffect(() => {
    getCampaignRules();
  }, [campaign._id]); //eslint-disable-line
  const loadingIcon = <LoadingOutlined spin />;
  return (
    <ContainerStyle>
      <Card
        title={
          <CollapsePanelHeaderStyle>Optimization Data</CollapsePanelHeaderStyle>
        }
      >
        <OptimizationData
          campaign={campaign}
          onEditSave={onEditSave}
          fieldsToEdit={fieldsToEdit}
          editCampaignLoading={editCampaignLoading}
          getKampaniaData={getKampaniaData}
          fetchKampaniaDataLoading={fetchKampaniaDataLoading}
          editMode={editMode}
        />
      </Card>
      {fetchCampaignRulesLoading ? (
        <Spin indicator={loadingIcon} style={{ width: '100%', margin: 10 }} />
      ) : (
        <RulesTable rules={campaignRules} />
      )}
    </ContainerStyle>
  );
};

export default Optimization;
