import { Button } from 'antd';
import React, { useEffect } from 'react';

import {
  KeyContainerStyle,
  RowContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';

const FacebookIntegrationPanel = ({
  campaign,
  integrationToken,
  generateIntegrationToken,
  getTokenLoading,
  isIntegrated,
  showIntegrationLink,
}) => {
  useEffect(() => {
    if (integrationToken) {
      const integrationLink = getIntegrationLink();
      showIntegrationLink({
        title: 'Facebook Integration Link',
        link: integrationLink,
      });
    }
  }, [integrationToken]); //eslint-disable-line

  const getIntegrationLink = () => {
    const {
      REACT_APP_FA_URL,
      REACT_APP_FA_EXTERNAL_REDIRECT_URL,
    } = process.env;

    if (integrationToken) {
      return (
        `${REACT_APP_FA_URL}&redirect_uri=${REACT_APP_FA_EXTERNAL_REDIRECT_URL}` +
        `&state=${encodeURIComponent(
          JSON.stringify({ token: integrationToken }),
        )}`
      );
    }
  };

  const integrationStatus = isIntegrated ? 'Integrated' : 'Not Integrated';
  const accountAuthorized = campaign?.facebookAds?.fb_email;

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Status</KeyContainerStyle>
        <ValueContainerStyle>{integrationStatus}</ValueContainerStyle>
      </RowContainerStyle>
      <RowContainerStyle lastRow={!isIntegrated}>
        <KeyContainerStyle>
          {isIntegrated ? 'Authorize a different account' : 'Integrate'}
        </KeyContainerStyle>
        <ValueContainerStyle>
          <Button
            type="primary"
            onClick={() => generateIntegrationToken('facebook')}
            loading={getTokenLoading}
          >
            Generate Public Link
          </Button>
        </ValueContainerStyle>
      </RowContainerStyle>
      <RowContainerStyle lastRow hide={!isIntegrated}>
        <KeyContainerStyle>Account Authorized</KeyContainerStyle>
        <ValueContainerStyle>{accountAuthorized}</ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default FacebookIntegrationPanel;
