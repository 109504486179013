import { CardDefaultDivStyle } from 'styles/styledComponents';
import { LAPTOP_SCREEN_WIDTH, MOB_SCREEN_WIDTH } from 'utils/constants';
import styled from 'styled-components';
import theme from 'styles/themes';

export const ContainerStyle = styled(CardDefaultDivStyle)`
  width: 4rem;
  transition: all 0.4s;
  display: ${({ centeralize }) => (centeralize ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;

  & .ant-tabs-nav-container {
    padding-left: ${({ selectedTabKey }) => selectedTabKey === '1' && 0};
  }
  & .ant-tabs-tab-prev {
    display: ${({ selectedTabKey }) => selectedTabKey === '1' && 'none'};
  }
  & .ant-collapse-content-box {
    padding: 0 1.6rem !important;
    border-left: 0.1rem solid ${theme.colors.silver};
    border-right: 0.1rem solid ${theme.colors.silver};
  }
  & .ant-collapse {
    border: none;
  }
  & .ant-collapse-item {
    margin-bottom: 1.5rem;
  }
  & .ant-spin-nested-loading > div > .ant-spin {
    max-height: fit-content;
  }
`;

export const CardHeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CampaignNameStyle = styled.p`
  text-transform: capitalize;
  font-weight: bold;
  margin: 1rem;
  font-size: 1.6rem;
  max-width: calc(100rem - 750px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CampaignStateStyle = styled.p`
  text-transform: capitalize;
  color: ${({ stateColor }) => stateColor};
  border: ${({ stateColor }) => `0.1rem solid ${stateColor}`};
  margin: 1rem;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  user-select: none;
`;

export const TabHeaderStyle = styled.p`
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
`;

export const CollapseContainerStyle = styled.div`
  & .ant-collapse-header {
    background: ${theme.colors.main};
    font-weight: bold;
  }

  & .ant-collapse-arrow {
    color: ${theme.colors.white} !important;
  }
`;

export const CollapsePanelHeaderStyle = styled.p`
  color: ${theme.colors.white};
  margin: 0;
  user-select: none;
  text-transform: uppercase;
`;

export const RowContainerStyle = styled.div`
  align-items: center;
  margin: 0;
  font-size: 1.4rem;
  border-bottom: ${({ lastRow }) =>
    !lastRow && `0.1rem solid ${theme.colors.silver}`};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    & .ant-btn {
      font-size: 1.1rem;
    }

    & .ant-radio-button-wrapper {
      font-size: 1.1rem;
      padding: 0 0.8rem;
    }
  }
`;

export const KeyContainerStyle = styled.span`
  margin-bottom: 0;
  padding: 1rem 1rem 1rem 0;
  min-width: 25%;
  font-weight: bold;
  @media (max-width: ${LAPTOP_SCREEN_WIDTH}px) {
    font-size: 1.2rem;
    min-width: 35%;
  }
`;

export const KeyHintStyle = styled.p`
  font-size: 0.9rem;
  margin: 0;
`;

export const ValueContainerStyle = styled.span`
  margin-bottom: 0;
  padding: ${({ big }) => (big ? '2.6rem 1rem' : '1.8rem 1rem')};
  border-left: 0.1rem solid ${theme.colors.silver};
  min-width: 75%;
  word-break: break-word;
  white-space: pre-wrap;

  .ant-legacy-form-item-control-wrapper {
    width: 100%;
  }

  @media (max-width: ${LAPTOP_SCREEN_WIDTH}px) {
    font-size: 1.2rem;
    padding: ${({ big }) => (big ? '3rem 1rem' : '2.4rem 1rem')};
    min-width: 65%;
  }
`;

export const CampaignDetailsHeaderNameStateStyle = styled.div`
  display: flex;
`;

export const CampaignDetailsActionIcons = styled.div`
  & > *:not(:first-child) {
    margin-left: 10px;
  }
`;

export const actionIconStyles = { fontSize: 20, cursor: 'pointer' };

export const NotificationFormContainerStyle = styled.div`
  .ant-form-item-label {
    min-width: 20.6rem;
    text-align: left;
  }

  .ant-form-item-control {
    flex-grow: 1;
  }
`;

export const ScheduledPublishContainerStyle = styled.div`
  max-width: 520px;
  min-width: 300px;
  min-height: 300px;
`;

export const SinglePublishContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  > div > span {
    text-transform: capitalize;
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.grayBorder};
  }
`;
