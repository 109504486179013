import styled from 'styled-components';

export const PublishCampaignCardsStyle = styled.div`
  .ant-card {
    margin-bottom: 2rem;
  }

  .ant-alert-description {
    text-transform: none;
  }
`;

export const DatePickerContainer = styled.div`
  margin-bottom: 2rem;
`;
