import { AuthApi } from '../../utils/apiCalls';
import { handleApiError } from 'utils/helpers';

export default {
  name: 'userView',
  state: {
    loading: false,
    error: false,
    errorMsg: '',
  },

  actions: {
    /**
     * This action calls the user impersonation api
     * it takes the user campaign id and admin token and returns user token
     * After this, this action make another api call with the user token to get user data
     * @param {string} campaignId
     * @param {string} adminToken
     */
    *userImpersonate(id, type, adminToken) {
      try {
        yield { loading: true };
        const payload = {};
        payload[type] = id;
        // send admin token and campaign id to get user token
        const token = yield AuthApi.userImpersonate(payload, adminToken).catch(
          e => e,
        );
        handleApiError(token);

        const userData = yield AuthApi.getUser(token?.access_token).catch(
          e => e,
        );
        handleApiError(userData);

        yield {
          loading: false,
          token,
          userData,
        };
      } catch (error) {
        yield {
          loading: false,
          error: true,
          errorMsg: 'user not found',
        };
      }
    },
  },
};
