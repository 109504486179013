import styled from 'styled-components';

export const StatisticsContainerStyle = styled.dl`
  display: flex;
  margin: 0;
  line-height: initial;
  text-align: right;

  span:not(:last-of-type) {
    margin-right: 3rem;
  }

  dt {
    font-weight: 400;
  }

  dd {
    font-weight: 500;
  }
`;
