import React from 'react';
import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import Validation from 'utils/Validation';

function UploadFromUrl({ getFieldDecorator }) {
  return (
    <Form.Item label="Upload from URL">
      {getFieldDecorator('images_url', {
        rules: [Validation.url],
      })(<Input data-test="uploadFromUrl" />)}
    </Form.Item>
  );
}

export default UploadFromUrl;
