import React from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Card } from 'antd';
import RangePicker from '../SharedComponents/rangePicker';
import Keywords from '../SharedComponents/keywords';
import GenerateKeywords from '../SharedComponents/generateKeywords';
import Cpc from '../SharedComponents/cpc';
import AccountId from '../SharedComponents/accountId';

function Bing({
  addNewPublishAccount,
  addingAccountLoading,
  getFieldDecorator,
  getFieldValue,
  campaign,
  campaignKeywords,
  keywordsLoading,
  getTrafficSourceAccounts,
  trafficSourceAccountList,
  getTrafficSourceAccountsLoading,
  generateKeywords,
  resetKeywords,
  setFieldsValue,
  accountAdded,
}) {
  return (
    <>
      <Card title="Bing Details" size="small">
        <AccountId
          trafficSource="bing"
          getFieldDecorator={getFieldDecorator}
          getTrafficSourceAccounts={getTrafficSourceAccounts}
          trafficSourceAccountList={trafficSourceAccountList}
          getTrafficSourceAccountsLoading={getTrafficSourceAccountsLoading}
          addNewPublishAccount={addNewPublishAccount}
          addingAccountLoading={addingAccountLoading}
          accountAdded={accountAdded}
        />

        <Cpc getFieldDecorator={getFieldDecorator} defaultCPC={0.5} />

        <RangePicker
          getFieldDecorator={getFieldDecorator}
          campaign={campaign}
        />

        <Form.Item label="Title Part 1">
          {getFieldDecorator('title_part1', {
            rules: [
              {
                required: true,
                message: 'Please input Title Part 1!',
              },
            ],
          })(<Input data-test="titlePart1" />)}
        </Form.Item>

        <Form.Item label="Title Part 2">
          {getFieldDecorator('title_part2', {
            rules: [
              {
                required: true,
                message: 'Please input Title Part 2!',
              },
            ],
          })(<Input data-test="titlePart2" />)}
        </Form.Item>

        <Form.Item label="Title Part 3">
          {getFieldDecorator('title_part3')(<Input data-test="titlePart3" />)}
        </Form.Item>

        <Form.Item label="Ad Text">
          {getFieldDecorator('ad_text1', {
            rules: [
              {
                required: true,
                message: 'Please input an Ad Text!',
              },
            ],
          })(<Input data-test="adText" />)}
        </Form.Item>

        <Form.Item label="Ad Text 2">
          {getFieldDecorator('ad_text2')(<Input data-test="adText2" />)}
        </Form.Item>
      </Card>

      <Card title="Keywords" size="small">
        <Keywords
          getFieldDecorator={getFieldDecorator}
          campaignKeywords={campaignKeywords}
          keywordsLoading={keywordsLoading}
          resetKeywords={resetKeywords}
          setFieldsValue={setFieldsValue}
        />
        <GenerateKeywords
          getFieldDecorator={getFieldDecorator}
          generateKeywords={generateKeywords}
          campaign={campaign}
          keywordsLoading={keywordsLoading}
        />
      </Card>
    </>
  );
}

export default Bing;
