import Cookies from 'universal-cookie';
import { AuthApi } from 'utils/apiCalls';
import {
  AUTH_EXPIRATION_PERIOD,
  COOKIES_AUTHORIZATION,
  REFRESH_TOKEN,
} from 'utils/constants';
import * as helpers from 'utils/helpers';
import * as userTypes from 'utils/userTypes';

const cookies = new Cookies();

export default {
  name: 'login',

  state: {
    loading: false,
    error: false,
    errorMsg: '',
    isLoggedIn: false,
  },

  actions: {
    *submitLoginForm(userCredentials, rememberMe) {
      try {
        yield {
          loading: true,
        };

        const tokens = yield AuthApi.login({
          ...userCredentials,
          remember_me: rememberMe,
        }).catch(e => e);

        helpers.handleApiError(tokens);

        const user = yield AuthApi.getUser(tokens.access_token).catch(e => e);

        helpers.handleApiError(user);

        if (user.role === userTypes.currentUserType) {
          if (rememberMe) {
            cookies.set(COOKIES_AUTHORIZATION, tokens.access_token);
            cookies.set(REFRESH_TOKEN, tokens.refresh_token, {
              maxAge: AUTH_EXPIRATION_PERIOD,
            });
          } else {
            cookies.set(COOKIES_AUTHORIZATION, tokens.access_token);
          }

          yield {
            loading: false,
            isLoggedIn: true,
          };
        } else {
          yield {
            loading: false,
            error: true,
            errorMsg: 'Your email address or password is wrong',
          };
        }
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        yield {
          loading: false,
          error: true,
          errorMsg,
        };
      }
    },
    resetLoggedInState() {
      return {
        isLoggedIn: false,
      };
    },
  },
};
