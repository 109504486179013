import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Radio, InputNumber } from 'antd';

function BiddingStrategy({ getFieldDecorator, defaultCPC = 1 }) {
  const [biddingStrategy, setBiddingStrategy] = useState('manual_cpc');
  const [cpcAmount, setCpcAmount] = useState(defaultCPC);

  return (
    <Form.Item
      label="Bidding Strategy"
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
      }}
    >
      {getFieldDecorator('bidding_strategy', {
        initialValue: biddingStrategy,
      })(
        <Radio.Group
          onChange={e => {
            setBiddingStrategy(e.target.value);
          }}
          style={{
            display: 'flex',
            marginTop: 10,
            ...(biddingStrategy === 'manual_cpc' ? { height: 70 } : {}),
          }}
          data-test="biddingStrategy"
        >
          <Radio value="maximized_clicks">Maximized Clicks</Radio>
          <Radio value="manual_cpc">
            Manual Cpc
            {biddingStrategy === 'manual_cpc' ? (
              <Form.Item style={{ marginTop: 15, width: '80px' }}>
                {getFieldDecorator('cpc_amount', {
                  initialValue: cpcAmount,
                })(
                  <InputNumber
                    step={0.1}
                    min={0.1}
                    onChange={setCpcAmount}
                    style={{ marginLeft: 'auto' }}
                  />,
                )}
              </Form.Item>
            ) : null}
          </Radio>
        </Radio.Group>,
      )}
    </Form.Item>
  );
}

export default BiddingStrategy;
