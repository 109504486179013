import React from 'react';

import { PageBody, PageContainer, PageHeader } from './styles';

const PageLayout = ({ pageHeader, pageBody }) => {
  return (
    <PageContainer>
      <PageHeader>{pageHeader}</PageHeader>
      <PageBody>{pageBody}</PageBody>
    </PageContainer>
  );
};

export default PageLayout;
