import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { COOKIES_AUTHORIZATION } from 'utils/constants';

import { pathHomePage, pathLogin } from './routes';

const cookies = new Cookies();

const RootRedirectRoute = props => {
  const isLoggedIn = cookies.get(COOKIES_AUTHORIZATION);

  return (
    <Route
      {...props}
      render={() =>
        isLoggedIn ? (
          <Redirect push to={pathHomePage} />
        ) : (
          <Redirect push to={pathLogin} />
        )
      }
    />
  );
};

export default RootRedirectRoute;
