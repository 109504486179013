import React from 'react';
import { SliderStyle } from 'styles/styledComponents';
import { Form } from '@ant-design/compatible';
import { Slider, InputNumber } from 'antd';
import Validation from 'utils/Validation';

function WeightSlider({
  getFieldDecorator,
  campaign,
  weight,
  setWeight,
  setFieldsValue,
}) {
  const calculateMaxAllowedBudget = () =>
    (
      (campaign?.package?.abm *
        ((campaign?.package?.balance || 0) + (campaign?.package?.bonus || 0)) *
        weight) /
      100
    ).toFixed(2) || 0;

  return (
    <SliderStyle>
      <Form.Item
        style={{ marginTop: 24, display: 'flex', flexWrap: 'nowrap' }}
        label="Assigned Budget"
      >
        {getFieldDecorator('weight', {
          initialValue: weight,
          rules: [Validation.weight],
        })(
          <div className="customized-slider-container">
            <Slider
              data-test="weightSlider"
              min={0}
              max={100}
              step={1}
              tipFormatter={value => `${value}%`}
              onChange={setWeight}
              value={typeof weight === 'number' ? weight : 0}
            />
            <InputNumber
              data-test="weightInput"
              min={0}
              max={100}
              step={1}
              style={{ marginLeft: 16 }}
              value={weight}
              onChange={val => {
                if (typeof val === 'number') {
                  setFieldsValue({ weight: val });
                  setWeight(val);
                }
              }}
            />
          </div>,
        )}

        <p
          style={{ marginBottom: 0 }}
        >{`$${calculateMaxAllowedBudget()} from the total allowed budget`}</p>
      </Form.Item>
    </SliderStyle>
  );
}

export default WeightSlider;
