import {
  CampaignsApi,
  GAApi,
  IntegrationsApi,
  PackagesApi,
  PaymentApi,
  UsersApi,
  NotificationsApi,
  KeywordsApi,
} from 'utils/apiCalls';
import {
  handleApiError,
  showMessage,
  getPackageStartDate,
  getPackageEndDate,
} from 'utils/helpers';
import notificationsList from 'utils/notifications.json';
import axios from 'axios';

const getRemainingBudget = (paymanCost, balance) => {
  const formattedPaymanCost = {};

  // eslint-disable-next-line no-unused-expressions
  paymanCost?.data?.forEach(({ transaction_date, total }) => {
    formattedPaymanCost[transaction_date] =
      total / 100 + (formattedPaymanCost[transaction_date] || 0);
  });

  const totalChargedCost = Object.values(formattedPaymanCost)?.reduce(
    (acc, cur) => acc + Number(cur),
    0,
  );

  const remainingBudget = balance - totalChargedCost;

  return remainingBudget >= 0 ? remainingBudget : 0;
};

export let cancelPendingRequests = () => {};

export default {
  name: 'campaignDetails',
  state: {
    campaign: { package: {}, kampania: {} },
    campaignLinks: {},
    fetchCampaignError: null,
    fetchCampaignLoading: true,

    googleGetKeywordsLoading: false,
    semrushGetKeywordsLoading: false,
    bingGetKeywordsLoading: false,

    editLoading: false,
    fieldsToEdit: [],

    campaignCreatives: {},
    fetchCampaignCreativesLoading: false,

    campaignRules: [],
    fetchCampaignRulesLoading: true,

    campaignWorkflowRuns: [],
    fetchCampaignWorkflowRunsLoading: false,

    tsCampaigns: {},
    tsCampaignsStatus: {},
    fetchTsCampaignsLoading: false,

    campaignLocations: [],
    fetchCampaignLocationsLoading: false,

    gaViews: [],
    fetchCampaignGAViewsLoading: false,

    fetchKampaniaDataLoading: false,
    fetchCampaignIntegrationsLoading: false,
    patchCampaignGAViewLoading: false,

    opentagScripts: [],
    fetchCampaignScriptsLoading: false,

    gaGoals: [],
    fetchCampaignGoalsLoading: null,

    accountTransactions: [],

    editCampaignOpenTagLoading: false,

    cloneCampaignLoading: false,

    gaIntegrationToken: '',
    generateGaIntegrationTokenLoading: false,

    fbIntegrationToken: '',
    generateFbIntegrationTokenLoading: false,

    revokeGaLoading: false,
    revokeFbLoading: false,

    archiveCampaignLoading: false,
    archiveCampaignError: '',
    isCampaignArchived: false,
    isCampaignUpdated: false,
    isPackageUpdated: false,

    extendCampaignLoading: false,
    sucessExtendCampaign: false,
    extendCampaignError: '',

    editWeightLoading: false,
    doneEditingWeight: false,
    failedToFetchStatus: false,

    googleSaveKeywordsLoading: false,
    semrushSaveKeywordsLoading: false,
    bingSaveKeywordsLoading: false,
    manualSaveKeywordsLoading: false,
  },
  actions: {
    *getCampaign(campaignId) {
      yield { fetchCampaignLoading: true };
      try {
        const CancelToken = axios.CancelToken;

        const cancelToken = new CancelToken(c => {
          cancelPendingRequests = c;
        });

        const data = yield CampaignsApi.getCampaignById(
          campaignId,
          cancelToken,
        ).catch(e => e);
        handleApiError(data);

        const usersEmails = yield UsersApi.getCampaignUserEmails(
          data?.campaign?.emails,
          cancelToken,
        ).catch(e => e);

        handleApiError(usersEmails);

        if (data) {
          data.campaign.usersEmails = usersEmails;
        }

        if (data?.campaign) {
          yield {
            fetchCampaignLoading: false,
            fetchCampaignError: null,
            campaign: data?.campaign,
            campaignLinks: data?.links,
            failedToFetchStatus: false,
          };
        }
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignLoading: false,
          fetchCampaignError: JSON.parse(message),
        };
      }
    },

    *extendCampaign(params) {
      yield { extendCampaignLoading: true };
      try {
        const data = yield CampaignsApi.extendCampaign(params).catch(e => e);
        handleApiError(data);
        yield {
          extendCampaignLoading: false,
          sucessExtendCampaign: true,
        };
      } catch (err) {
        const { message } = err;
        yield {
          sucessExtendCampaign: false,
          extendCampaignLoading: false,
          extendCampaignError: message,
        };
      }
    },

    *archiveCampaign(campaignId) {
      yield { archiveCampaignLoading: true };
      try {
        const data = yield CampaignsApi.archiveCampaignById(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        yield {
          archiveCampaignLoading: false,
          archiveCampaignError: null,
          isCampaignArchived: true,
        };
      } catch (err) {
        const { message } = err;
        yield {
          archiveCampaignLoading: false,
          archiveCampaignError: JSON.parse(message),
        };
      }
    },

    *editCampaign(updatedField) {
      try {
        const updatedFieldKeysArray = Object.keys(updatedField);

        const campaign = this.getState().campaign;

        const isInstascalerCampaign =
          campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

        yield {
          editLoading: true,
          fieldsToEdit: updatedFieldKeysArray,
        };

        const returnData = yield CampaignsApi.editCampaign({
          campaignId: campaign._id,
          updatedField,
          isInstascalerCampaign,
        }).catch(e => e);

        handleApiError(returnData);

        const newKampaniaObj = updatedFieldKeysArray.includes(
          'semaphoreFinalUrl',
        )
          ? {
              ...campaign.kampania,
              semaphore_final_url: updatedField.semaphoreFinalUrl,
            }
          : { ...campaign.kampania };

        yield {
          editLoading: false,
          isCampaignUpdated: true,
          campaign: { ...campaign, ...returnData, kampania: newKampaniaObj },
        };

        if (updatedField.state) {
          showMessage(
            'success',
            `Campaign changed to ${updatedField.state} successfully`,
          );
        } else {
          showMessage('success', 'Edited successfully');
        }
      } catch (err) {
        const { message } = err;
        yield {
          editLoading: false,
        };
        const displayMessage = JSON.parse(message)?.errorMsg;
        showMessage(
          'error',
          `Error happened${displayMessage ? `: ${displayMessage}` : ''}`,
        );
      }
    },

    *editPackage(updatedField) {
      const updatedFieldKeysArray = Object.keys(updatedField);

      const campaign = this.getState().campaign;

      const isInstascalerCampaign =
        campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

      yield {
        editLoading: true,
        fieldsToEdit: updatedFieldKeysArray,
      };
      try {
        const returnData = yield PackagesApi.editPackage({
          packageId: campaign.package._id,
          updatedField: { package: updatedField },
          isInstascalerCampaign,
        }).catch(e => e);
        handleApiError(returnData);

        yield {
          editLoading: false,
          isPackageUpdated: true,
          campaign: { ...campaign, package: returnData },
        };
        showMessage('success', 'Edited successfully');
      } catch (err) {
        const { message } = err;
        yield {
          editLoading: false,
        };
        const displayMessage = JSON.parse(message)?.errorMsg;
        const errorCode = JSON.parse(message)?.errorCode;
        if (errorCode === 409) {
          yield {
            campaign: {
              ...campaign,
              package: { ...campaign.package, auto_renew: false },
            },
          };
        }
        showMessage(
          'error',
          `Error happened${displayMessage ? `: ${displayMessage}` : ''}`,
        );
      }
    },

    *getAccountTransactions() {
      const campaign = this.getState().campaign;

      let accountTransactionsData = [];
      const accountId = campaign?.package?.account;
      if (accountId) {
        const account = yield UsersApi.getAccount(accountId).catch(e => e);
        handleApiError(campaign);

        yield {
          account,
          paymanAccountId: account.payman_account_id,
        };

        if (account.payman_account_id || account.payman_account_id === 0) {
          accountTransactionsData = yield PaymentApi.getAccountTransactions(
            accountId,
          ).catch(e => e);

          handleApiError(accountTransactionsData);

          yield {
            accountTransactions:
              accountTransactionsData.objects || accountTransactionsData,
          };
        }
      }
    },

    *getCampaignCreatives(campaignId) {
      yield { fetchCampaignCreativesLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignCreatives(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        yield {
          fetchCampaignCreativesLoading: false,
          campaignCreatives: data,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignCreativesLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign creatives${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getKampaniaData(campaignId) {
      yield { fetchKampaniaDataLoading: true };
      try {
        const data = yield CampaignsApi.getKampaniaData(campaignId).catch(
          e => e,
        );
        handleApiError(data);
        const campaign = this.getState().campaign;

        yield {
          fetchKampaniaDataLoading: false,
          campaign: { ...campaign, kampania: data },
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchKampaniaDataLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching kampania data${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignIntegrations(campaignId) {
      yield { fetchCampaignIntegrationsLoading: true };
      try {
        const data = yield CampaignsApi.getIntegrations(campaignId).catch(
          e => e,
        );
        handleApiError(data);
        const campaign = this.getState().campaign;

        yield {
          fetchCampaignIntegrationsLoading: false,
          campaign: { ...campaign, ...data },
        };
      } catch (err) {
        const { message } = err;

        yield {
          fetchCampaignIntegrationsLoading: false,
        };
        let displayedError = '';
        try {
          const messageObj = JSON.parse(message);
          displayedError = messageObj.errorMsg;
        } catch (error) {
          displayedError = message;
        }
        showMessage(
          'error',
          `Error while fetching campaign integrations data${
            displayedError ? `: ${displayedError}` : ''
          }`,
        );
      }
    },

    *getCampaignRules(campaignId) {
      yield { fetchCampaignRulesLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignRules(campaignId).catch(
          e => e,
        );
        handleApiError(data);

        yield {
          fetchCampaignRulesLoading: false,
          campaignRules: data.objects,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignRulesLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign rules${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignWorkflowRuns(campaignId) {
      yield { fetchCampaignWorkflowRunsLoading: true };
      try {
        const data = yield CampaignsApi.getCampaignWorkflowRuns(
          campaignId,
        ).catch(e => e);
        handleApiError(data);

        yield {
          fetchCampaignWorkflowRunsLoading: false,
          campaignWorkflowRuns: data,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignWorkflowRunsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign workflow runs${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getTsCampaigns(kampaniaId) {
      try {
        if (kampaniaId) {
          yield { fetchTsCampaignsLoading: true };

          const data = yield CampaignsApi.getTsCampaigns(kampaniaId).catch(
            e => e,
          );
          handleApiError(data);
          yield {
            fetchTsCampaignsLoading: false,
            tsCampaigns: data,
          };
        }
      } catch (err) {
        const { message } = err;
        yield {
          fetchTsCampaignsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching traffic source campaigns${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getTsCampaignsStatus(TrafficSourcWithIds) {
      yield { fetchTsCampaignsLoading: true };
      try {
        const data = yield CampaignsApi.getTsCampaignsStatus(
          TrafficSourcWithIds,
        ).catch(e => e);
        handleApiError(data);
        yield {
          tsCampaignsStatus: data?.result,
          fetchTsCampaignsLoading: false,
          failedToFetchStatus:
            Object.keys(data.result).length > 0 ? false : true,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchTsCampaignsLoading: false,
          failedToFetchStatus: true,
        };
        showMessage(
          'error',
          `Error while fetching traffic source campaigns status${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getCampaignGaGoals(campaignId) {
      yield { fetchCampaignGoalsLoading: true };
      try {
        const data = yield GAApi.getCampaignGoals(campaignId).catch(e => e);

        handleApiError(data);
        yield {
          fetchCampaignGoalsLoading: false,
          gaGoals: data.conversions || [],
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignGoalsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching campaign goals${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignScripts() {
      yield { fetchCampaignScriptsLoading: true };
      const campaign = this.getState().campaign;

      const encryptedId = campaign.encrypted_id;

      if (encryptedId)
        try {
          const data = yield CampaignsApi.getCampaignOpentagScripts(
            encryptedId,
          ).catch(e => e);
          handleApiError(data);

          yield {
            opentagScripts: data,
            fetchCampaignScriptsLoading: false,
          };
        } catch (err) {
          const { message } = err;
          yield {
            fetchCampaignScriptsLoading: false,
          };
          showMessage(
            'error',
            `Error while fetching campaign scripts${
              message ? `: ${message}` : ''
            }`,
          );
        }
    },

    *editCampaignOpentagScripts(campaignId, newOpentagScripts) {
      yield {
        editCampaignOpenTagLoading: true,
      };

      const campaign = this.getState().campaign;

      const isInstascalerCampaign =
        campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

      try {
        const data = yield CampaignsApi.editCampaignOpentagScripts(
          campaignId,
          newOpentagScripts,
          isInstascalerCampaign,
        ).catch(e => e);
        handleApiError(data);

        const campaign = this.getState().campaign;

        yield {
          editCampaignOpenTagLoading: false,
          opentagScripts: data.scripts,
          campaign: {
            ...campaign,
            optimize_with:
              newOpentagScripts?.analyticsTrackingService?.optimize_with,
          },
        };
        showMessage('success', 'Edited successfully');
      } catch (err) {
        const { message } = err;
        yield {
          editCampaignOpenTagLoading: false,
        };
        showMessage(
          'error',
          `Error while editing${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCampaignGAViews(campaignId) {
      yield { fetchCampaignGAViewsLoading: true };
      try {
        const data = yield GAApi.getCampaignGAViews(campaignId).catch(e => e);
        handleApiError(data);

        const formattedGAViews = [];

        data.web_properties.forEach(g => {
          g.views.forEach(v =>
            formattedGAViews.push({
              ...v,
              web_property_id: g.id,
              web_property_name: g.name,
            }),
          );
        });

        yield {
          fetchCampaignGAViewsLoading: false,
          gaViews: formattedGAViews,
        };
      } catch (err) {
        const { message } = err;
        yield {
          fetchCampaignGAViewsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching GA views${message ? `: ${message}` : ''}`,
        );
      }
    },

    *patchCampaignGAView({ campaignId, viewId, webPropertyId }) {
      yield {
        patchCampaignGAViewLoading: true,
      };

      try {
        const data = yield GAApi.patchGAView({
          campaignId,
          viewId,
          webPropertyId,
        }).catch(e => e);
        handleApiError(data);

        const campaign = this.getState().campaign;

        yield {
          patchCampaignGAViewLoading: false,
          campaign: {
            ...campaign,
            google_analytics: {
              ...campaign.google_analytics,
              profile_id: viewId,
            },
          },
        };
      } catch (err) {
        const { message } = err;
        yield {
          patchCampaignGAViewLoading: false,
        };
        showMessage(
          'error',
          `Error while patching views${message ? `: ${message}` : ''}`,
        );
      }
    },

    *cloneCampaign(wfrunId, trafficSource) {
      yield { cloneCampaignLoading: true };
      try {
        const clonedCampaign = yield CampaignsApi.cloneCampaign(
          wfrunId,
          trafficSource,
        ).catch(e => e);
        handleApiError(clonedCampaign);

        yield { cloneCampaignLoading: false };

        showMessage('success', 'Campaign was successfully cloned');
      } catch (err) {
        yield {
          cloneCampaignLoading: false,
        };
        showMessage('error', 'Error while cloning campaign');
      }
    },

    *generateIntegrationToken(campaignId, platform) {
      let loadingState = '';
      let valueState = '';

      switch (platform) {
        case 'google':
          loadingState = 'generateGaIntegrationTokenLoading';
          valueState = 'gaIntegrationToken';
          break;
        case 'facebook':
          loadingState = 'generateFbIntegrationTokenLoading';
          valueState = 'fbIntegrationToken';
          break;
        default:
          break;
      }

      yield {
        [loadingState]: true,
      };

      const campaign = this.getState().campaign;

      const isInstascalerCampaign =
        campaign.delivery_utm_source.toLocaleLowerCase() === 'instascaler';

      try {
        const integrationLink = yield IntegrationsApi.generateIntegrationToken(
          campaignId,
          isInstascalerCampaign,
        ).catch(e => e);
        handleApiError(integrationLink);

        yield {
          [valueState]: integrationLink.token,
          [loadingState]: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          [loadingState]: false,
        };
        showMessage(
          'error',
          `Error while generating token${message ? `: ${message}` : ''}`,
        );
      }
    },

    *revokeGaIntegration(campaignId) {
      yield {
        revokeGaLoading: true,
      };
      const campaign = this.getState().campaign;
      const id = campaignId || campaign._id;

      try {
        const data = yield IntegrationsApi.revokeGa(id).catch(e => e);
        handleApiError(data);

        yield {
          campaign: { ...campaign, is_ga_integrated: false },
          revokeGaLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          revokeGaLoading: false,
        };
        showMessage(
          'error',
          `Error while revoking${message ? `: ${message}` : ''}`,
        );
      }
    },

    *revokeFbIntegration(campaignId) {
      yield {
        revokeFbLoading: true,
      };
      const campaign = this.getState().campaign;
      const id = campaignId || campaign._id;

      try {
        const data = yield IntegrationsApi.revokeFb(id).catch(e => e);
        handleApiError(data);
        yield {
          campaign: { ...campaign, is_fb_integrated: false },
        };

        yield {
          revokeFbLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          revokeFbLoading: false,
        };
        showMessage(
          'error',
          `Error while revoking${message ? `: ${message}` : ''}`,
        );
      }
    },

    *editTSCampaignWeight(weight, wf_run_id) {
      try {
        yield {
          editWeightLoading: true,
        };

        const campaign = this.getState('campaign');
        const packageObj = campaign?.package;

        if (packageObj) {
          const start_date = getPackageStartDate(packageObj);
          const end_date = getPackageEndDate(packageObj);
          const maxAllowedBudget = packageObj?.balance * packageObj?.abm;

          const reqBody = {
            wf_run_id,
            end_date,
            start_date,
            weight: weight / 100,
            budget: maxAllowedBudget,
          };

          const editWeight = yield CampaignsApi.editTSCampaignWeight(reqBody);

          handleApiError(editWeight);

          yield {
            doneEditingWeight: true,
            editWeightLoading: false,
          };
        }
      } catch (error) {
        const { errorMsg } = JSON.parse(error.message);
        showMessage(
          'error',
          `Error while Editing TS campaign weight${
            errorMsg ? `: ${errorMsg}` : ''
          }`,
        );
        yield {
          editWeightLoading: false,
        };
      }
    },

    *sendNotification(campaignId, body, closeDrawer) {
      try {
        yield {
          sendNotificationLoading: true,
        };

        const sendNotifications = yield NotificationsApi.sendNotification(
          campaignId,
          body,
        ).catch(e => e);

        handleApiError(sendNotifications);

        showMessage('success', `Your notification has been sent successfully!`);

        yield {
          sendNotificationLoading: false,
        };

        closeDrawer();
      } catch (err) {
        const { message } = err;
        yield {
          sendNotificationLoading: false,
        };
        showMessage(
          'error',
          `Error while sending notification${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getCustomerNotifications(campaignId) {
      try {
        yield {
          getCustomerNotificationsLoading: true,
        };

        const getCustomerNotificationsRes = yield NotificationsApi.getIssuesNotificationsHistory(
          campaignId,
        ).catch(e => e);

        handleApiError(getCustomerNotificationsRes);

        const customerNotifications = [];

        // eslint-disable-next-line no-unused-expressions
        getCustomerNotificationsRes?.hits?.hits.forEach(notification => {
          const notificationSource = notification?._source;
          const { author, metadata } = notificationSource;

          const notificationPayload = JSON.parse(metadata)?.payload
            ?.notification;
          const text = notificationPayload.text;
          const title = notificationsList?.notifications?.find(
            i => i.id === notificationPayload?.category,
          )?.title;
          const id = notificationPayload.id;

          customerNotifications.push({
            id,
            author,
            title,
            text,
          });
        });

        yield {
          customerNotifications,
          getCustomerNotificationsLoading: false,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getCustomerNotificationsLoading: false,
        };
        showMessage(
          'error',
          `Error while getting Customer Notifications${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    resetCustomerNotifications() {
      return {
        customerNotifications: [],
        getCustomerNotificationsLoading: false,
      };
    },

    *getPaymanCharges(campaignId) {
      try {
        const paymanCost = yield PaymentApi.getPaymanCost({
          campaignId,
        }).catch(e => e);

        handleApiError(paymanCost);

        const remainingBudget = getRemainingBudget(
          paymanCost,
          this?.getState('campaign')?.package?.balance,
        );

        yield {
          remainingBudget,
        };
      } catch (err) {
        const { message } = err;
        showMessage(
          'error',
          `Error while getting Payman charges${message ? `: ${message}` : ''}`,
        );
      }
    },

    *chargeCampaign(campaignId, amount, email, endDate) {
      try {
        yield {
          chargeCampaignLoading: true,
        };

        const chargeCampaign = yield PaymentApi.chargeCampaign(
          campaignId,
          amount,
          this.getState('paymanAccountId'),
          email,
          endDate,
        ).catch(e => e);

        handleApiError(chargeCampaign);

        yield {
          chargeCampaignLoading: false,
        };

        showMessage('success', `Campaign charged Successfully!`);

        const paymanCost = yield PaymentApi.getPaymanCost({
          campaignId,
        }).catch(e => e);

        handleApiError(paymanCost);

        const remainingBudget = getRemainingBudget(
          paymanCost,
          this?.getState('campaign')?.package?.balance,
        );

        yield {
          remainingBudget,
        };
      } catch (err) {
        const { message } = err;
        yield {
          chargeCampaignLoading: false,
        };

        showMessage(
          'error',
          `Error while charging campaign${message ? `: ${message}` : ''}`,
        );
      }
    },

    *toggleTSCampaign(TSCampaignId, isStart) {
      try {
        yield {
          toggleTSCampaignLoading: true,
        };

        const toggleTSCampaign = yield CampaignsApi.toggleTSCampaign(
          TSCampaignId,
          isStart,
        ).catch(e => e);

        handleApiError(toggleTSCampaign);

        yield {
          toggleTSCampaignLoading: false,
        };

        showMessage(
          'success',
          `Campaign ${isStart ? 'started' : 'paused'} Successfully!`,
        );
      } catch (e) {
        const { message } = e;
        yield {
          toggleTSCampaignLoading: false,
        };

        showMessage(
          'error',
          `Error while ${isStart ? 'starting' : 'pausing'} campaign${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getAllScheduledPublish(campaignId) {
      try {
        yield {
          getAllScheduledPublishLoading: true,
        };
        const scheduledPublishRes = yield CampaignsApi.getAllScheduledPublish(
          campaignId,
        ).catch(e => e);

        handleApiError(scheduledPublishRes);

        yield {
          publish_jobs: scheduledPublishRes?.publish_jobs,
          getAllScheduledPublishLoading: false,
        };
      } catch (e) {
        const { message } = e;
        yield {
          getAllScheduledPublishLoading: false,
        };
        showMessage(
          'error',
          `Error while getting All Scheduled Publish ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *deleteScheduledPublish(id) {
      try {
        yield {
          loadingDeleteScheduledPublish: true,
          publishToBeDeleted: id,
        };

        const deleteScheduledPublishRes = yield CampaignsApi.deleteScheduledPublish(
          id,
        ).catch(e => e);

        handleApiError(deleteScheduledPublishRes);

        yield {
          loadingDeleteScheduledPublish: false,
          publishToBeDeleted: '',
          getAllScheduledPublishLoading: true,
        };

        showMessage('success', 'Scheduled Publish deleted Successfully!');

        const scheduledPublishRes = yield CampaignsApi.getAllScheduledPublish(
          this.getState('campaign')?._id,
        ).catch(e => e);

        handleApiError(scheduledPublishRes);

        yield {
          getAllScheduledPublishLoading: false,
          publish_jobs: scheduledPublishRes?.publish_jobs,
        };
      } catch (e) {
        const { message } = e;
        yield {
          loadingDeleteScheduledPublish: false,
          publishToBeDeleted: '',
          getAllScheduledPublishLoading: false,
        };
        showMessage(
          'error',
          `Error while deleting Scheduled Publish ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *getKeywords({ campaign_id, domain, source, keywords_seeds, countries }) {
      try {
        yield {
          [`${source}GetKeywordsLoading`]: true,
        };

        const getKeywordsRes = yield KeywordsApi.getKeywords({
          campaign_id,
          domain,
          source,
          keywords_seeds,
          countries,
        }).catch(e => e);

        handleApiError(getKeywordsRes);

        yield {
          [`${source}GetKeywordsLoading`]: false,
          [`${source}Keywords`]: getKeywordsRes?.data,
        };
      } catch (e) {
        const { message } = e;
        yield {
          [`${source}GetKeywordsLoading`]: false,
        };
        showMessage(
          'error',
          `Error while getting keywords ${message ? `: ${message}` : ''}`,
        );
      }
    },

    *getSelectedKeywords({ campaign_id, source }) {
      try {
        yield {
          [`${source}GetSelectedKeywordsLoading`]: true,
        };

        const getSelectedKeywordsRes = yield KeywordsApi.getSelectedKeywords({
          campaign_id,
          source,
        }).catch(e => e);

        handleApiError(getSelectedKeywordsRes);

        yield {
          [`${source}GetSelectedKeywordsLoading`]: false,
          [`${source}SelectedKeywords`]: getSelectedKeywordsRes?.keywords,
        };
      } catch (e) {
        const { message } = e;
        yield {
          [`${source}GetSelectedKeywordsLoading`]: false,
        };
        showMessage(
          'error',
          `Error while getting selected keywords ${
            message ? `: ${message}` : ''
          }`,
        );
      }
    },

    *selectKeywords({ campaign_id, source, keywords }) {
      try {
        yield {
          [`${source}SaveKeywordsLoading`]: true,
        };

        const body = {
          source,
          keywords,
        };

        const saveKeywordsRes = yield KeywordsApi.selectKeywords({
          campaign_id,
          body,
        }).catch(e => e);

        handleApiError(saveKeywordsRes);

        showMessage('success', `Keywords saved successfully`);

        yield {
          sourceTobeRefreshed: source,
          [`${source}SaveKeywordsLoading`]: false,
        };
      } catch (e) {
        const { message } = e;
        yield {
          [`${source}SaveKeywordsLoading`]: false,
        };
        showMessage(
          'error',
          `Error while saving keywords ${message ? `: ${message}` : ''}`,
        );
      }
    },

    resetSelectedKeywords() {
      return {
        googleGetSelectedKeywordsLoading: false,
        googleSelectedKeywords: [],
        semrushGetSelectedKeywordsLoading: false,
        semrushSelectedKeywords: [],
        bingGetSelectedKeywordsLoading: false,
        manualGetSelectedKeywordsLoading: false,
        bingSelectedKeywords: [],
        manualSelectedKeywords: [],
      };
    },

    resetKeywords() {
      return {
        bingKeywords: [],
        googleKeywords: [],
        semrushKeywords: [],
        bingSelectedKeywords: [],
        googleSelectedKeywords: [],
        semrushSelectedKeywords: [],
        manualSelectedKeywords: [],
        bingGetKeywordsLoading: false,
        googleGetKeywordsLoading: false,
        semrushGetKeywordsLoading: false,
      };
    },

    resetSourceTobeRefreshed() {
      return {
        sourceTobeRefreshed: '',
      };
    },

    resetState() {
      return {
        campaignLinks: {},
        fetchCampaignError: null,
        fetchCampaignLoading: false,

        editLoading: false,
        fieldsToEdit: [],

        campaignRules: [],
        fetchCampaignRulesLoading: false,

        campaignWorkflowRuns: [],
        fetchCampaignWorkflowRunsLoading: false,

        fetchTsCampaignsLoading: false,
        tsCampaigns: {},
        tsCampaignsStatus: {},

        campaignLocations: [],
        fetchCampaignLocationsLoading: false,

        gaViews: [],
        fetchCampaignGAViewsLoading: false,

        fetchKampaniaDataLoading: false,
        fetchCampaignIntegrationsLoading: false,
        patchCampaignGAViewLoading: false,

        opentagScripts: [],
        fetchCampaignScriptsLoading: false,

        gaGoals: [],
        fetchCampaignGoalsLoading: null,

        accountTransactions: [],

        editCampaignOpenTagLoading: false,

        cloneCampaignLoading: false,

        gaIntegrationToken: '',
        generateGaIntegrationTokenLoading: false,

        fbIntegrationToken: '',
        generateFbIntegrationTokenLoading: false,

        revokeGaLoading: false,
        revokeFbLoading: false,

        archiveCampaignLoading: false,
        archiveCampaignError: '',
        isCampaignArchived: false,
        isCampaignUpdated: false,
        isPackageUpdated: false,

        extendCampaignLoading: false,
        sucessExtendCampaign: false,
        extendCampaignError: '',
        paymanAccountId: null,
      };
    },

    resetCampaign() {
      return {
        campaign: { package: {}, kampania: {} },
      };
    },

    resetCampaignCreatives() {
      return {
        campaignCreatives: {},
        fetchCampaignCreativesLoading: false,
      };
    },

    resetEditWeight() {
      return {
        editWeightLoading: false,
        doneEditingWeight: false,
      };
    },
  },
  globalState: {
    fetchAllCampaignsLoading: 'campaignsList.fetchCampaignsLoading',
  },
};
