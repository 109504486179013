export const getQueryForMonitoringDataWithFilters = (
  alerts,
  campaignState = 'RUNNING',
  campaignSource = '*',
  id = '*',
) => {
  let query;
  if (alerts.length === 0 && campaignState) {
    query = {
      bool: {
        must: [
          {
            match: {
              campaign_state: campaignState,
            },
          },
          { wildcard: { campaign_id: id } },
          {
            wildcard: {
              campaign_source: campaignSource !== 'ALL' ? campaignSource : '*',
            },
          },
        ],
      },
    };
  } else if (alerts.length === 1 && campaignState) {
    query = {
      bool: {
        must: [
          {
            match: {
              campaign_state: campaignState,
            },
          },
          { wildcard: { campaign_id: id } },
          {
            wildcard: {
              campaign_source: campaignSource !== 'ALL' ? campaignSource : '*',
            },
          },
          {
            nested: {
              path: 'alerts',
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        'alerts.name': alerts[0],
                      },
                    },
                    {
                      match: {
                        'alerts.value': true,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    };
  } else if (alerts.length > 1 && campaignState) {
    const filterQuery = alerts.map(alert => {
      return {
        bool: {
          must: [
            {
              match: {
                'alerts.name': alert,
              },
            },
            {
              match: {
                'alerts.value': true,
              },
            },
          ],
        },
      };
    });
    query = {
      bool: {
        must: [
          {
            match: {
              campaign_state: 'RUNNING',
            },
          },
          { wildcard: { campaign_id: id } },
          {
            wildcard: {
              campaign_source: campaignSource !== 'ALL' ? campaignSource : '*',
            },
          },
          {
            nested: {
              path: 'alerts',
              query: {
                bool: {
                  should: [...filterQuery],
                },
              },
            },
          },
        ],
      },
    };
  } else if (alerts.length === 1 && !campaignState) {
    query = {
      bool: {
        must: [
          {
            wildcard: {
              campaign_source: campaignSource !== 'ALL' ? campaignSource : '*',
            },
          },
          {
            nested: {
              path: 'alerts',
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        'alerts.name': alerts[0],
                      },
                    },
                    {
                      match: {
                        'alerts.value': true,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    };
  } else if (alerts.length > 1 && !campaignState) {
    const filterQuery = alerts.map(alert => {
      return {
        bool: {
          must: [
            {
              match: {
                'alerts.name': alert,
              },
            },
            {
              match: {
                'alerts.value': true,
              },
            },
          ],
        },
      };
    });
    query = {
      bool: {
        must: [
          {
            wildcard: {
              campaign_source: campaignSource !== 'ALL' ? campaignSource : '*',
            },
          },
          {
            nested: {
              path: 'alerts',
              query: {
                bool: {
                  should: [...filterQuery],
                },
              },
            },
          },
        ],
      },
    };
  }
  return query;
};
