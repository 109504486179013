export const getLogsQuery = (campaignId, filter = []) => {
  let query;

  if (filter.length) {
    query = {
      query: {
        bool: {
          must: [
            {
              terms: {
                instascaler_id: [campaignId],
              },
            },
            {
              terms: {
                event_name: [...filter.map(f => f.value)],
              },
            },
          ],
        },
      },
      size: 10000,
    };
  } else {
    query = {
      query: {
        bool: {
          must: [
            {
              terms: {
                instascaler_id: [campaignId],
              },
            },
          ],
        },
      },
      size: 10000,
    };
  }

  return query;
};
