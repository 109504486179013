import { request } from './apiAxios';
import axios from 'axios';
import { notificationsEvent } from './helpers';

const splitQueryParams = urlParams => {
  let queryString = '';
  Object.keys(urlParams).forEach(k => {
    if (urlParams[k] !== null && urlParams[k] !== undefined) {
      queryString += `${k}=${urlParams[k]}&`;
    }
  });

  return queryString;
};

const elasticCustomerAggs = (type, emails) => {
  return {
    terms: {
      field: `${type}_email`,
      include: emails,
      order: {
        total_balance: 'desc',
      },
      size: 10000,
    },
    aggs: {
      total_balance: {
        sum: {
          field: 'balance',
        },
      },
      camapigns: {
        cardinality: {
          field: 'campaign_id',
        },
      },
    },
  };
};

const getNotificationsQuery = (campaignId, eventName, size = 1000) => {
  const query = {
    query: {
      bool: {
        must: [
          {
            terms: {
              state: ['EXPIRED', 'PENDING_PAID', 'RUNNING'],
            },
          },
          {
            terms: {
              event_name:
                eventName === 'ALL' ? notificationsEvent : [eventName],
            },
          },
        ],
      },
    },
    size,
  };

  if (campaignId) {
    query.query.bool.must.push({
      match: {
        instascaler_id: campaignId,
      },
    });
  }

  return query;
};

export const AuthApi = {
  login(userCredential) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/public/users/signin`,
      method: 'post',
      body: userCredential,
    });
  },
  getUser(token) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/me`,
      method: 'get',
      token,
    });
  },
  userImpersonate(campaignId, token) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/impersonate`,
      method: 'post',
      body: campaignId,
      token,
    });
  },
};

export const CampaignsApi = {
  getCampaignKeywords(endpoint) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
    });
  },

  getAllCampaigns(urlParams) {
    if (urlParams.state === 'ALL') delete urlParams.state;
    const URLParams = urlParams;

    Object.keys(urlParams).forEach(
      k => !urlParams[k] && urlParams[k] !== 0 && delete URLParams[k],
    );

    const params = new URLSearchParams(URLParams).toString();

    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns${
        params ? `/?${params}` : ''
      }`,
      method: 'get',
      isFullResponse: true,
    });
  },

  getReport(
    campaignId,
    startDate,
    endDate,
    groupByDay,
    cities,
    getCost = false,
    cancelToken,
  ) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v2/campaigns/report/${campaignId}?start_date=${startDate}&end_date=${endDate}${
        groupByDay ? '&group_by=day' : ''
      }${cities ? '&cities=true' : ''}&getCost=${getCost}&useReportingId=true`,
      cancelToken,
    });
  },

  getAssistedConversions(analyticsId, startDate, endDate, cancelToken) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/report/ga/${analyticsId}/mcf?start_date=${startDate}&end_date=${endDate}`,
      cancelToken,
    });
  },

  getDailyClicksAndVisitsReport({
    analyticsId,
    type,
    startDate,
    endDate,
    campaignId,
    devices,
    countries,
    utmSource,
  }) {
    if (type) {
      let params = '';
      if (type === 'visits')
        params = `${analyticsId}?type=${type}&startDate=${startDate}&endDate=${endDate}${
          devices ? `&devices=${devices}` : ''
        }${countries ? `&countries=${countries}` : ''}${
          utmSource ? `&source=${utmSource}` : '&source=instascaler'
        }`;
      else
        params = `${campaignId}?type=${type}&startDate=${startDate}&endDate=${endDate}&analyticsId=${analyticsId}`;
      return request({
        url: `${process.env.REACT_APP_API_URL}/analytics/reports/daily/${params}`,
      });
    }
  },

  getCostReport({ campaignId, analyticsId, startDate, endDate }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/reports/dailycost/${campaignId}?startDate=${startDate}&endDate=${endDate}&analyticsId=${analyticsId}`,
    });
  },

  getCampaignResults(campaignId, startDate, endDate, cancelToken) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v3/campaigns/${campaignId}/reports?${
        startDate ? `&start_date=${startDate}` : ''
      }${endDate ? `&end_date=${endDate}` : ''}`,
      cancelToken,
    });
  },

  getCampaignsStateCount() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/statesCount`,
    });
  },

  archiveCampaignById(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}`,
      method: 'delete',
    });
  },

  getCampaignCreatives(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/creatives`,
    });
  },

  getCampaignRules(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/rules`,
    });
  },

  getCampaignWorkflowRuns(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/wf-runs`,
    });
  },

  getTsCampaigns(id) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v3/icampaigns/${id}`,
    });
  },

  getTsCampaignsStatus(data) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/icampaigns/traffic_source_status`,
      method: 'post',
      body: data,
    });
  },

  toggleTSCampaign(TSCampaignId, isStart) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/kampania/api/v2/ts_campaign/${TSCampaignId}/${
        isStart ? 'start' : 'pause'
      }/`,
      method: 'post',
      body: '',
    });
  },

  getCampaignOpentagScripts(encryptedId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/scripts/${encryptedId}`,
    });
  },

  addCustomCampaign(customCampaignObj) {
    const customCampaign = JSON.stringify(customCampaignObj);
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/custom`,
      method: 'post',
      body: customCampaign,
    });
  },

  getCampaignById(campaignId, cancelToken) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}?with_user=true`,
      cancelToken,
    });
  },

  getKampaniaData(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}/kampania`,
    });
  },

  getIntegrations(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}/reports/integrations`,
    });
  },

  editCampaign({ campaignId, updatedField, isInstascalerCampaign }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}${
        isInstascalerCampaign ? '' : '?disable_intercom=true'
      }`,
      method: 'patch',
      body: updatedField,
    });
  },

  editCampaignOpentagScripts(campaignId, data, isInstascalerCampaign) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v2/campaigns/scripts/${campaignId}${
        isInstascalerCampaign ? '' : '?disable_intercom=true'
      }`,
      method: 'post',
      body: data,
    });
  },

  getPartnerList() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users/partners?with=balance&search=&`,
      method: 'get',
    });
  },

  getCampaignHistory(query) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/logbook/_search?sort=created_at:desc`,
      method: 'post',
      body: query,
    });
  },

  getCampaignNotifications(campaignId, eventName) {
    let eventsParam;

    if (eventName === 'ALL') {
      eventsParam = `event_name_in=${notificationsEvent.map((event, i) =>
        i - 1 === notificationsEvent.length ? event + ',' : event,
      )}`;
    } else {
      eventsParam = `event_name=${eventName}`;
    }

    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/logs/api/events/?ordering=-created_at&${eventsParam}&${
        campaignId ? 'instascaler_id=' + campaignId : ''
      }`,
      method: 'get',
    });
  },

  cloneCampaign(wfrunId, trafficSource) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${wfrunId}/${trafficSource}/clone/`,
      method: 'post',
      body: {},
    });
  },

  addNewPublishAccount(ts, account_name) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/traffic_source_account`,
      method: 'post',
      body: {
        traffic_source_name: ts,
        account_name,
        account_currency_code: 'USD',
      },
    });
  },

  publishCampaign(kampaniaId, trafficSource, body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/icampaigns/${kampaniaId}/${trafficSource}/publish`,
      method: 'post',
      body,
    });
  },
  schedulePublishingCampaign(body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/publish_job`,
      method: 'post',
      body,
    });
  },
  generateKeywords(instascaler_id, url) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/generate_keywords`,
      method: 'post',
      body: {
        instascaler_id,
        url,
      },
    });
  },
  getAllScheduledPublish(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/publish_job/?instascaler_id=${campaignId}`,
      method: 'get',
    });
  },
  deleteScheduledPublish(id) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/publish_job/${id}`,
      method: 'delete',
    });
  },
  getVerticals() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/google/verticals`,
      method: 'get',
    });
  },
  getTrafficSourceAccounts(trafficeSource) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v2/traffic_source_account?traffic_source_name__icontains=${trafficeSource}`,
      method: 'get',
    });
  },
  getPages(campaignId) {
    const url = `${process.env.REACT_APP_API_URL}/v2/pages/${campaignId}`;
    return request({ url });
  },
  getAudiences(trafficSource) {
    const url = `${process.env.REACT_APP_API_URL}/v2/audiences/${trafficSource}`;
    return request({ url });
  },
  extendCampaign({ campaignId, endDate, balance }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/campaigns/${campaignId}/extension?end_date=${endDate}`,
      body: { balance },
      method: 'post',
    });
  },

  getFinalUrl(URL) {
    const url = `${process.env.REACT_APP_GET_REDIRECTS_URL}?url=${URL}`;
    return axios.get(url);
  },

  editTSCampaignWeight(body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/kampania/api/v3/icampaigns/edit_traffic_source_weight/`,
      body: { ...body },
      method: 'post',
    });
  },
};

export const PackagesApi = {
  editPackage({ packageId, updatedField, isInstascalerCampaign }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/packages/${packageId}${
        isInstascalerCampaign ? '' : '?disable_intercom=true'
      }`,
      method: 'patch',
      body: updatedField,
    });
  },
};

export const UsersApi = {
  getAccount(accountId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/accounts/${accountId}`,
    });
  },

  addPartner(partnerObj) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/accounts`,
      method: 'post',
      body: JSON.stringify(partnerObj),
    });
  },

  getUsers(urlParams) {
    const queryString = splitQueryParams(urlParams);
    return request({
      url: `${process.env.REACT_APP_API_URL}/v3/users?${queryString}`,
      isFullResponse: true,
    });
  },

  getPartners(urlParams) {
    const queryString = splitQueryParams(urlParams);
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/users/partners?with=balance&${queryString}`,
      isFullResponse: true,
    });
  },

  getCampaignUserEmails(endpoint, cancelToken) {
    return request({
      url: `${process.env.REACT_APP_API_URL}${endpoint}`,
      cancelToken,
    });
  },
};

export const LocationsApi = {
  getLocationsByQuery(query) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/locations?search=${encodeURI(
        query,
      )}`,
    });
  },
};

export const IntegrationsApi = {
  generateIntegrationToken(campaignId, isInstascalerCampaign) {
    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/v3/campaigns/${campaignId}/integration/token${
        !isInstascalerCampaign ? '?requester=metadsp' : ''
      }`,
    });
  },
  revokeGa(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/revokeGA/${campaignId}`,
      method: 'delete',
    });
  },
  revokeFb(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/facebookAds`,
      method: 'delete',
    });
  },
};

export const GAApi = {
  getCampaignGoals(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/report/${campaignId}?filter=${process.env.REACT_APP_GA_DURATION}&useReportingId=true`,
    });
  },
  getCampaignGAViews(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/gaViews?keepGA=true`,
    });
  },
  patchGAView({ campaignId, viewId, webPropertyId }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/campaigns/${campaignId}/analytics`,
      method: 'patch',
      body: {
        ga: {
          profile_id: viewId,
          web_property_id: webPropertyId,
        },
      },
    });
  },

  getUTMSources({ analyticsId, startDate, endDate }) {
    const params =
      startDate && endDate ? `start_date=${startDate}&end_date=${endDate}` : ``;
    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/ga/sources/${analyticsId}?${params}`,
      method: 'GET',
    });
  },
  getUTMKeywordText({ keywordId, accountId, adgroupId }) {
    return request({
      url: `
      ${process.env.REACT_APP_API_URL}/v2/reports/utmKeywordText?keyword_id=${keywordId}&account_id=${accountId}&adgroup_id=${adgroupId}
    `,
      method: 'GET',
    });
  },
  getGAReport({ analyticsId, startDate, endDate, utmFilter, keywords }) {
    let params =
      startDate && endDate ? `startDate=${startDate}&endDate=${endDate}` : '';

    params += utmFilter ? `&source=${utmFilter}` : '&source=instascaler';
    params += keywords ? '&keywords=true' : '';

    return request({
      url: `${process.env.REACT_APP_API_URL}/analytics/report/ga/${analyticsId}?${params}`,
      method: 'GET',
    });
  },
};

export const PaymentApi = {
  getAccountTransactions(accountId, limit = 1000) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/payment/account/${accountId}/transactions?limit=${limit}`,
    });
  },

  getPaymanCost({ accountId, campaignId, startDate, endDate, cancelToken }) {
    let params = '';
    if (startDate) {
      params += `&transaction_date__gte=${startDate}`;
    }
    if (endDate) {
      params += `&transaction_date__lte=${endDate}`;
    }

    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/payman-external/api/v1/account-transaction/group_transaction?${
        accountId ? `account_id=${accountId}&` : ''
      }instascaler_campaign_id=${campaignId}&group_daily_charges=true${params}`,
      cancelToken,
    });
  },

  chargeCampaign(campaignId, amount, paymanAccountId, customerEmail, endDate) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/payman-external/api/v1/account-transaction`,
      method: 'post',
      body: {
        amount: amount * 100,
        account: `/api/v1/account/${paymanAccountId}`,
        customer_account: customerEmail,
        type: 'CHARGE',
        description: 'NewCharge',
        instascaler_campaign_id: campaignId,
        transaction_date: endDate,
      },
    });
  },
};

export const UploadApi = {
  generatePresignedUrl(campaignId, urlParams = {}, urlMethod = 'put') {
    const queryString = splitQueryParams(urlParams);

    return request({
      url: `${process.env.REACT_APP_API_URL}/v2/upload/generatepresignedurl/${campaignId}/method/${urlMethod}Object?${queryString}`,
      method: 'get',
    });
  },
};

export const NotificationsApi = {
  sendNotification(campaignId, body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/notify/issue?campaign_id=${campaignId}`,
      method: 'post',
      body: {
        ...body,
      },
    });
  },
  getIssuesNotificationsHistory(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/logbook/_search?sort=created_at:desc`,
      method: 'post',
      body: {
        query: {
          bool: {
            must: [
              { terms: { instascaler_id: [campaignId] } },
              { terms: { event_name: ['SEMRUSH_ISSUE_NOTIFICATION'] } },
            ],
          },
        },
        size: 10000,
      },
    });
  },
};

export const BayonetApi = {
  getMonitoringRules() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/equations/?name__startswith=monitoring`,
      method: 'get',
    });
  },
  EditMonitoringRule(ruleId, newRule) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/equations/${ruleId}/`,
      method: 'put',
      body: {
        formula: newRule,
      },
    });
  },
};

export const ElasticSearch = {
  getCampaignsMonitoring(URLSearchParams) {
    const queryString = splitQueryParams(URLSearchParams);
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/campaigns/doc/_search?${queryString}`,
      method: 'get',
    });
  },
  getCampaignsMonitoringWithFilter(URLSearchParams, query) {
    const queryString = splitQueryParams(URLSearchParams);
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/campaigns/doc/_search?${queryString}`,
      method: 'post',
      body: {
        query,
      },
    });
  },
  getNotifications(campaignId, eventName, size) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/logbook/_search?sort=created_at:desc`,
      method: 'post',
      body: getNotificationsQuery(campaignId, eventName, size),
    });
  },
  getCustomerBalance(emails) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/customer-balance/_search`,
      method: 'post',
      body: {
        size: 0,
        aggs: {
          group_by_customer: elasticCustomerAggs('customer', emails),
          group_by_partner: elasticCustomerAggs('partner', emails),
        },
      },
    });
  },

  getCustomerCampaignsBalance(email) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/search/customer-balance/_search?size=10000`,
      method: 'post',
      body: {
        query: {
          multi_match: {
            query: email,
            fields: ['partner_email', 'customer_email'],
          },
        },
      },
    });
  },

  refreshBayonet(campaignId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/analytics/campaign/?campaign_ids=${campaignId}`,
      method: 'get',
    });
  },

  refreshMonitoring() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/schedular_task/run/monitoring/`,
      method: 'post',
    });
  },

  checkMonitoring() {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/schedular_task/check/monitoring/`,
      method: 'get',
    });
  },

  getMonitoringStatus(id) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/schedular_task/?task_id=${id}`,
      method: 'get',
    });
  },
};

export const commentsApi = {
  addComment(body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/comments/`,
      method: 'post',
      body,
    });
  },

  editComment(commentId, body) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/comments/${commentId}/`,
      method: 'put',
      body,
    });
  },

  deleteComment(commentId) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/comments/${commentId}/`,
      method: 'delete',
    });
  },

  getCampaignComments(campaignIds) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/bayonet-external/api/v1/comments/?campaign_id__in=${campaignIds}`,
      method: 'get',
    });
  },
};

export const KeywordsApi = {
  getKeywords({ campaign_id, domain, source, keywords_seeds, countries }) {
    const limit = source === 'google' ? 700 : source === 'semrush' ? 500 : '';

    return request({
      url: `${
        process.env.REACT_APP_API_URL
      }/keeper-external/api/v1/keywords/keyword_research/${campaign_id}?source=${source}&countries=${countries}${
        limit ? '&display_limit=' + limit : ''
      }&${domain ? 'domain=' + domain : ''}${
        keywords_seeds ? 'keywords_seeds=' + keywords_seeds : ''
      }`,
      method: 'get',
    });
  },

  getSelectedKeywords({ campaign_id, source }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/keeper-external/api/v1/keywords/get_selected_keywords/${campaign_id}?source=${source}`,
      method: 'get',
    });
  },

  selectKeywords({ campaign_id, body }) {
    return request({
      url: `${process.env.REACT_APP_API_URL}/keeper-external/api/v1/keywords/save_keywords/${campaign_id}`,
      method: 'post',
      body,
    });
  },
};
