import { Icon as LegacyIcon } from '@ant-design/compatible';
import React, { useEffect, useState } from 'react';
import {
  PauseCircleOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import {
  initialColumns,
  getTrafficSourceName,
  getBaseTrafficSourceName,
} from './tableHelpers';
import CustomizedTable from 'components/CustomizedTable';
import { TRAFFIC_SOURCES } from 'utils/constants';
import theme from 'styles/themes';

import { DivFlexSpaceBetween } from 'styles/styledComponents';
import EditableField from '../../../../components/EditableField';
import { InputNumber, Popconfirm } from 'antd';

const WfRunsTable = ({
  campaign,
  campaignWorkflowRuns,
  tsCampaignsReport,
  accountTransactions,
  cloneCampaign,
  cloneCampaignLoading,
  editTSCampaignWeight,
  editWeightLoading,
  doneEditingWeight,
  toggleTSCampaign,
  toggleTSCampaignLoading,
  getTsCampaignsStatus,
  tsCampaignsStatus,
  failedToFetchStatus,
  shouldRefreshTable,
  setShouldRefreshTable,
}) => {
  const [selectedWfRun, setSelectedWfRun] = useState('');
  const [selectedTSCampaign, setSelectedTSCampaign] = useState('');
  const [weight, setWeight] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [updatedDataTable, setUpdatedDataTable] = useState(false);

  useEffect(() => {
    if (tableData.length === 0) {
      setTableData(getTableData());
    }
  }, [tableData.length]); //eslint-disable-line

  useEffect(() => {
    if (shouldRefreshTable) {
      setTableData(getTableData());

      return setShouldRefreshTable(false);
    }
  }, [shouldRefreshTable]) //eslint-disable-line

  useEffect(() => {
    if (doneEditingWeight) {
      setEditMode(false);
    }
    return () => setEditMode(null);
  }, [doneEditingWeight]);

  useEffect(() => {
    if (
      updatedDataTable === false &&
      tableData.length > 0 &&
      Object.keys(tsCampaignsStatus).length > 0
    ) {
      const updatedTableData = [...tableData];
      updatedTableData.map(
        td =>
          (td.traffic_source_status =
            (tsCampaignsStatus[td.traffic_source] &&
              tsCampaignsStatus[td.traffic_source][td.campaign_id]) ||
            ''),
      );
      setUpdatedDataTable(true);
      setTableData(updatedTableData);
    }
  }, [tableData, tsCampaignsStatus, updatedDataTable]);

  const accumulateChargeTransactions = wfRunCampaignId => {
    const { _id: campaignId } = campaign;

    const instascalerCampaignId = campaignId?.toString();

    return accountTransactions.reduce((acc, transaction) => {
      const transactionInstascalerCampaignId = transaction?.instascaler_campaign_id?.toString();

      const transactionTSCampaignId = transaction?.traffic_source_campaign_id?.toString();

      const instascalerCampaignRelatedTransaction =
        transactionInstascalerCampaignId === instascalerCampaignId;
      const tsCampaignRelatedTransaction =
        transactionTSCampaignId === wfRunCampaignId;

      if (
        transaction.type === 'CHARGE' &&
        instascalerCampaignRelatedTransaction
      ) {
        if (
          (wfRunCampaignId && tsCampaignRelatedTransaction) ||
          !wfRunCampaignId
        ) {
          return acc + (transaction.amount || 0);
        }
      }
      return acc;
    }, 0);
  };

  const getTableData = () => {
    const {
      package: { balance, abm },
    } = campaign;

    const maxAllowedBudget = Number((balance * abm).toFixed(2));

    const modifiedWorkFlowRunsTableData = [];

    const tsObjects = [];

    TRAFFIC_SOURCES.forEach(ts => {
      const modTsCampaignsReport = tsCampaignsReport?.[ts]?.map(c => {
        return { ...c, ts };
      });

      return modTsCampaignsReport && tsObjects?.push(...modTsCampaignsReport);
    });
    campaignWorkflowRuns.forEach(wfRun => {
      const tsCampaignObjId = wfRun?.ts_campaign_id;
      const consumedBudgetForThiswfRun = accumulateChargeTransactions(
        tsCampaignObjId?.toString(),
      );
      const tsCampaigns = tsCampaignsReport?.ts_campaigns;
      const tsCampaignInfo = tsCampaigns?.find(
        tsCampaign => tsCampaign.id === tsCampaignObjId,
      );

      const totalCost = Number(tsCampaignInfo?.last_consumed_cost).toFixed(2);
      const totalCostDisplayed = totalCost === 'NaN' ? 'N/A' : totalCost;

      const [nativeTsCampaignId, campaignState] = getNativeTsCampaignIdAndState(
        wfRun?.wf_run_id,
        tsObjects,
      );

      modifiedWorkFlowRunsTableData.push({
        ...wfRun,
        traffic_source: getTrafficSourceName(wfRun.workflow_name).source,
        weight: (Number(wfRun.weight) * 100).toFixed(2),
        assigned_budget: (Number(wfRun.weight) * maxAllowedBudget).toFixed(2),
        consumed_budget: consumedBudgetForThiswfRun,
        key: wfRun.wf_run_id,
        actual_cost: totalCostDisplayed,
        campaign_id: nativeTsCampaignId,
        is_active: campaignState,
      });
    });

    const data = groupByTrafficSource(modifiedWorkFlowRunsTableData);

    if (
      (Object.keys(tsCampaignsStatus).length === 0 && !failedToFetchStatus) ||
      shouldRefreshTable
    ) {
      getTsCampaignsStatus(data);
    }
    return modifiedWorkFlowRunsTableData;
  };

  const groupByTrafficSource = workFlowRuns => {
    const data = {};
    workFlowRuns.forEach(wf => {
      if (data[wf.traffic_source] !== undefined) {
        data[wf.traffic_source].push(wf.campaign_id);
      } else {
        data[wf.traffic_source] = [];
        data[wf.traffic_source].push(wf.campaign_id);
      }
    });
    return data;
  };

  const getNativeTsCampaignIdAndState = (wfId, tsObjects) => {
    const tsCampaignObj = tsObjects?.find(campaign => {
      const tsWfId = Number(campaign?.wf_run?.match(/(\d){4,5}/g)?.[0]);

      return tsWfId === wfId;
    });

    const id = tsCampaignObj?.[`${tsCampaignObj?.ts}_campaign_id`];

    const state = tsCampaignObj?.is_active_campaign;

    return [id, state];
  };

  return (
    <CustomizedTable
      hasMinWidth
      data={tableData}
      columns={[
        ...initialColumns.map(col => {
          if (col.key === 'is_active') {
            return {
              dataIndex: 'is_active',
              key: 'is_active',
              width: 50,
              render: (date, { ts_campaign_id, traffic_source_status }) => {
                const isLoading =
                  toggleTSCampaignLoading &&
                  selectedTSCampaign === ts_campaign_id;

                return (
                  <>
                    {traffic_source_status === 'Running' ? (
                      <Popconfirm
                        title="Are you sure you want to pause this campaign?"
                        onConfirm={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedTSCampaign(ts_campaign_id);
                          toggleTSCampaign(ts_campaign_id);
                        }}
                        okText="Pause"
                        cancelText="CANCEL"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <LoadingOutlined
                            style={{ fontSize: 25, marginRight: 10 }}
                          />
                        ) : (
                          <PauseCircleOutlined
                            style={{
                              fontSize: 25,
                              color: theme.colors.orange,
                              marginRight: 10,
                            }}
                          />
                        )}
                      </Popconfirm>
                    ) : (
                      <Popconfirm
                        title="Are you sure you want to resume this campaign?"
                        onConfirm={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedTSCampaign(ts_campaign_id);
                          toggleTSCampaign(ts_campaign_id, true);
                        }}
                        okText="Resume"
                        cancelText="CANCEL"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <LoadingOutlined style={{ fontSize: 25 }} />
                        ) : (
                          <PlayCircleOutlined
                            style={{ fontSize: 25, color: theme.colors.green }}
                          />
                        )}
                      </Popconfirm>
                    )}
                  </>
                );
              },
            };
          }
          if (col.key === 'workflow_name') {
            return {
              title: 'Traffic Source',
              dataIndex: 'workflow_name',
              key: 'workflow_name',
              width: 220,
              render: (workFlowName, { wf_run_id: workFlowRunId }) => {
                const baseTrafficSource = getBaseTrafficSourceName(
                  workFlowName,
                );
                const cloneCampaignForWfRunLoading =
                  cloneCampaignLoading && selectedWfRun === workFlowRunId;
                return (
                  <DivFlexSpaceBetween>
                    {getTrafficSourceName(workFlowName).name}
                    {baseTrafficSource === 'google' && (
                      <Popconfirm
                        title="Are you sure clone this campaign?"
                        onConfirm={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedWfRun(workFlowRunId);
                          cloneCampaign(workFlowRunId, baseTrafficSource);
                        }}
                        okText="Clone"
                        cancelText="CANCEL"
                      >
                        <LegacyIcon
                          style={{ marginLeft: 8, fontSize: 17 }}
                          type={
                            cloneCampaignForWfRunLoading ? 'loading' : 'copy'
                          }
                        />
                      </Popconfirm>
                    )}
                  </DivFlexSpaceBetween>
                );
              },
            };
          }
          if (col.key === 'assigned_budget_percent') {
            return {
              title: 'ASSIGNED BUDGET PERCENTAGE',
              dataIndex: 'weight',
              key: 'assigned_budget_percent',
              width: 200,
              render: (value, { wf_run_id: workFlowRunId }) => {
                const newWeight = weight || value;

                return (
                  <EditableField
                    editMode={editMode}
                    value={`${value}%`}
                    disableSaveButton={!weight || weight === Number(value)}
                    onSave={() => {
                      editTSCampaignWeight(newWeight, workFlowRunId);
                      setWeight(null);
                    }}
                    onCancel={() => {
                      setWeight(null);
                    }}
                    loadingEdit={editWeightLoading}
                  >
                    <InputNumber
                      placeholder="Weight"
                      defaultValue={value}
                      onChange={setWeight}
                      data-test={`weight-input`}
                      max={100}
                    />
                  </EditableField>
                );
              },
            };
          }
          return col;
        }),
      ]}
    />
  );
};

export default WfRunsTable;
