import React from 'react';
import { Button } from 'antd';
import { HeaderContainerStyle } from './style';

const PageHeader = props => {
  return (
    <HeaderContainerStyle>
      <h1>{props.title}</h1>
      {props.children}
      {props.btnText && (
        <Button
          data-test={props.btnTestId}
          type="primary"
          onClick={props.btnOnChange}
        >
          {props.btnText}
        </Button>
      )}
    </HeaderContainerStyle>
  );
};

export default PageHeader;
