import { Tooltip } from 'antd';
import React from 'react';

import { Circle } from './styles';

const StatusCircle = ({ label, noLabel, title, color, ...props }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        width: 'fit-content',
      }}
      {...props}
    >
      <Tooltip title={title}>
        <Circle color={color} />
      </Tooltip>
      {noLabel ? null : <span>{label || title}</span>}
    </div>
  );
};

export default StatusCircle;
