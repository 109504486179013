import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Progress, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

import {
  KeyContainerStyle,
  RowContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';

const GoogleIntegrationPanel = ({
  campaign,
  integrationToken,
  generateIntegrationToken,
  getTokenLoading,
  getGAViewsLoading,
  patchGAViewLoading,
  isIntegrated,
  showIntegrationLink,
  fetchCampaignGoalsLoading,
  patchGAView,
  goals,
  views,
}) => {
  const [selectedGAViewId, setSelectedGAViewId] = useState(
    campaign?.google_analytics?.profile_id,
  );
  const [selectedViewName, setSelectedViewName] = useState('');
  const [isSelectMode, setSelectMode] = useState(false);

  useEffect(() => {
    if (integrationToken) {
      const integrationLink = getIntegrationLink();
      showIntegrationLink({
        title: 'Google Integration Link',
        link: integrationLink,
      });
    }
  }, [integrationToken]); //eslint-disable-line

  useEffect(() => {
    const updatedProfileId = campaign?.google_analytics?.profile_id;

    setSelectedGAViewId(updatedProfileId);

    const selectedViewObject = views?.find(
      view => view.id === updatedProfileId,
    );

    if (selectedViewObject) {
      setSelectedViewName(getViewName(selectedViewObject));
    }

    return () => {
      setSelectedGAViewId('');
      setSelectedViewName('');
    };
  }, [views, campaign]); //eslint-disable-line

  const getIntegrationLink = () => {
    const {
      REACT_APP_GA_URL,
      REACT_APP_GA_EXTERNAL_REDIRECT_URL,
    } = process.env;

    if (integrationToken) {
      return `${REACT_APP_GA_URL}&redirect_uri=${REACT_APP_GA_EXTERNAL_REDIRECT_URL}&state=${integrationToken}`;
    }
  };

  const renderGoals = () => {
    if (goals.length === 0) {
      return 'No goals';
    }

    return goals
      .filter(goal => typeof goal.conversion === 'number')
      .map((goal, i) => (
        <div key={i}>
          <div>{goal.name}</div>
          <Progress percent={goal.conversion} />
        </div>
      ));
  };

  const onPatchView = view => {
    Modal.confirm({
      title: 'Are you sure you want to change view?',
      content: getViewName(view),
      width: 700,
      onOk: () =>
        patchGAView({
          viewId: view.id,
          webPropertyId: view.web_property_id,
        }),
      okText: 'Yes',
    });
  };

  const integrationStatus = isIntegrated ? 'Integrated' : 'Not Integrated';

  const getViewName = view =>
    `${view.id}: ${view.name} - ${view.web_property_name} - ${view.web_property_id}`;

  const loadingIcon = <LoadingOutlined style={{ marginLeft: 10 }} spin />;

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Status</KeyContainerStyle>
        <ValueContainerStyle data-test="googleIntegrationStatus">
          {integrationStatus}
        </ValueContainerStyle>
      </RowContainerStyle>
      {isIntegrated ? (
        <>
          <RowContainerStyle hide={views.length !== 0 && isSelectMode}>
            <KeyContainerStyle>Views</KeyContainerStyle>
            <ValueContainerStyle>
              <Button
                type="primary"
                loading={getGAViewsLoading}
                onClick={() => {
                  setSelectMode(true);
                }}
              >
                {selectedViewName}
              </Button>
            </ValueContainerStyle>
          </RowContainerStyle>

          <RowContainerStyle hide={!isSelectMode || views.length === 0}>
            <KeyContainerStyle>Views</KeyContainerStyle>
            <ValueContainerStyle>
              <Dropdown
                overlay={
                  <Menu>
                    {views.map(view => (
                      <Menu.Item
                        key={view.id}
                        disabled={view.id === selectedGAViewId}
                        onClick={() => onPatchView(view)}
                      >
                        {getViewName(view)}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button loading={getGAViewsLoading}>
                  {selectedViewName}
                  <DownOutlined />
                </Button>
              </Dropdown>
              {patchGAViewLoading && <Spin indicator={loadingIcon} />}
            </ValueContainerStyle>
          </RowContainerStyle>

          <RowContainerStyle lastRow>
            <KeyContainerStyle>Goals</KeyContainerStyle>
            <ValueContainerStyle>
              {fetchCampaignGoalsLoading ? (
                <LoadingOutlined spin />
              ) : (
                renderGoals()
              )}
            </ValueContainerStyle>
          </RowContainerStyle>
        </>
      ) : (
        <RowContainerStyle lastRow>
          <KeyContainerStyle>Integrate</KeyContainerStyle>
          <ValueContainerStyle>
            <Button
              type="primary"
              onClick={() => generateIntegrationToken('google')}
              loading={getTokenLoading}
            >
              Generate Public Link
            </Button>
          </ValueContainerStyle>
        </RowContainerStyle>
      )}
    </>
  );
};

export default GoogleIntegrationPanel;
