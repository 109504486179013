import styled from 'styled-components';

export const ActionsBoxStyle = styled.div`
  border: 1px solid;
  padding: 0.2rem;
  top: 1.1rem;
  position: absolute;
  right: 0.5rem;
  background: #fff;
`;

export const ActionSquareStyle = styled.div`
  display: grid;
  grid-template-columns: 1rem 1rem;
  grid-template-rows: 1rem 1rem;
  grid-gap: 0.2rem;
  padding: 0.1rem;
`;

export const FullIntegrationBoxesStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  & i {
    display: flex;
    align-items: center;
  }
`;
