const Validation = {
  /**
   * validate that value is a percentage value
   */
  weight: {
    validator: (rule, value, cb) => {
      const isValid = Number(value) >= 0 && Number(value) <= 100;
      return isValid ? cb() : cb(true);
    },
    message: 'Please enter a number between 0 and 100!',
  },

  /**
   * validate that field is required
   */
  required: {
    required: true,
    message: 'This field is required!',
  },

  /**
   * validate website input field
   */
  website: {
    type: 'url',
    message: 'Wrong format!',
  },

  /**
   * validate email input field
   */
  email: {
    type: 'email',
    message: 'Wrong format!',
  },

  /**
   * validate percentage
   */

  percentage: {
    validator: (rule, value, cb) =>
      value >= 0 && value <= 100 ? cb() : cb(true),
    message: 'Please enter a correct percentage',
  },

  /**
   * validate VolummID input field
   */
  volummId: {
    validator: (rule, value, cb) => {
      const regExp = /^([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+$/;
      return regExp.test(value) ? cb() : cb(true);
    },
    message: 'Volumm ID only accept alphanumeric and "-"!',
  },

  url: {
    validator: (rule, value, cb) => {
      const regExp = /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})([\/\w \.-]*)*\/?$/; // eslint-disable-line
      return regExp.test(value) ? cb() : cb(true);
    },
    message: 'You should write a url.',
  },

  descriptionLimit: {
    max: 90,
  },
  headlineLimit: {
    max: 30,
  },
  facebookHeadlineLimit: {
    max: 255,
  },
  facebookTextLimit: {
    max: 1024,
  },
  facebookDescriptionLimit: {
    max: 5000,
  },
};

export default Validation;
