import React from 'react';
import { convertSecondsToHHMMSS } from 'utils/helpers';

export default {
  columns: [
    {
      title: 'UTM',
      children: [
        {
          title: 'UTM Term',
          dataIndex: 'ga:keyword',
          className: 'long-text',
          width: 250,
          key: 'ga:keyword',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a['ga:keyword'] - b['ga:keyword'],
        },
        {
          title: 'UTM Medium',
          dataIndex: 'ga:medium',
          className: 'long-text',
          width: 250,
          key: 'ga:medium',
          sorter: (a, b) => a['ga:medium'] - b['ga:medium'],
        },
      ],
    },
    {
      title: 'Acquisition',
      children: [
        {
          title: 'Sessions',
          dataIndex: 'ga:sessions',
          width: 250,
          key: 'ga:sessions',
          sorter: (a, b) => a['ga:sessions'] - b['ga:sessions'],
        },
        {
          title: 'New Sessions Percentage',
          dataIndex: 'ga:percentNewSessions',
          width: 250,
          className: 'short-text',
          render: perc => <span> {Math.round(perc * 100) / 100} </span>,
          key: 'ga:percentNewSessions',
          sorter: (a, b) =>
            a['ga:percentNewSessions'] - b['ga:percentNewSessions'],
        },
        {
          title: 'New Users',
          dataIndex: 'ga:newUsers',
          key: 'ga:newUsers',
          width: 250,
          sorter: (a, b) => a['ga:newUsers'] - b['ga:newUsers'],
        },
      ],
    },
    {
      title: 'Behavior',
      children: [
        {
          title: 'Bounce Rate',
          dataIndex: 'ga:bounceRate',
          render: perc => <span> {Math.round(perc * 100) / 100} </span>,
          key: 'ga:bounceRate',
          width: 250,
          sorter: (a, b) => a['ga:bounceRate'] - b['ga:bounceRate'],
        },
        {
          title: 'Pages/Session',
          dataIndex: 'ga:pageviewsPerSession',
          render: perc => <span> {Math.round(perc * 100) / 100} </span>,
          key: 'ga:pageviewsPerSession',
          width: 250,
          sorter: (a, b) =>
            a['ga:pageviewsPerSession'] - b['ga:pageviewsPerSession'],
        },
        {
          title: 'Average Session Duration',
          dataIndex: 'ga:avgSessionDuration',
          className: 'short-text',
          width: 250,
          render: perc => <span> {convertSecondsToHHMMSS(perc)} </span>,
          key: 'ga:avgSessionDuration',
          sorter: (a, b) =>
            a['ga:avgSessionDuration'] - b['ga:avgSessionDuration'],
        },
      ],
    },
  ],
};
