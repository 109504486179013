import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { COOKIES_AUTHORIZATION } from 'utils/constants';

import { pathHomePage } from './routes';

const cookies = new Cookies();

const NotLoggedInRoute = props => {
  const isLoggedIn = cookies.get(COOKIES_AUTHORIZATION);

  const { component: Page, ...rest } = props;

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? <Redirect to={pathHomePage} /> : <Page {...props} />
      }
    />
  );
};

export default NotLoggedInRoute;
