import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import Cookies from 'universal-cookie';
import { Link, useHistory } from 'react-router-dom';
import { UserOutlined, BellOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Layout,
  Avatar,
  Menu,
  Button,
  Dropdown,
  Badge,
  Spin,
  notification,
  Tooltip,
} from 'antd';
import { connect } from 'speedux';
import moment from 'moment';

import { pathAllNotifications, pathRoot } from 'routes/routes';
import {
  AUTH_EXPIRATION_PERIOD,
  COOKIES_AUTHORIZATION,
  COOKIES_NOTIFICATION_LAST_CHK,
  FULL_DATE_FORMAT,
} from 'utils/constants';
import Statistics from '../Statistics';
import {
  HeaderContainerStyle,
  StatisticsContainerStyle,
  NotificationsContainerStyle,
  NotificationHeaderStyle,
  NotificationItemStyle,
  NotificationItemsContainerStyle,
  NotificationFooterStyle,
} from './style';
import { DivFlexSpaceBetween } from 'styles/styledComponents';
import Module from './module';
import { getNotificationBody } from 'utils/helpers';

const cookies = new Cookies();
const { Header } = Layout;

const TopNav = props => {
  const history = useHistory();
  const [lastChkNotificationOn, setLastChkNotificationOn] = useState(
    cookies.get(COOKIES_NOTIFICATION_LAST_CHK),
  );

  const refreshJobId = props?.state?.refreshJobId;
  const refreshMonitoringLoading = props?.state?.refreshMonitoringLoading;

  useEffect(() => {
    if (refreshJobId) {
      const getStatus = () => {
        props?.actions?.getMonitoringStatus(); //eslint-disable-line
      };

      setInterval(getStatus, 5000);

      return () => clearInterval(getStatus);
    }
  }, [refreshJobId]); //eslint-disable-line

  useEffect(() => {
    props.actions.getUser();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (process.env.REACT_APP_SOCKET_URL && props?.state?.user?.email) {
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);

      socket.emit('identify', {
        userid: props?.state?.user?.email,
        send_history: false,
      });

      socket.on('message', msg => {
        notification.info({
          message: `Notification!`,
          description: msg,
          placement: 'bottomRight',
          duration: null,
        });
      });
    }
  }, [props?.state?.user?.email]); //eslint-disable-line

  useEffect(() => {
    props.actions.getCampaignNotifications();

    return () => {
      props.actions.resetNotifications();
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (
      !props.state.campaignsStateCount.length ||
      props?.state?.updateCampaignsCount
    ) {
      props.actions.getCampaignsStateCount();
    }
  }, [props.state.campaignsStateCount, props.state.updateCampaignsCount]); //eslint-disable-line

  const handleLogout = e => {
    e.preventDefault();
    cookies.remove(COOKIES_AUTHORIZATION);
    history.push(pathRoot);
  };

  const setLastNotificationsCheck = willBeVisible => {
    if (!willBeVisible) {
      cookies.set(COOKIES_NOTIFICATION_LAST_CHK, moment.utc(), {
        maxAge: AUTH_EXPIRATION_PERIOD,
      });
      setLastChkNotificationOn(cookies.get(COOKIES_NOTIFICATION_LAST_CHK));
      props.actions.resetCount();
    }
  };

  const profileMenu = (
    <Menu>
      <Menu.Item>
        <Button data-test="logout-btn" type="link" onClick={handleLogout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const notifications = (
    <NotificationsContainerStyle>
      <NotificationHeaderStyle>notifications</NotificationHeaderStyle>
      <Spin spinning={props?.state?.getNotificationsLoading}>
        <NotificationItemsContainerStyle key={lastChkNotificationOn}>
          {props?.state?.notifications.map(singleNotification => {
            const notification = singleNotification?._source;
            const notificationBody = getNotificationBody(notification);
            const notificationDate = moment.utc(notificationBody?.date);
            const lastChkDate = moment.utc(lastChkNotificationOn);

            const newMsg = notificationDate.isAfter(lastChkDate);

            return (
              <NotificationItemStyle
                key={notificationBody?.campaignId + notificationBody.date}
                color={notificationBody?.color}
                newMsg={newMsg}
              >
                <div>{notificationBody?.msg}</div>
                <div>
                  {moment.utc(notificationBody?.date).format(FULL_DATE_FORMAT)}
                </div>
              </NotificationItemStyle>
            );
          })}
        </NotificationItemsContainerStyle>
      </Spin>
      <NotificationFooterStyle>
        <Link to={pathAllNotifications}>see all</Link>
      </NotificationFooterStyle>
    </NotificationsContainerStyle>
  );

  return (
    <Header style={{ background: '#fff', padding: 0 }}>
      <HeaderContainerStyle hasState={props.state.campaignsStateCount.length}>
        {props.state.campaignsStateCount.length ? (
          <StatisticsContainerStyle>
            <Statistics data={props.state.campaignsStateCount} />
          </StatisticsContainerStyle>
        ) : (
          ''
        )}
        <DivFlexSpaceBetween>
          {refreshMonitoringLoading ? (
            <Tooltip title="Refresh Monitoring Data">
              <LoadingOutlined
                style={{
                  fontSize: '30px',
                }}
              />
            </Tooltip>
          ) : (
            ''
          )}

          <Dropdown
            overlay={notifications}
            trigger={['click']}
            onVisibleChange={e => setLastNotificationsCheck(e)}
          >
            <Badge count={props?.state?.newMsgCount} offset={[-5, 5]}>
              <BellOutlined
                style={{
                  fontSize: '30px',
                  marginLeft: '30px',
                }}
              />
            </Badge>
          </Dropdown>

          <Dropdown overlay={profileMenu} trigger={['click']}>
            <Avatar
              data-test="profile-settings"
              size="large"
              icon={<UserOutlined />}
              style={{
                marginLeft: '30px',
              }}
            />
          </Dropdown>
        </DivFlexSpaceBetween>
      </HeaderContainerStyle>
    </Header>
  );
};

export default connect(TopNav, Module);
