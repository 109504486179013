import React from 'react';
import CustomizedTable from 'components/CustomizedTable';

const RulesTable = ({ rules }) => {
  const tableColumns = [
    {
      title: 'RULES ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'RULE NAME',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'EQUATION FORMULA',
      dataIndex: ['equation', 'formula'],
      key: 'equation.formula',
    },
    {
      title: 'RULE TYPE',
      dataIndex: 'is_general_rule',
      key: 'is_general_rule',
      width: 150,
      render: isGeneralRule => (
        <span>{isGeneralRule ? 'General' : 'Specific'}</span>
      ),
    },
  ];
  const tableData = rules.map(rule => ({ ...rule, key: rule.id }));
  return (
    <>
      {rules.length > 0 && (
        <CustomizedTable hasMinWidth columns={tableColumns} data={tableData} />
      )}
    </>
  );
};

export default RulesTable;
