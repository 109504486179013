import AppLayout from 'components/AppLayout';
import Campaigns from 'pages/Campaigns';
import Login from 'pages/Login';
import Customers from 'pages/Customers';
import Notifications from 'pages/Notifications';
import Monitoring from 'pages/Monitoring';
import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import NotLoggedInRoute from './NotLoggedInRoute';
import RootRedirectRoute from './RootRedirectRoute';
import {
  pathAllCampaigns,
  pathLogin,
  pathCustomers,
  pathRoot,
  pathAllNotifications,
  pathMonitoring,
} from './routes';
import Cookies from 'universal-cookie';
import { COOKIES_AUTHORIZATION } from 'utils/constants';

const cookies = new Cookies();
const Router = () => {
  const isAuthorized = cookies.get(COOKIES_AUTHORIZATION);
  if (!isAuthorized) {
    const tokenKey = 'jwtToken=';
    const indexOfToken = window.location.search.indexOf(tokenKey);

    if (indexOfToken > 0) {
      const valueOfToken = window.location.search.slice(
        indexOfToken + tokenKey.length,
      );
      cookies.set(COOKIES_AUTHORIZATION, decodeURI(valueOfToken));
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        <RootRedirectRoute exact path={pathRoot} />
        <NotLoggedInRoute exact path={pathLogin} component={Login} />
        <AppLayout>
          <AuthorizedRoute path={pathMonitoring} component={Monitoring} />
          <AuthorizedRoute path={pathAllCampaigns} component={Campaigns} />
          <AuthorizedRoute path={pathCustomers} component={Customers} />
          <AuthorizedRoute
            path={pathAllNotifications}
            component={Notifications}
          />
        </AppLayout>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
