import styled from 'styled-components';
import { MOB_SCREEN_WIDTH } from 'utils/constants';
import theme from 'styles/themes';

export const DivFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DivFlexFlexEnd = styled(DivFlexSpaceBetween)`
  justify-content: flex-end;
`;

export const DivFlexCenterCenter = styled(DivFlexSpaceBetween)`
  justify-content: center;
`;

export const CardDefaultDivStyle = styled.div`
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.1);
`;

export const SliderStyle = styled.div`
  .ant-legacy-form-item-children {
    display: flex;
    flex-direction: column;
  }

  .ant-input-number {
    width: 90px;
  }

  .customized-slider-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
`;

export const FormItemFlexGrow = styled.div`
  width: 100%;

  .ant-legacy-form {
    flex-grow: 1;
  }

  .ant-legacy-form-item-control-wrapper {
    flex-grow: 1;
  }

  .ant-legacy-form-item-control {
    flex-grow: 1;
  }
`;

export const CircleStyle = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 3px solid ${theme.colors.main};
  cursor: pointer;
  border-radius: 50%;
`;

export const Circle = styled.span`
  background: ${props => props.color || 'rgb(189, 113, 0)'};
  display: inline-block;
  width: 1rem;
  max-width: 1rem;
  min-width: 1rem;
  height: 1rem;
  max-height: 1rem;
  min-height: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const FormContainerStyle = styled.div`
  dt {
    min-width: 18.5rem;
  }
  .ant-legacy-form-item {
    display: flex;
    justify-content: flex-end;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: ${MOB_SCREEN_WIDTH}px) {
      flex-direction: column;
    }
  }

  .ant-legacy-form-item-control {
    & > span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .ant-legacy-form-item-control-wrapper {
    flex-grow: 1;
  }

  .ant-picker {
    flex-grow: 1;
  }

  .ant-legacy-form-item-label {
    min-width: 18.5rem;
    text-align: left;
  }

  .ant-card {
    margin-bottom: 2rem;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .ant-card-head-title {
    text-transform: uppercase;
  }
`;

export const SearchHeaderContainerStyle = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
`;
