import React from 'react';
import { Input, Spin, Button, Form } from 'antd';

const { TextArea } = Input;

const ManualKeywordTab = ({
  campaign_id,
  selectKeywords,
  saveLoading,
  source,
  loading,
  selectedKeywords,
}) => {
  const [form] = Form.useForm();

  const addToCampaign = values => {
    const keywords = values.keywords.split('\n');

    selectKeywords({
      campaign_id,
      source,
      keywords: [...selectedKeywords, ...keywords],
    });

    form.resetFields();
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="addToCampaign"
        form={form}
        onFinish={addToCampaign}
        style={{
          textAlign: 'right',
        }}
      >
        <Form.Item
          label="Keywords"
          name="keywords"
          rules={[
            { required: true, message: 'Please input at least one keyword!' },
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Enter your keywords, one keyword per line!"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={saveLoading}>
          Add To Campaign
        </Button>
      </Form>
    </Spin>
  );
};

export default ManualKeywordTab;
