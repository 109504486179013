import { LAPTOP_SCREEN_WIDTH } from 'utils/constants';
import styled from 'styled-components';

export const ViewModeContainerStyle = styled.div`
  display: flex;
  min-width: 50%;
  align-items: center;
  justify-content: space-between;

  & .anticon {
    cursor: pointer;
  }
`;

export const EditModeContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;

  & .ant-btn {
    margin-right: 1rem;
  }
  & .ant-legacy-form-item {
    margin-bottom: 0;
  }
`;

export const ButtonsContainerStyle = styled.div`
  display: flex;
  margin-top: 1rem;
  @media (max-width: ${LAPTOP_SCREEN_WIDTH}px) {
    margin-top: 1rem;
  }

  & .ant-btn-primary {
    margin-right: 0;
  }
`;

export const ShownValueStyle = styled.span`
  max-width: 80%;
`;
