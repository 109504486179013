import styled from 'styled-components';

export const Circle = styled.div`
  background: ${props => props.color || 'rgb(189, 113, 0)'};
  width: 1rem;
  max-width: 1rem;
  min-width: 1rem;
  height: 1rem;
  max-height: 1rem;
  min-height: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
`;
