import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Card, Upload, Button, message } from 'antd';
import { FormContainerStyle } from 'styles/styledComponents';
import { UploadContainerStyle } from './styles';

function AddPartner({
  setDrawerIsVisible,
  form: { getFieldDecorator, validateFieldsAndScroll },
  actions: { createPartner, generatePresignedUrl, uploadFile },
  state: { createPartnerLoading, generatedURL },
}) {
  const [fileName, setFileName] = useState('');
  const uploadProps = {
    accept: 'image/x-png,image/gif,image/jpeg',
    name: 'file',
    multiple: false,
    action: generatedURL,
    listType: 'picture',
    customRequest({ action, file, onError, onSuccess }) {
      if (generatedURL) {
        const headers = {
          'Content-Type': 'application/octet-stream',
        };
        uploadFile(action, file, headers, onSuccess, onError);
      }
    },
    onChange(info) {
      const { file } = info;
      const { status } = file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onSuccess = () => {
    message.success('Partner Created Successfully!');
    setDrawerIsVisible(false);
  };

  const formSubmission = e => {
    e.preventDefault();
    validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        const modifiedBody = {
          user: {
            ...values,
            logo: fileName,
          },
        };
        createPartner(modifiedBody, onSuccess);
      }
    });
  };

  useEffect(() => {
    const filename = `partner-logo-${Date.now()}`;
    setFileName(filename);
    generatePresignedUrl(filename);
  }, [generatePresignedUrl]);
  return (
    <FormContainerStyle>
      <Form onSubmit={formSubmission}>
        <Card title="Partner Details" size="small">
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input user email!' }],
            })(<Input data-test="name" placeholder="Name" />)}
          </Form.Item>

          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please input user email!' },
                { type: 'email', message: 'Wrong email format!' },
              ],
            })(<Input data-test="email" placeholder="Email" />)}
          </Form.Item>

          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your Password!' },
                { min: 8, message: 'Please enter at least 8 characters.' },
              ],
            })(
              <Input.Password
                type="password"
                placeholder="Password"
                autocomplete="new-password"
                min={8}
              />,
            )}
          </Form.Item>

          <Form.Item label="Logo" style={{ flexDirection: 'column' }}>
            <UploadContainerStyle>
              <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Upload.Dragger>
            </UploadContainerStyle>
          </Form.Item>

          <Button
            htmlType="submit"
            type="primary"
            loading={createPartnerLoading}
            style={{ float: 'right' }}
          >
            Create Partner
          </Button>
        </Card>
      </Form>
    </FormContainerStyle>
  );
}

export default Form.create()(AddPartner);
