import React, { useState, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import {
  Card,
  Select,
  Spin,
  Input,
  Radio,
  message,
  Button,
  Popconfirm,
} from 'antd';
import JSZip from 'jszip';
import Validation from 'utils/Validation';
import { showMessage } from 'utils/helpers';
import RangePicker from '../SharedComponents/rangePicker';
import UploadFromUrl from '../SharedComponents/uploadFromUrl';
import UploadFromComputer from '../SharedComponents/uploadFromComputer';

const { Option } = Select;
let keysHeadlinesId = 0;
let keysDescriptionsId = 0;
function Facebook({
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  uploadFileAction,
  resetUpload,
  generatedURL,
  campaign,
  allFbAudiences,
  allPagesIds,
  isAudianceListLoading,
  isPageLoading,
  getAudienceIds,
  getPageIds,
}) {
  const [selectedUploadMode, selectUploadMode] = useState('upload');
  const [supportedFile, setSupportedFile] = useState('zip');
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [zipFile, setZipFile] = useState('');
  const [selectedAudienceId, selectAudienceId] = useState('');
  const [uploadFilesData, setUploadFilesData] = useState({
    uploadData: [],
    filesToUpload: [],
  });

  const handleUploadFile = ({ action, file, headers, onSuccess, onError }) => {
    if (supportedFile === 'zip') {
      uploadFileAction({
        action,
        file,
        headers,
        onSuccess: () => {
          message.success(`${file.name} file uploaded successfully.`);
          onSuccess();
        },
        onError: () => {
          message.success(`${file.name} file upload failed.`);
          onError();
        },
      });
    } else {
      const zip = new JSZip();
      imagesToUpload.forEach(img => zip.file(img.name, img, { base64: true }));
      zip.generateAsync({ type: 'blob' }).then(content => {
        content.lastModifiedDate = new Date();
        content.name = 'images.zip';

        uploadFileAction({
          action,
          file: content,
          headers,
          onSuccess: () => {
            message.success('Your file uploaded successfully.');
          },
          onError: () => {
            message.error('Your file upload failed.');
          },
        });
      });
    }
  };

  const uploadImagesProps = {
    accept:
      supportedFile === 'zip'
        ? 'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
        : 'image/x-png,image/gif,image/jpeg',

    name: 'file',
    multiple: supportedFile === 'image',
    disabled: !generatedURL,
    action: generatedURL,
    ...(supportedFile === 'image'
      ? { fileList: imagesToUpload.filter(f => f.size <= 2000000) }
      : uploadFilesData.filesToUpload.length
      ? { fileList: uploadFilesData.filesToUpload }
      : { fileList: zipFile }),

    beforeUpload(file, fileList) {
      const { name, size } = file;
      if (size > 2000000) {
        if (supportedFile === 'zip') {
          fileList.pop();
        }
        showMessage('error', `${file.name} file size of 2 MB exceeded.`);
        return false;
      }
      if (supportedFile === 'zip' && !name.endsWith('.zip')) {
        showMessage('error', `${file.name} is not a valid archive`);
        fileList.pop();
        return false;
      }
      if (
        supportedFile === 'image' &&
        !['png', 'gif', 'jpeg', 'jpg'].some(itm =>
          name.toLowerCase().endsWith(itm),
        )
      ) {
        showMessage('error', `${file.name} is not a valid image`);
        fileList.pop();
        return false;
      }
      if (supportedFile === 'image' && fileList.length > 40) {
        showMessage('error', 'Files count cannot be more than 40 images.');
        fileList.pop();
        return false;
      }
      if (supportedFile === 'image') {
        setImagesToUpload([...imagesToUpload, ...fileList]);
      }
      return true;
    },

    customRequest({ action, file, onError, onSuccess }) {
      const headers = {
        'Content-Type': 'application/zip',
      };
      handleUploadFile({ action, file, headers, onSuccess, onError });
    },

    defaultFileList: uploadFilesData.filesToUpload,

    onChange(info) {
      const { status } = info.file;
      let modInfoList = [...info.fileList];
      if (supportedFile === 'image') {
        const filesImagesF = [...modInfoList];
        const acceptedImages = filesImagesF.filter(
          f => f.size <= 2000000 && !f.error,
        );
        modInfoList = [...acceptedImages];
      }
      if (status === 'done') {
        if (supportedFile === 'zip' && modInfoList.length > 0) {
          info.fileList.shift();
          modInfoList.shift();
        }
      } else if (status === 'removed') {
        setImagesToUpload(modInfoList);
        if (info.fileList.length < 1) resetUpload();
      } else if (status === 'error') {
        showMessage('error', 'Your file upload failed.');
      }

      if (supportedFile === 'zip') {
        if (status === 'removed') {
          setZipFile([]);

          delete info.file;
        } else if (
          !info.file.name.endsWith('.zip') ||
          info.file.size > 2000000
        ) {
          info.file = zipFile && zipFile[0];
          setZipFile(info.file);
        } else {
          Object.keys(info).length && setZipFile([info.file]);
        }
      }
      setUploadFilesData({
        ...uploadFilesData,
        filesToUpload:
          supportedFile === 'zip'
            ? info.file && Object.keys(info.file).length
              ? [info.file]
              : []
            : modInfoList,
      });
    },
  };

  const removeField = (k, fieldName) => {
    const keys = getFieldValue(fieldName);
    if (keys?.length === 1) {
      return;
    }

    setFieldsValue({
      [fieldName]: keys?.filter(key => key !== k),
    });
  };

  const addField = fieldName => {
    const keys = getFieldValue(fieldName) || [];
    let nextKeys = [];
    if (keys?.length < 5) {
      nextKeys = keys?.concat(
        fieldName === 'keysHeadlines'
          ? ++keysHeadlinesId
          : ++keysDescriptionsId,
      );
    } else {
      nextKeys = [...keys];
    }

    setFieldsValue({
      [fieldName]: nextKeys,
    });
  };

  getFieldDecorator('keysHeadlines', { initialValue: [0] });
  getFieldDecorator('keysDescriptions', { initialValue: [0] });

  const headlineParts = getFieldValue('keysHeadlines');
  const DescriptionParts = getFieldValue('keysDescriptions');

  const renderAudienceList = () => {
    return allFbAudiences?.map(aud => (
      <Option value={aud.id} key={aud.id}>
        {aud.name}
      </Option>
    ));
  };

  const renderPagesList = () => {
    return allPagesIds?.map(page => (
      <Option value={page.id} key={page.id}>
        {page.name}
      </Option>
    ));
  };

  const headlinePartsFormItems = (parts, type) => {
    const [title, keyVal] =
      type === 'keysHeadlines'
        ? ['Headline Part', 'headlines']
        : ['Description', 'description'];

    return (
      <>
        {parts.map((k, index) => (
          <Form.Item label={`${title} ${index + 1}`} key={k}>
            {getFieldDecorator(`${keyVal}[${k}]`, {
              rules: [
                type === 'keysHeadlines'
                  ? Validation.facebookHeadlineLimit
                  : Validation.facebookDescriptionLimit,
                index === 0 && Validation.required,
              ],
            })(<Input placeholder={title} />)}
            {parts.length > 1 && index !== 0 ? (
              <Popconfirm
                title="Are you sure remove this field?"
                onConfirm={() => removeField(k, type)}
                okText="Yes"
                cancelText="No"
              >
                <MinusCircleOutlined style={{ marginLeft: 5 }} />
              </Popconfirm>
            ) : null}
          </Form.Item>
        ))}

        <Button
          type="dashed"
          onClick={() => {
            addField(type);
          }}
          style={{ width: '100%', marginBottom: 24 }}
          disabled={getFieldValue(type).length === 5}
        >
          <PlusOutlined /> Add New {title}
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (!allFbAudiences.length) getAudienceIds('facebook');
    if (!selectedAudienceId.length) getPageIds(campaign?._id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title="Facebook Details" size="small">
        <RangePicker
          getFieldDecorator={getFieldDecorator}
          campaign={campaign}
        />

        <Form.Item label="Audience List ID">
          {getFieldDecorator('audience_list_id')(
            <Select
              showSearch
              loading={isAudianceListLoading}
              placeholder="Select an audience"
              optionFilterProp="children"
              onChange={value => {
                selectAudienceId(value);
              }}
              notFoundContent={
                isAudianceListLoading ? <Spin size="small" /> : null
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              data-test="audienceListId"
            >
              {renderAudienceList()}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label="Page ID">
          {getFieldDecorator('page_id')(
            <Select
              showSearch
              placeholder="Select an page Id"
              optionFilterProp="children"
              loading={isPageLoading}
              notFoundContent={isPageLoading ? <Spin size="small" /> : null}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              data-test="pageId"
            >
              {renderPagesList()}
            </Select>,
          )}
        </Form.Item>

        {Array.from({ length: 5 }, (_, indx) => (
          <Form.Item
            htmlFor={`text${indx + 1}`}
            key={`text${indx + 1}`}
            label={`Primiary Text ${indx + 1}`}
          >
            {getFieldDecorator(`text${indx + 1}`, {
              rules: [Validation.required, Validation.facebookTextLimit],
            })(<Input data-test={`text${indx + 1}`} />)}
          </Form.Item>
        ))}

        {headlinePartsFormItems(headlineParts, 'keysHeadlines')}
        {headlinePartsFormItems(DescriptionParts, 'keysDescriptions')}
      </Card>

      <Card title="Upload" size="small">
        <Radio.Group
          style={{ width: '100%', marginBottom: '1rem' }}
          defaultValue="upload"
          buttonStyle="solid"
          onChange={e => {
            selectUploadMode(e.target.value);
          }}
          data-test="uploadFacebook"
        >
          <Radio.Button
            style={{ width: '50%', textAlign: 'center' }}
            value="upload"
          >
            Upload File
          </Radio.Button>
          <Radio.Button
            style={{ width: '50%', textAlign: 'center' }}
            value="url"
          >
            URL
          </Radio.Button>
        </Radio.Group>

        {selectedUploadMode === 'upload' ? (
          <>
            <Radio.Group
              style={{ width: '50%', marginBottom: '1rem' }}
              defaultValue="zip"
              onChange={e => {
                setSupportedFile(e.target.value);
                setUploadFilesData({
                  uploadData: [],
                  filesToUpload: [],
                });
                setImagesToUpload([]);
                setZipFile('');
              }}
              data-test="uploadFileTechnique"
            >
              <Radio.Button
                style={{ width: '50%', textAlign: 'center', padding: '0 10px' }}
                value="zip"
              >
                Zip
              </Radio.Button>
              <Radio.Button
                style={{ width: '50%', textAlign: 'center', padding: '0 10px' }}
                value="image"
              >
                Images
              </Radio.Button>
            </Radio.Group>

            <UploadFromComputer
              uploadImagesProps={uploadImagesProps}
              getFieldDecorator={getFieldDecorator}
            />
          </>
        ) : (
          <UploadFromUrl getFieldDecorator={getFieldDecorator} />
        )}
      </Card>
    </>
  );
}

export default Facebook;
