import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Spin, Switch, Tooltip } from 'antd';
import { isURL } from 'utils/helpers';
import React, { useState, useEffect } from 'react';
import Validation from 'utils/Validation';

import {
  RowContainerStyle,
  KeyContainerStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';

import {
  CodeStyle,
  HintStyle,
  HintContainerStyle,
  HTMLTagStyle,
  OpentagInfoTextStyle,
} from 'pages/CampaignDetails/Analytics/styles';
import { DivFlexSpaceBetween } from 'styles/styledComponents';

import EditableField from 'components/EditableField';

const OpentagPanel = ({
  campaign,
  opentagScripts,
  editOpentagScripts,
  editCampaignOpenTagLoading,
  fetchCampaignScriptsLoading,
  form,
}) => {
  const [editedValues, setEditedValues] = useState({
    scripts: '',
  });
  const [newOpentagScripts, setNewOpentagScripts] = useState('');
  const [matomoAnalytics, setMatomoAnalytics] = useState({});
  const [optimizeWith, setOptimizeWith] = useState('');

  const [editedField, setEditedField] = useState('');

  useEffect(() => {
    const opentagScriptsStringified = opentagScripts?.join(',');
    setNewOpentagScripts(opentagScriptsStringified);
    const siteId = getMatomoSiteId(opentagScriptsStringified);
    if (siteId) {
      setMatomoAnalytics({ siteId });
    }
  }, [opentagScripts]);

  useEffect(() => {
    setOptimizeWith(campaign.optimize_with);
  }, [campaign]);

  const opentagStatus = campaign.opentag_integrated
    ? 'Installed'
    : 'Not Installed';

  const encryptedId = campaign.encrypted_id;

  const opentagScriptUrl = process.env.REACT_APP_OPENTAG_SCRIPT_URL;

  const getMatomoSiteId = text => {
    const urlsArray = text
      .split(',')
      .join('\n')
      .split('\n');
    const matomoUrl = urlsArray.find(url => url.includes('matomi'));
    let siteId = '';
    if (isURL(matomoUrl && matomoUrl.trim())) {
      const url = new URL(matomoUrl.trim());
      siteId = url.searchParams.get('site_id');
    }
    return siteId;
  };

  const onEditOpentag = (editValue, optimizeWithTS) => {
    const [editedOpentagScripts] = Object.values(editValue);
    const openTagData = {
      scripts: editedOpentagScripts
        .toString()
        .trim()
        .split('\n'),
    };

    if (matomoAnalytics && matomoAnalytics.siteId) {
      openTagData.analyticsTrackingService =
        openTagData.analyticsTrackingService || {};
      openTagData.analyticsTrackingService.matomo = matomoAnalytics;
    }

    if (optimizeWithTS) {
      openTagData.analyticsTrackingService =
        openTagData.analyticsTrackingService || {};
      openTagData.analyticsTrackingService.optimize_with = optimizeWithTS;
    }

    editOpentagScripts(openTagData);
  };

  const onChangeOpentagScripts = scripts => {
    setNewOpentagScripts(scripts);
    const siteId = getMatomoSiteId(scripts);
    if (siteId) {
      setMatomoAnalytics({ siteId });
    } else {
      setMatomoAnalytics({});
    }
  };

  const hintContent = (
    <>
      <OpentagInfoTextStyle>
        Enter each script URL on a separate line. The following code snippet
        should be placed before the <HTMLTagStyle>&lt;/head&gt;</HTMLTagStyle>{' '}
        end tag:
      </OpentagInfoTextStyle>
      <CodeStyle>
        &lt;script <br />
        &nbsp;&nbsp;async src=&quot;
        {opentagScriptUrl}
        ?id=
        {encryptedId}
        &quot;&gt; <br />
        &lt;/script&gt;
      </CodeStyle>
    </>
  );

  const isSaveButtonDisabled = (name, value) =>
    !editedValues[name] || editedValues[name]?.toString() === value?.toString();

  const loadingIcon = <LoadingOutlined style={{ marginLeft: 10 }} spin />;

  const { getFieldDecorator, setFieldsValue } = form;

  return (
    <>
      <RowContainerStyle>
        <KeyContainerStyle>Status</KeyContainerStyle>
        <ValueContainerStyle>{opentagStatus}</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Scripts</KeyContainerStyle>
        <ValueContainerStyle>
          {fetchCampaignScriptsLoading ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <>
              <EditableField
                value={opentagScripts?.join(',')}
                disableSaveButton={isSaveButtonDisabled(
                  'scripts',
                  opentagScripts,
                )}
                onSave={() => {
                  setEditedField('scripts');
                  onEditOpentag(
                    { scripts: editedValues['scripts'] },
                    optimizeWith,
                  );
                }}
                onCancel={() => {
                  setEditedValues({ ...editedValues, scripts: opentagScripts });
                  setFieldsValue({ scripts: opentagScripts });
                }}
                loadingEdit={
                  editCampaignOpenTagLoading && editedField === 'scripts'
                }
                onChange={e => onChangeOpentagScripts(e.target.value)}
              >
                <Form.Item>
                  {getFieldDecorator('scripts', {
                    initialValue: opentagScripts?.join(','),
                    rules: [Validation.required],
                  })(
                    <Input
                      placeholder="Scripts"
                      onChange={e =>
                        setEditedValues({
                          ...editedValues,
                          scripts: e.target.value,
                        })
                      }
                      data-test={`scripts-input`}
                    />,
                  )}
                </Form.Item>
              </EditableField>
              <HintContainerStyle>
                <Popover content={hintContent} placement="bottom">
                  <HintStyle>
                    <InfoCircleOutlined />
                  </HintStyle>
                </Popover>
              </HintContainerStyle>
            </>
          )}
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>Matomo SiteID</KeyContainerStyle>
        <ValueContainerStyle>
          <Input value={getMatomoSiteId(newOpentagScripts)} disabled />
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow>
        <KeyContainerStyle>Use Matomo</KeyContainerStyle>
        <ValueContainerStyle>
          <DivFlexSpaceBetween>
            <div>
              <Switch
                checked={campaign.optimize_with === 'ma'}
                onChange={value => {
                  const ts = value ? 'ma' : 'ga';
                  setOptimizeWith(ts);
                  setEditedField('useMatomo');
                  onEditOpentag({ scripts: newOpentagScripts }, ts);
                }}
                disabled={
                  !matomoAnalytics.siteId || matomoAnalytics.siteId.length === 0
                }
              />
              {editCampaignOpenTagLoading && editedField === 'useMatomo' && (
                <Spin indicator={loadingIcon} />
              )}
            </div>
            <Tooltip title="You can optimize with matomo when a matomo script is provided">
              <HintStyle>
                <InfoCircleOutlined />
              </HintStyle>
            </Tooltip>
          </DivFlexSpaceBetween>
        </ValueContainerStyle>
      </RowContainerStyle>
    </>
  );
};

export default Form.create()(OpentagPanel);
