import React from 'react';
import { Form } from '@ant-design/compatible';
import { Button, Checkbox } from 'antd';

function GenerateKeywords({
  getFieldDecorator,
  generateKeywords,
  campaign,
  keywordsLoading,
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
      }}
    >
      <Form.Item>
        {getFieldDecorator('keywords_as_seed', {
          initialValue: false,
        })(
          <Checkbox data-test="keywords_as_seed">
            Generate More Keywords
          </Checkbox>,
        )}
      </Form.Item>
      <Button
        type="primary"
        onClick={() => generateKeywords(campaign?._id, campaign?.url)}
        loading={keywordsLoading}
        data-test="loadingKeywords"
      >
        generate keywords
      </Button>
    </div>
  );
}

export default GenerateKeywords;
