import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { Form } from '@ant-design/compatible';
import Validation from 'utils/Validation';

function Cpc({ defaultCPC, getFieldDecorator }) {
  const [cpcAmount, setCpcAmount] = useState(defaultCPC);

  return (
    <Form.Item label="CPC">
      {getFieldDecorator('cpc_amount', {
        initialValue: cpcAmount,
        rules: [Validation.required],
      })(
        <InputNumber
          step={0.1}
          min={0.1}
          onChange={setCpcAmount}
          style={{ marginLeft: 'auto' }}
        />,
      )}
    </Form.Item>
  );
}

export default Cpc;
