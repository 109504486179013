import React, { useEffect, useState } from 'react';
import { Input, Table, Card, Drawer, message, Tooltip } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import customerColumns, { campaignsColumns } from './customersColums';
import { connect } from 'speedux';
import Module from './module';
import useMedia from 'use-media';
import {
  LAPTOP_SCREEN_HEIGHT,
  MOB_SCREEN_WIDTH,
  MOB_WIDTH_FOR_DRAWER,
} from 'utils/constants';
import AddPartner from './addPartner';
import PageLayout from 'components/PageLayout';
import { CustomerListStyle } from './styles';
import { SearchHeaderContainerStyle } from 'styles/styledComponents';
import { pathAllCampaigns } from 'routes/routes';

const { Search } = Input;
const OFFSET = 10;
const Customers = ({
  state: {
    getAllCustomersLoading,
    customersList,
    customersCount,
    createPartnerLoading,
    submitted,
    generatedURL,
    partnerCreated,
    campaigns,
    getCampaignLoading,
  },
  actions: {
    getCustomers,
    createPartner,
    generatePresignedUrl,
    uploadFile,
    resetPartnerCreated,
    getCustomerCampaigns,
  },
}) => {
  const [selectedRow, selectRow] = useState(null);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [pagination, setPagination] = useState({
    limit: OFFSET,
    offset: 0,
  });

  const paginationConfig = {
    size: 'small',
    total: +customersCount,
    pageSize: OFFSET,
    showSizeChanger: false,
    onChange: pageNumber =>
      setPagination({ limit: OFFSET, offset: OFFSET * (pageNumber - 1) }),
  };

  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });

  useEffect(() => {
    getCustomers({ ...pagination, search: '' });
  }, [pagination]); //eslint-disable-line

  useEffect(() => {
    if (partnerCreated) {
      message.success('Partner created successfully.');
      setDrawerIsVisible(false);
    }

    return () => resetPartnerCreated();
  }, [partnerCreated]); //eslint-disable-line

  useEffect(() => {
    if (selectedRow) {
      getCustomerCampaigns(selectedRow);
    }
  }, [getCustomerCampaigns, selectedRow]);

  useEffect(() => {
    if (customersList.length) {
      const firstCustomer = customersList?.[0].email;
      selectRow(firstCustomer);
    }
  }, [customersList]);

  const isLaptop = useMedia({ minHeight: LAPTOP_SCREEN_HEIGHT });

  return (
    <CustomerListStyle>
      <PageLayout
        pageHeader={
          <PageHeader
            title="customers"
            btnText="create partner"
            btnOnChange={() => setDrawerIsVisible(!drawerIsVisible)}
            btnTestId={'addPartnerBtn'}
          >
            <Drawer
              title="ADD NEW PARTNER"
              placement="right"
              {...(isMob
                ? { width: MOB_WIDTH_FOR_DRAWER }
                : { width: MOB_SCREEN_WIDTH })}
              onClose={() => {
                setDrawerIsVisible(false);
              }}
              visible={drawerIsVisible}
              destroyOnClose
            >
              <AddPartner
                actions={{ createPartner, generatePresignedUrl, uploadFile }}
                state={{ createPartnerLoading, submitted, generatedURL }}
                setDrawerIsVisible={setDrawerIsVisible}
              />
            </Drawer>
          </PageHeader>
        }
        pageBody={
          <>
            <Card>
              <SearchHeaderContainerStyle>
                <div>
                  <Search
                    placeholder="Search for customer"
                    onSearch={e => {
                      getCustomers({ limit: OFFSET, offset: 0, search: e });
                    }}
                    enterButton
                  />
                </div>
              </SearchHeaderContainerStyle>
              <Table
                loading={getAllCustomersLoading}
                rowKey="email"
                columns={customerColumns}
                pagination={paginationConfig}
                dataSource={customersList}
                rowClassName={record => {
                  if (record.email === selectedRow) return 'activeRow';
                }}
                onRow={record => ({
                  onClick: () => {
                    selectRow(record.email);
                  },
                })}
                style={{
                  wordWrap: 'no-wrap',
                }}
                scroll={{ x: 300, y: isLaptop ? '58vh' : 340 }}
              />
            </Card>

            <Card>
              <h3 style={{ marginBottom: 25 }}>{selectedRow}'s Campaigns</h3>
              <Table
                loading={getCampaignLoading}
                rowKey="_id"
                columns={[
                  ...campaignsColumns,
                  {
                    title: '',
                    dataIndex: '_id',
                    key: 'actions',
                    width: 50,
                    fixed: 'right',
                    render: id => (
                      <Link to={`${pathAllCampaigns}?state=ALL&search=${id}`}>
                        <Tooltip title="Go to campaign">
                          <SelectOutlined style={{ fontSize: 25 }} />
                        </Tooltip>
                      </Link>
                    ),
                  },
                ]}
                pagination={false}
                dataSource={campaigns}
                style={{
                  wordWrap: 'no-wrap',
                }}
                scroll={{ x: 300, y: isLaptop ? '58vh' : 340 }}
                data-test="customerCampaigns-table"
              />
            </Card>
          </>
        }
      />
    </CustomerListStyle>
  );
};

export default connect(Customers, Module);
