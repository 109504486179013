import React, { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { connect } from 'speedux';
import Module from './module';
import Cookies from 'universal-cookie';
import { COOKIES_AUTHORIZATION, flexBetweenCenter } from 'utils/constants';

const cookies = new Cookies();
function ViewAsUser({
  setViewAsUserModal,
  viewAsUserModal,
  campaignId,
  campaignName,
  userName,
  impersonatedId,
  actions: { userImpersonate },
  state: { token, userData },
}) {
  const accessToken = token?.access_token;
  const accountID = userData?.roleAttributes?.accountId;
  const email = userData?.email;

  useEffect(() => {
    if (viewAsUserModal)
      userImpersonate(
        campaignId || impersonatedId,
        (campaignId && 'campaignId') || (impersonatedId && 'impersonatedId'),
        cookies.get(COOKIES_AUTHORIZATION),
      );
  }, [viewAsUserModal, campaignId, impersonatedId, userImpersonate]);

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <div style={{ ...flexBetweenCenter, padding: 20 }}>
        {campaignName && (
          <h3 data-test="campaignToImpersonate">{campaignName}'s View</h3>
        )}
        {userName && (
          <h3 data-test="campaignToImpersonate">View as {userName}</h3>
        )}
        <CloseOutlined
          onClick={() => {
            setViewAsUserModal(false);
          }}
        />
      </div>
      <iframe
        title="Instascaler Dashboard View"
        id="userViewIframe"
        src={`${process.env.REACT_APP_CUSTOMER_DASHBOARD_URL}report?token=${accessToken}&id=${accountID}&email=${email}`}
        width="100%"
        height="450"
        frameBorder="0"
      />
    </Card>
  );
}

export default connect(ViewAsUser, Module);
