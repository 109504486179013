// Public paths
export const pathRoot = '/';
export const pathLogin = '/login';
export const pathForgetPassword = '/forget';

// Authorized paths
export const pathAllCampaigns = '/campaigns';
export const pathCustomers = '/customers';
export const pathAllNotifications = '/notifications';
export const pathMonitoring = '/monitoring';
export const pathHomePage = pathMonitoring;
