import React, { useMemo } from 'react';
import { Card } from 'antd';
import RangePicker from '../SharedComponents/rangePicker';
import Keywords from '../SharedComponents/keywords';

function ZeroPark({
  getFieldDecorator,
  campaign,
  getFieldValue,
  campaignKeywords,
  keywordsLoading,
}) {
  const keywords = getFieldValue('keywords');

  return (
    <Card size="small" title="Zero Park Details">
      <RangePicker getFieldDecorator={getFieldDecorator} campaign={campaign} />
      {useMemo(
        () => (
          <Keywords
            getFieldDecorator={getFieldDecorator}
            campaignKeywords={campaignKeywords}
            keywordsLoading={keywordsLoading}
          />
        ),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [keywords],
      )}
    </Card>
  );
}

export default ZeroPark;
