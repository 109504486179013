import styled from 'styled-components';
import theme from 'styles/themes';

export const TagsContainerStyle = styled.div`
  display: flex;
  flex-direction: column;

  div:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export const RulesContainerStyle = styled.div`
  max-width: 520px;
  min-width: 300px;
  min-height: 300px;
`;

export const RuleContainerStyle = styled.div`
  padding-right: ${props => (props.editMode ? 'initial' : '25px')};
  display: ${props => (props.editMode ? 'inherit' : 'flex')};
  align-items: baseline;

  &:not(:last-of-type) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.grayBorder};
  }
`;

export const CommentContainerStyle = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;

  > div:last-of-type {
    flex-grow: 1;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.grayBorder};
    padding-bottom: 10px;
  }
`;

export const ButtonsContainerStyle = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const FilterContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.isMob ? 'column' : 'row')};
  margin-bottom: 2rem;
`;

export const SelectHealthStatusStyle = styled.div`
  width: ${props => (props.isMob ? '100%' : '350px')};
  margin-bottom: ${props => (props.isMob ? '1rem' : 0)};
  margin-right: 2rem;
`;

export const SelectCampaignStateStyle = styled.div`
  width: ${props => (props.isMob ? '100%' : '150px')};
  margin-right: 2rem;
`;
