import React, { useEffect, useState } from 'react';
import { Button, Card, Spin, Tag } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

const SelectedKeywords = ({
  campaign_id,
  source: sourceTitle,
  selectedKeywords,
  color,
  loading,
  selectKeywords,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [canceledEdit, setCanceledEdit] = useState(0);
  const [keywordsToBeRemoved, setKeywordsToBeRemoved] = useState([]);
  const [keywordsToBeRender, setKeywordsToBeRender] = useState([]);

  useEffect(() => {
    if (selectedKeywords) {
      setKeywordsToBeRender(selectedKeywords);
    }
  }, [selectedKeywords]);

  const source = sourceTitle.toLowerCase();

  const removeKeywords = () => {
    const newKeywords = selectedKeywords.filter(
      keyword => !keywordsToBeRemoved.includes(keyword),
    );

    selectKeywords({
      campaign_id,
      source,
      keywords: newKeywords,
    });

    setEditMode(false);
    setKeywordsToBeRemoved([]);
  };

  return (
    <Spin spinning={loading} key={`${source}SelectedKeywords`}>
      <Card
        key={canceledEdit}
        title={sourceTitle}
        size="small"
        extra={
          editMode ? (
            <>
              <Button
                type="link"
                icon={<CloseOutlined style={{ fontSize: 20, color: '#fff' }} />}
                onClick={() => {
                  setEditMode(false);
                  setCanceledEdit(canceledEdit + 1);
                  setKeywordsToBeRemoved([]);
                  setKeywordsToBeRender(selectedKeywords);
                }}
              />
              <Button
                type="link"
                icon={<SaveOutlined style={{ fontSize: 20, color: '#fff' }} />}
                onClick={removeKeywords}
              />
            </>
          ) : (
            <>
              {keywordsToBeRender?.length > 1 ? (
                <Button
                  type="link"
                  icon={
                    <EditOutlined style={{ fontSize: 20, color: '#fff' }} />
                  }
                  onClick={() => setEditMode(true)}
                />
              ) : (
                ''
              )}
            </>
          )
        }
        style={{ marginBottom: 20 }}
      >
        {keywordsToBeRender?.length
          ? keywordsToBeRender?.map(keyword => (
              <Tag
                closable={
                  editMode &&
                  keywordsToBeRender?.length - keywordsToBeRemoved.length > 1
                }
                onClose={() =>
                  setKeywordsToBeRemoved([...keywordsToBeRemoved, keyword])
                }
                key={keyword}
                color={color}
                style={{ marginBottom: 8 }}
              >
                {keyword}
              </Tag>
            ))
          : 'No Selected Keywords!'}
        {editMode &&
        keywordsToBeRender?.length - keywordsToBeRemoved.length > 1 ? (
          <Button
            type="link"
            style={{ margin: '0 0 0 auto', display: 'block' }}
            onClick={() => {
              setKeywordsToBeRemoved(keywordsToBeRender?.slice(1));
              setKeywordsToBeRender([keywordsToBeRender[0]]);
            }}
          >
            Remove All
          </Button>
        ) : (
          ''
        )}
      </Card>
    </Spin>
  );
};

export default SelectedKeywords;
