import { Dropdown, Input, Menu, Select } from 'antd';
import ActionSquare from 'components/ActionSquare';
import CustomizedTable from 'components/CustomizedTable';
import StatusCircle from 'components/StatusCircle';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useMedia from 'use-media';

import {
  campaignsStatusMap,
  DEBOUNCE_TIMEOUT,
  getSearchParams,
  LIMIT,
  LAPTOP_SCREEN_HEIGHT,
  campaignsSourceMap,
} from 'utils/constants';

import {
  CamapignsListContainer,
  DropdownActionsContainer,
  FilterActionsContainer,
} from './styles';

const { Search } = Input;
const { Option } = Select;

const CampaignsList = ({
  state: {
    partnersList,
    fetchPartnersLoading,
    fetchCampaignsLoading,
    campaignsCount,
    filteredCampaigns,
    campaignAdded,
  },
  onClickCampaign,
  setViewAsUserModal,
  onViewReport,
  onViewHistory,
  onViewCostReport,
  onViewAsUserClick,
  triggerGaIntegrationView,
  onSelectSpecificSection,
  getAllCampaigns,
  resetCampaignAdded,
  getAllPartners,
}) => {
  const location = useLocation();
  const history = useHistory();
  const params = getSearchParams(location.search);
  const urlParams = new URLSearchParams(location.search);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [selectedRow, selectRow] = useState(null);
  const [queryString, setQueryString] = useState({
    search: urlParams.get('search'),
    state: urlParams.get('state') || 'RUNNING',
    source: urlParams.get('source') || 'All',
    accountId: urlParams.get('accountId'),
  });

  const columns = [
    {
      title: '',
      dataIndex: 'state',
      key: 'state',
      width: '50px',
      render: (_, { state }) => {
        const { color } = campaignsStatusMap[state];
        return <StatusCircle data-test={state} color={color} noLabel />;
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '70px',
      render: (_, { id }) => <span>{id}</span>,
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: text => <span>{text}</span>,
    },
    {
      title: '',
      key: 'actions',
      width: '40px',
      render: (_, campaignInfo) => {
        return (
          <ActionSquare
            onSelectSpecificSection={onSelectSpecificSection}
            triggerGaIntegrationView={({
              id,
              name,
              url,
              deliveryUtmSource,
            }) => {
              triggerGaIntegrationView({ id, name, url, deliveryUtmSource });
              selectRow(id);
            }}
            setViewAsUserModal={setViewAsUserModal}
            onViewAsUserClick={onViewAsUserClick}
            onViewReport={(id, name) => {
              onViewReport(id, name);
              selectRow(id);
            }}
            onViewCostReport={(id, name, balance, createdAt) => {
              onViewCostReport(id, name, balance, createdAt);
              selectRow(id);
            }}
            onViewHistory={(id, name) => {
              onViewHistory(id, name);
              selectRow(id);
            }}
            onClickCampaign={id => {
              onClickCampaign(id);
              selectRow(id);
            }}
            {...campaignInfo}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (campaignAdded) {
      getAllCampaigns({
        campaignParams: {
          ...queryString,
          state: queryString.state,
          source: queryString.source,
          search: queryString.search,
          accountId: queryString.accountId,
        },
        limit: LIMIT,
        page: 1,
      });
    }

    return () => resetCampaignAdded();
  }, [campaignAdded]); //eslint-disable-line

  useEffect(() => {
    if (filteredCampaigns) {
      const [firstCampaign] = filteredCampaigns;
      const firstCampaignId = firstCampaign?._id;
      onClickCampaign(firstCampaignId);
      selectRow(firstCampaignId);
    }
  }, [filteredCampaigns]); //eslint-disable-line

  useEffect(() => {
    setCurrentPage(1);
    getAllPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!queryString.search) {
      if (params.has('search')) {
        params.delete('search');
      }
    } else {
      params.set('search', queryString.search);
    }
    const timeout = setTimeout(() => {
      const [_state, _source, _search, _accountId] = [
        params.get('state') || queryString.state,
        params.get('source') || queryString.source,
        params.get('search') || queryString.search,
        params.get('accountId') || queryString.accountId,
      ];
      let q = '';
      if (_state) q += `${q ? '&' : '?'}state=${_state}`;
      if (_source) q += `${q ? '&' : '?'}source=${_source}`;
      if (_search) q += `${q ? '&' : '?'}search=${_search}`;
      if (_accountId) q += `${q ? '&' : '?'}accountId=${_accountId}`;

      history.push({ search: q });

      setCurrentPage(1);
      getAllCampaigns({
        campaignParams: {
          search: _search,
          state: _state,
          source: _source,
          accountId: _accountId,
        },
        limit: LIMIT,
        page: 1,
      });
    }, DEBOUNCE_TIMEOUT);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString.search, getAllCampaigns]);

  const refetchCampaignAfterChangeStateOrAccountId = ({
    state = null,
    accountId = null,
    source = null,
  }) => {
    let q = '';
    if (state !== '' && (state || queryString.state)) {
      params.set('state', state || queryString.state);
      q += `${q ? '&' : '?'}state=${state || queryString.state}`;
    }
    if (source !== '' && (source || queryString.source)) {
      params.set('source', source || queryString.source);
      q += `${q ? '&' : '?'}source=${source || queryString.source}`;
    }
    if (accountId !== '' && (accountId || queryString.accountId)) {
      params.set('accountId', accountId || queryString.accountId);
      q += `${q ? '&' : '?'}accountId=${accountId || queryString.accountId}`;
    }
    if (queryString.search) {
      params.set('search', queryString.search);
      q += `${q ? '&' : '?'}search=${queryString.search}`;
    }

    history.push({ search: q });
    setCurrentPage(1);
    getAllCampaigns({
      campaignParams: {
        search: queryString.search,
        state: state === '' ? null : state || queryString.state,
        source: source === '' ? null : source || queryString.source,
        accountId: accountId === '' ? null : accountId || queryString.accountId,
      },
      limit: LIMIT,
      page: 1,
    });
  };

  const renderStatusList = useMemo(
    () => (
      <Menu
        onClick={({ key: selectedState }) => {
          if (selectedState === 'ALL') {
            params.delete('state');
            selectedState = '';
            setQueryString({ ...queryString, state: '' });
          } else {
            setQueryString({ ...queryString, state: selectedState });
            params.set('state', selectedState);
          }

          refetchCampaignAfterChangeStateOrAccountId({
            state: selectedState,
          });
        }}
      >
        {Object.keys(campaignsStatusMap).map(status => {
          const { title, color } = campaignsStatusMap[status];

          return (
            <Menu.Item key={status} data-test={`state-${status}`}>
              <StatusCircle title={title} color={color} />
            </Menu.Item>
          );
        })}
      </Menu>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params, queryString],
  );

  const renderSourceList = useMemo(
    () => (
      <Menu
        onClick={({ key: selectedSource }) => {
          if (selectedSource === 'ALL') {
            params.delete('source');
            selectedSource = '';
            setQueryString({ ...queryString, source: '' });
          } else {
            setQueryString({ ...queryString, source: selectedSource });
            params.set('source', selectedSource);
          }

          refetchCampaignAfterChangeStateOrAccountId({
            source: selectedSource,
          });
        }}
      >
        {Object.keys(campaignsSourceMap).map(source => {
          const { title, color } = campaignsSourceMap[source];

          return (
            <Menu.Item key={source} data-test={`source-${source}`}>
              <StatusCircle title={title} color={color} />
            </Menu.Item>
          );
        })}
      </Menu>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params, queryString],
  );

  const renderPartners = () =>
    partnersList?.map(partner => (
      <Option key={partner.id} value={partner.id}>
        {partner.username}
      </Option>
    ));

  const formattedCampaignsList = filteredCampaigns?.map(camp => ({
    id: camp._id,
    key: camp._id,
    name: camp.name,
    isFacebookIntegrated: camp.is_fb_integrated,
    isGoogleIntegrated: camp.is_ga_integrated,
    isMatomoIntegrated: camp.is_ma_integrated,
    state: camp.state,
    deliveryUtmSource: camp.delivery_utm_source,
    url: camp.url,
    balance: camp.package.balance,
    createdAt: camp.created_at,
  }));

  const isLaptop = useMedia({ minHeight: LAPTOP_SCREEN_HEIGHT });
  const {
    title: defaultStateTitle,
    color: defaultStateColor,
  } = campaignsStatusMap[queryString.state];

  const {
    title: defaultSourceTitle,
    color: defaultSourceColor,
  } = campaignsSourceMap[queryString.source];

  return (
    <CamapignsListContainer>
      <FilterActionsContainer>
        <Search
          allowClear
          placeholder="Which campaign you search for?"
          value={queryString.search}
          onChange={e => {
            setQueryString({
              ...queryString,
              search: e.target.value,
            });
            if (!e.target.value) {
              setCurrentPage(1);
              getAllCampaigns({
                campaignParams: {
                  ...queryString,
                  search: '',
                },
                limit: LIMIT,
                page: 1,
              });
            }
          }}
          enterButton
        />
        <DropdownActionsContainer>
          <Dropdown.Button
            overlay={renderStatusList}
            data-test="change-campaign-state"
            trigger={['click', 'hover']}
          >
            <StatusCircle title={defaultStateTitle} color={defaultStateColor} />
          </Dropdown.Button>
          <Dropdown.Button
            overlay={renderSourceList}
            data-test="change-campaign-source"
            trigger={['click', 'hover']}
          >
            <StatusCircle
              title={defaultSourceTitle}
              color={defaultSourceColor}
            />
          </Dropdown.Button>
          <Select
            style={{
              width: '100%',
            }}
            showSearch
            allowClear
            value={
              partnersList.length
                ? partnersList.find(
                    partner =>
                      Number(queryString.accountId) === Number(partner.id),
                  )?.['id']
                : undefined
            }
            placeholder="Select a partner"
            optionFilterProp="children"
            notFoundContent="Not Found."
            loading={fetchPartnersLoading}
            onChange={value => {
              if (!value) params.delete('accountId');
              setQueryString({ ...queryString, accountId: value || '' });
              refetchCampaignAfterChangeStateOrAccountId({
                accountId: value || '',
              });
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderPartners()}
          </Select>
        </DropdownActionsContainer>
      </FilterActionsContainer>
      <CustomizedTable
        columns={columns}
        maxHeight={isLaptop}
        pagination={{
          total: campaignsCount,
          current: currentPage,
          defaultPageSize: pageSize,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            getAllCampaigns({
              campaignParams: queryString,
              limit: pageSize,
              page: page,
            });
          },
          onShowSizeChange: (currentPage, pageSize) => {
            setCurrentPage(1);
            setPageSize(pageSize);
            getAllCampaigns({
              campaignParams: queryString,
              limit: pageSize,
              page: currentPage,
            });
          },
        }}
        data={formattedCampaignsList}
        loading={fetchCampaignsLoading}
        rowClassName={record => {
          if (record.id === selectedRow) return 'activeCampaign';
        }}
        onRow={record => ({
          onClick: () => {
            onClickCampaign(record.id);
            selectRow(record.id);
          },
        })}
      />
    </CamapignsListContainer>
  );
};

export default CampaignsList;
