import styled from 'styled-components';
import { MOB_SCREEN_WIDTH } from 'utils/constants';

export const PageHeaderStyles = styled.div`
  .ant-page-header.ant-page-header-ghost {
    padding: 0;
  }
`;

export const FilterGaTableStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;

  & > * {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const TableFixedStyles = styled.div`
  width: calc(100vw - 670px);

  @media (max-width: 1036px) {
    width: calc(100vw - 210px);
  }

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    width: calc(100vw - 105px);
  }
`;
