export const columns = [
  {
    title: 'Published on',
    dataIndex: 'published_on',
    key: 'published_on',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Source',
    dataIndex: 'campaign_source',
    key: 'campaign_source',
    width: 150,
  },
  {
    title: 'Start date',
    dataIndex: 'campaign_start_date',
    key: 'campaign_start_date',
    width: 150,
    sorter: true,
  },
  {
    title: 'end date',
    dataIndex: 'campaign_end_date',
    key: 'campaign_end_date',
    width: 150,
    sorter: true,
  },
  {
    title: 'clicks',
    dataIndex: 'clicks',
    key: 'clicks',
    width: 100,
  },
  {
    title: 'visits',
    dataIndex: 'visits',
    key: 'visits',
    width: 100,
  },
  {
    title: 'avg. SD',
    dataIndex: 'avg_session_duration',
    key: 'avg_session_duration',
    width: 100,
  },
  {
    title: 'PPS',
    dataIndex: 'page_views_per_session',
    key: 'page_views_per_session',
    width: 100,
    render: pps => pps?.toFixed(2),
  },
  {
    title: 'BR',
    dataIndex: 'bounce_rate',
    key: 'bounce_rate',
    width: 100,
    render: br => `${br?.toFixed(2)}%`,
  },
  {
    title: 'TC',
    dataIndex: 'total_conversions_count',
    key: 'total_conversions_count',
    width: 100,
  },
  {
    title: 'campaign budget',
    dataIndex: 'campaign_budget',
    key: 'campaign_budget',
    width: 200,
    render: cb => cb?.toFixed(2),
    sorter: true,
  },
  {
    title: 'Assigned budget',
    dataIndex: 'campaign_adops_budget',
    key: 'campaign_adops_budget',
    width: 200,
    render: cab => cab?.toFixed(2),
    sorter: true,
  },
  {
    title: 'Total cost spent',
    dataIndex: 'live_consumed_cost',
    key: 'live_consumed_cost',
    width: 200,
    render: lc => lc?.toFixed(2),
  },
  {
    title: 'ideal cost spent',
    dataIndex: 'campaign_ideal_spent_cost',
    key: 'campaign_ideal_spent_cost',
    width: 200,
    render: isc => isc?.toFixed(2),
  },
  {
    title: 'Total charged cost',
    dataIndex: 'charged_cost',
    key: 'charged_cost',
    width: 200,
    render: tcc => tcc?.toFixed(2),
  },
  {
    title: 'ECPC',
    dataIndex: 'campaign_ECPC',
    key: 'campaign_ECPC',
    width: 100,
    render: ecpc => ecpc?.toFixed(2),
  },
  {
    title: 'ECPV',
    dataIndex: 'campaign_ECPV',
    key: 'campaign_ECPV',
    width: 100,
    render: ecpv => ecpv?.toFixed(2),
  },
];
