import React from 'react';
import { Table } from 'antd';

function ConversionTable({
  goalsConversionsTotal,
  conversionsForAllGoals,
  goalNumbersData,
}) {
  const columns = [
    {
      title: '',
      dataIndex: 'goalNumber',
      key: 'goalNumber',
      width: '250px',
    },
    {
      title: `Conversions ${
        conversionsForAllGoals
          ? `(${Math.round(
              Object.values(conversionsForAllGoals).reduce(
                (acc, cur) => acc + cur,
                0,
              ) / 100,
            )})`
          : ''
      }`,
      dataIndex: 'conversions',
      key: 'Conversions',
      width: '150px',
    },
    {
      title: `Assisted Conversions ${
        goalsConversionsTotal
          ? `(${goalsConversionsTotal.assistedConversions})`
          : ''
      }`,
      dataIndex: 'assistedConversions',
      key: 'assistedConversions',
      width: '250px',
    },
  ];

  const dataSource = Object.keys(goalNumbersData).map(goalNumber => ({
    goalNumber: `${goalNumber}`,
    assistedConversions: `${goalNumbersData[goalNumber].assistedConversions}`,
    conversions: `${Math.round(conversionsForAllGoals[goalNumber] / 100)}`,
  }));

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey="conversions"
      style={{ whiteSpace: 'nowrap', marginBottom: 20 }}
      scroll={{ x: 300 }}
    />
  );
}

export default ConversionTable;
