import React, { useState } from 'react';

import {
  RowContainerStyle,
  KeyContainerStyle,
  KeyHintStyle,
  ValueContainerStyle,
} from 'pages/CampaignDetails/styles';
import EditableField from 'components/EditableField';
import { InputNumber, Select, Form } from 'antd';
import Validation from 'utils/Validation';
import { instascalerPackageTypes } from 'utils/constants';

const { Option } = Select;

const BudgetPanel = ({
  campaign,
  onEditSave,
  fieldsToEdit,
  editCampaignLoading,
  accountTransactions,
  tsCampaignsReport,
  fetchTsCampaignsLoading,
  editMode,
}) => {
  const [form] = Form.useForm();
  const [editedValues, setEditedValues] = useState({
    package_type: '',
    balance: '',
    max_allowed_budget_percentage: '',
    cpc: '',
    bonus: '',
  });

  const { auto_bidding: autoBidding, cpc, package: campaignPackage } = campaign;

  const { abm, balance, bonus, package_type: packageType } = campaignPackage;

  const maxAllowedBudgetPercentage = Number((abm * 100).toFixed(2));
  const maxAllowedBudget = Number(((balance + bonus) * abm).toFixed(2)) || 0;

  const isInstascalerCampaign = instascalerPackageTypes.includes(packageType);

  const state = campaign.state || '_';
  const canEditBudget =
    !isInstascalerCampaign &&
    state.toLowerCase() !== 'suspended' &&
    state.toLowerCase() !== 'expired';
  const canEditPackageType =
    isInstascalerCampaign && state.toLowerCase() !== 'running';

  const isEditLoading = fieldKey =>
    editCampaignLoading && fieldsToEdit.includes(fieldKey);

  const accumulateAllChargeTransactions = () => {
    const { _id: campaignId } = campaign;

    const instascalerCampaignId = campaignId?.toString();

    return accountTransactions.reduce((acc, transaction) => {
      const transactionInstascalerCampaignId = transaction?.instascaler_campaign_id?.toString();

      const instascalerCampaignRelatedTransaction =
        transactionInstascalerCampaignId === instascalerCampaignId;

      if (
        transaction.type === 'CHARGE' &&
        instascalerCampaignRelatedTransaction
      ) {
        return acc + (transaction.amount || 0);
      }
      return acc;
    }, 0);
  };

  const getTotalConsumedBudget = () => {
    const totalConsumedBudget = tsCampaignsReport?.ts_campaigns?.reduce(
      (currentTotalConsumedBudget, tsCampaign) => {
        return (
          currentTotalConsumedBudget +
          (Number(tsCampaign?.last_consumed_cost) || 0)
        );
      },
      0,
    );

    return totalConsumedBudget?.toFixed(2);
  };

  const isSaveButtonDisabled = (name, value) =>
    editedValues[name] === undefined ||
    editedValues[name] === null ||
    editedValues[name]?.toString() === value?.toString();

  const totalConsumedBudget = accumulateAllChargeTransactions();

  const { setFieldsValue } = form;

  return (
    <Form
      initialValues={{
        bonus,
        cpc,
        maxAllowedBudgetPercentage,
        balance,
      }}
    >
      <RowContainerStyle>
        <KeyContainerStyle>Package Type</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={packageType}
            disableSaveButton={isSaveButtonDisabled(
              'package_type',
              packageType,
            )}
            onSave={() =>
              onEditSave({ package_type: editedValues['package_type'] })
            }
            onCancel={() => {
              setEditedValues({ ...editedValues, package_type: packageType });
              setFieldsValue({ package_type: packageType });
            }}
            loadingEdit={isEditLoading('package_type')}
            disableEdit={!canEditPackageType}
            editMode={editMode}
          >
            <Select
              labelInValue
              value={{ key: editedValues['package_type'] || packageType }}
              onChange={value =>
                setEditedValues({
                  ...editedValues,
                  package_type: value.key,
                })
              }
            >
              <Option value="MICRO">Micro</Option>
              <Option value="STARTER">Starter</Option>
              <Option value="SCALER">Scaler</Option>
              <Option value="SUPER">Super scaler</Option>
            </Select>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>
          Budget
          <br />
          <KeyHintStyle>(USD)</KeyHintStyle>
        </KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={balance}
            disableSaveButton={isSaveButtonDisabled('balance', balance)}
            onSave={() => onEditSave({ balance: editedValues['balance'] })}
            renderShownValue={value => `${value} $`}
            onCancel={() => {
              setEditedValues({ ...editedValues, balance });
              setFieldsValue({ balance });
            }}
            loadingEdit={isEditLoading('balance')}
            disableEdit={!canEditBudget}
            editMode={editMode}
          >
            <Form.Item
              rules={[
                {
                  validator: (rule, value, cb) =>
                    value >= balance ? cb() : cb(true),
                  message: 'New Budget must be higher than old one',
                },
              ]}
            >
              <InputNumber
                placeholder="Budget"
                type="number"
                onChange={number =>
                  setEditedValues({
                    ...editedValues,
                    balance: number,
                  })
                }
                data-test={`balance-input`}
              />
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>
          Max Allowed Budget
          <br />
          <KeyHintStyle>(Percentage)</KeyHintStyle>
        </KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={maxAllowedBudgetPercentage}
            disableSaveButton={isSaveButtonDisabled(
              'max_allowed_budget_percentage',
              maxAllowedBudgetPercentage,
            )}
            onSave={() =>
              onEditSave({
                abm: Number(
                  (editedValues['max_allowed_budget_percentage'] / 100).toFixed(
                    2,
                  ),
                ),
              })
            }
            renderShownValue={value => `${value} %`}
            onCancel={() => {
              setEditedValues({
                ...editedValues,
                max_allowed_budget_percentage: maxAllowedBudgetPercentage,
              });
              setFieldsValue({
                maxAllowedBudgetPercentage,
              });
            }}
            loadingEdit={isEditLoading('abm')}
            editMode={editMode}
          >
            <Form.Item rules={[Validation.required, Validation.percentage]}>
              <InputNumber
                placeholder="Max Allowed Budget"
                type="number"
                onChange={number =>
                  setEditedValues({
                    ...editedValues,
                    max_allowed_budget_percentage: number,
                  })
                }
                data-test={`max-allowed-budget-percentage-input`}
              />
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>
          Max Allowed Budget
          <br />
          <KeyHintStyle>(USD)</KeyHintStyle>
        </KeyContainerStyle>
        <ValueContainerStyle big>{maxAllowedBudget} $</ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>
          Total Consumed Budget (Partner)
          <br />
          <KeyHintStyle>(USD)</KeyHintStyle>
        </KeyContainerStyle>
        <ValueContainerStyle big>
          {Number((totalConsumedBudget / 100).toFixed(2))} $
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle>
        <KeyContainerStyle>
          Total Consumed Budget
          <br />
          <KeyHintStyle>(USD)</KeyHintStyle>
        </KeyContainerStyle>
        <ValueContainerStyle big>
          {getTotalConsumedBudget()} $
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle hide={isInstascalerCampaign}>
        <KeyContainerStyle>CPV</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={autoBidding ? 'auto' : cpc}
            disableSaveButton={isSaveButtonDisabled('cpc', cpc)}
            onSave={() => onEditSave({ cpc: editedValues['cpc'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, cpc });
              setFieldsValue({ cpc });
            }}
            loadingEdit={isEditLoading('cpc')}
            disableEdit={autoBidding}
            editMode={editMode}
          >
            <Form.Item rules={[Validation.required]}>
              <InputNumber
                placeholder="CPV"
                type="number"
                onChange={number =>
                  setEditedValues({
                    ...editedValues,
                    cpc: number,
                  })
                }
                data-test={`cpc-input`}
              />
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>

      <RowContainerStyle lastRow>
        <KeyContainerStyle>Bonus</KeyContainerStyle>
        <ValueContainerStyle>
          <EditableField
            value={bonus}
            disableSaveButton={isSaveButtonDisabled('bonus', bonus)}
            onSave={() => onEditSave({ bonus: editedValues['bonus'] })}
            onCancel={() => {
              setEditedValues({ ...editedValues, bonus });
              setFieldsValue({ bonus });
            }}
            loadingEdit={isEditLoading('bonus')}
            editMode={editMode}
          >
            <Form.Item rules={[Validation.required]}>
              <InputNumber
                placeholder="Bonus"
                type="number"
                onChange={number =>
                  setEditedValues({
                    ...editedValues,
                    bonus: number,
                  })
                }
                data-test={`bonus-input`}
              />
            </Form.Item>
          </EditableField>
        </ValueContainerStyle>
      </RowContainerStyle>
    </Form>
  );
};

export default BudgetPanel;
