import styled from 'styled-components';
import { CardDefaultDivStyle } from 'styles/styledComponents';
import { MOB_SCREEN_WIDTH } from 'utils/constants';

export const CamapignsListContainer = styled(CardDefaultDivStyle)`
  & .ant-table-thead tr {
    & th:nth-of-type(2) {
      padding: 16px 16px 16px 0;
    }
  }

  & .ant-table-row td:last-of-type {
    position: relative;
  }

  & .ant-table-row.ant-table-row-level-0 {
    cursor: pointer;

    & td:first-of-type {
      padding: 16px 0 16px 16px;
    }

    & td:nth-of-type(2) {
      padding: 16px 16px 16px 0;
    }
  }

  & .activeCampaign {
    background-color: #ccd0e1;
  }
`;

export const FilterActionsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: minmax(100%, 1fr);
`;

export const DropdownActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:nth-of-type(1) {
    max-width: 200px;
    margin-right: 1rem;
  }

  @media (max-width: ${MOB_SCREEN_WIDTH}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & div,
    div.ant-select-selection {
      width: 100%;
    }

    & > div:nth-of-type(1) {
      max-width: 100%;
      margin-right: 0;
    }
  }

  & .ant-btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 1rem;

    & button:first-of-type {
      width: calc(100% - 32px);
    }
  }
`;
