import { ElasticSearch } from 'utils/apiCalls';
import { handleApiError, showMessage } from 'utils/helpers';

export default {
  name: 'Notifications',
  state: {
    getNotificationsLoading: false,
    notifications: [],
  },
  actions: {
    *getCampaignNotifications(campaignId, eventName) {
      try {
        yield { getNotificationsLoading: true };

        const notificationsRes = yield ElasticSearch.getNotifications(
          campaignId,
          eventName,
        ).catch(e => e);

        handleApiError(notificationsRes);

        const notifications = notificationsRes?.hits?.hits;

        yield {
          getNotificationsLoading: false,
          notifications,
        };
      } catch (err) {
        const { message } = err;
        yield {
          getNotificationsLoading: false,
        };
        showMessage(
          'error',
          `Error while fetching Notifications${message ? `: ${message}` : ''}`,
        );
      }
    },
    resetNotifications() {
      return {
        notifications: [],
      };
    },
  },
};
