import React from 'react';
import { Table } from 'antd';

function LocationsTable(props) {
  let expandedRowRender;
  const { cities } = props;

  if (cities?.length) {
    expandedRowRender = record => {
      const columns = [
        {
          dataIndex: 'city',
          key: 'city',
          width: '200px',
        },
        {
          dataIndex: 'sessions',
          key: 'city_visits',
          width: '120px',
        },
        {
          dataIndex: 'pageـviewsـper_session',
          key: 'city_pageـviewsـper_session',
          width: '120px',
        },
        {
          dataIndex: 'avg_session_duration',
          key: 'city_avg_session_duration',
          width: '150p',
        },
      ];

      const data =
        cities && Object.keys(cities).length && cities[record.country_code]
          ? [...cities[record.country_code]]
          : [];

      const sortedData = data.sort((a, b) => b.sessions - a.sessions);

      return (
        <Table
          showHeader={false}
          columns={columns}
          dataSource={sortedData}
          pagination={false}
          style={{ whiteSpace: 'nowrap' }}
          scroll={{ x: 300 }}
        />
      );
    };
  }

  const columns = [
    {
      dataIndex: 'country',
      key: 'country',
      width: '200px',
    },
    {
      title: 'Visits',
      dataIndex: 'visits',
      key: 'visits',
      width: '120px',
    },
    {
      title: 'Pages/Visit',
      dataIndex: 'pageـviewsـper_session',
      key: 'pageـviewsـper_session',
      width: '120px',
    },
    {
      title: 'Visit Duration',
      dataIndex: 'avg_session_duration',
      key: 'avg_session_duration',
      width: '150px',
    },
  ];

  const { countries } = props;

  const data = countries.map(countryData => {
    return {
      ...countryData,
      pageـviewsـper_session: Number(
        countryData.pageـviewsـper_session.toFixed(2),
      ),
    };
  });

  return (
    <Table
      columns={columns}
      expandedRowRender={expandedRowRender}
      dataSource={data}
      pagination={false}
      rowKey="country"
      style={{ whiteSpace: 'nowrap', marginBottom: 20 }}
      scroll={{ x: 300 }}
    />
  );
}

export default LocationsTable;
