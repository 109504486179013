import React from 'react';
import { Provider } from 'speedux';
import { ThemeProvider } from 'styled-components';

import Router from './routes';
import theme from './styles/themes';
import { GlobalStyle } from 'styles/globalStyle';

function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
